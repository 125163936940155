<template>
  <div>
    <v-tooltip bottom>
      <v-btn icon slot="activator" @click.stop="onOpen">
        <v-icon>filter_list</v-icon>
      </v-btn>
      <span>{{ $t('common.search_operations') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">search</v-icon>
          <span>&ensp;</span>
          <h4>{{ $t('common.search_operations') }}</h4>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs5 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('apply.location_owner') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-select
                :items="companies"
                item-text="name"
                item-value="id"
                v-model="search.client_id"
                autocomplete
                class="pt-0"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs5 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.status') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-btn-toggle v-model="search.enabled" mandatory>
                <v-btn color="success" class="white--text">{{ $t('shared.status.all') }}</v-btn>
                <v-btn color="error" class="white--text">{{ $t('shared.status.exceeded') }}</v-btn>
              </v-btn-toggle>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClear">{{ $t('common.clear') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="onSearch">{{ $t('common.search') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import Api from "../../api/lmp";

    export default {
        props: ['onFetch'],
        data() {
            return {
                dialog: false,
                search: {
                    enabled: 0
                },
                companies: []
            };
        },
        mounted(){
            Api.get('/company').then(response => {
                this.companies = response.data;
            })
        },
        methods: {
            onOpen() {
                this.dialog = true;
            },
            onClear() {
                this.search = {
                    enabled: 0
                }
            },
            onSearch() {
                this.dialog = false;
                this.onFetch(this.search)
            }
        }
    };
</script>

<style>
</style>
