<template>
  <v-card flat>
    <v-card-text>
      <v-list>
        <v-subheader>{{ $t('discriptions.support') }}</v-subheader>
        <v-list-tile>
          <v-list-tile-action>
            <v-icon>mail</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              <a href="mailto:loco_support@beacrew.jp">loco_support@beacrew.jp</a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>
