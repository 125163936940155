<template>
  <v-container fluid>
    <v-card>
      <v-form>
        <v-container>
          <v-layout>
            <v-flex xs12 md3>
              <v-select
                :items="apps"
                outline
                v-model="app_id"
                item-text="app_name"
                item-value="app_id"
                @input="selected_type === 'beacon_map' || selected_type === 'beacon_battery' ? null : getSingleApp(app_id)"
                height="50"
                v-bind:class="{disabled:!appLoadFinish}"
                v-bind:hide-details="true"
                class="custom-select"
              >
                <template v-slot:label>
                  <p v-if="appLoadFinish">{{ $t(`summary.applications`) }}</p>

                  <p v-else>{{ $t(`summary.please_wait`) }}</p>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs12 md3>
              <v-select
                  :items="types"
                  outline
                  v-model="selected_type"
                  @change="getSingleApp(app_id)"
                  item-text="log_type"
                  item-value="log_value"
                  height="50"
                  v-bind:hide-details="true"
                  :menu-props="{ maxHeight: 400 }"
                  style="font-size: 14px"
              >
                <template v-slot:label>
                  <p>{{ $t(`summary.type`) }}</p>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs12 md3>
              <v-select
                v-if="selected_type === 'beacon_map' || selected_type === 'beacon_battery'"
                :items="clusters"
                outline
                v-model="selected_cluster"
                item-text="cluster_name"
                item-value="cluster_id"
                height="50"
                v-bind:hide-details="true"
                @input="getSingleApp(app_id)"
              >
                <template v-slot:label>
                  <p>{{ $t(`common.clusters`) }}</p>
                </template>
              </v-select>
            </v-flex>
            <v-flex v-if="selected_type !== 'device_logs' && selected_type !== 'beacon_battery' && selected_type !== 'unique_device_logs'" xs12 md3>
              <v-daterange v-model="range" style="width: 100%;"
                           @input="getSingleApp(app_id)"
                           :startLabel="$t(`summary.startDateLabel`)"
                           :endLabel="$t(`summary.endDateLabel`)"
                           :locale="$t(`summary.locale`)"
                           hide-details="true"
              ></v-daterange>
            </v-flex>

            <v-flex v-if="selected_type === 'device_logs' || selected_type === 'unique_device_logs'" style="display: flex; flex-direction: row; align-items: center" xs12 md3>
              <v-icon color="secondary" class="arrow">arrow_left</v-icon>
              <vue-monthly-picker @selected="getSingleApp(app_id)"
                                  v-model="selectedMonth" :monthLabels="deviceLogsMonthLabels" alignment="center"
                                  selectedBackgroundColor="#00897B" style="height: 20px; align-self: center;"
                                  inputClass="v-input" placeHolder="Choose a month" :clearOption=false>
              </vue-monthly-picker>
              <v-icon color="secondary" class="arrow">arrow_right</v-icon>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
    <br>
    <template>

      <v-card style="margin: 0 auto;">



        <div v-if="loading_show">
          <br>
          <Spinner size="large" :message="$t('summary.loading')" line-fg-color="#00897B"></Spinner>
          <br>
        </div>


        <mixed-graph v-if="(selected_type === 'device_logs' || selected_type === 'unique_device_logs') && !loading_show" :series="series" :selectedMonth="selectedMonth"/>




        <stack-bar v-else-if="selected_type === 'os_logs' && !loading_show" :series_ios_version="series_ios_version"
                   :series_ios_model="series_ios_model" :series_android_version="series_android_version"
                   :series_android_model="series_android_model" :series_os="series_os" :range="range"/>


        <region-graph
          v-else-if="selected_type === 'region_logs' && (range.start !== undefined && range.end !== undefined)"
          :series="series"
          :loading_show="loading_show"/>


        <beacon-map
          v-else-if="selected_type === 'beacon_map' && selected_cluster !== '' && range.start !== undefined && range.end !== undefined && !loading_show"
          :clusters="clusters" :selected_cluster="selected_cluster" :series="series"/>



        <beacon-battery v-else-if="selected_type === 'beacon_battery' && selected_cluster !== '' && !loading_show"
                        :clusters="clusters" :selected_cluster="selected_cluster"
                        :series="series"/>

        <bar-graph
          v-else-if="(selected_type === 'beacon_logs' || selected_type === 'event_logs') && !loading_show && (range.start !== undefined && range.end !== undefined)"
          :loading_show="loading_show" :series="series"/>

        <div v-else></div>



      </v-card>
    </template>
  </v-container>
</template>

<script>

  import VDaterange from "./DateRangePicker"
  import apexchart from 'vue-apexcharts'
  import BarGraph from './BarGraph';
  import MixedGraph from './MixedGraph';
  import axios from "axios";
  import StackBar from "./StackBar";
  import RegionGraph from './RegionLogs';
  import BeaconMap from "./BeaconMap";
  import Spinner from "vue-simple-spinner";
  import BeaconBattery from "./BeaconBattery";
  import VueMonthlyPicker from 'vue-monthly-picker'
  import moment from "moment"


  export default {
    components: {
      BeaconBattery,
      StackBar,
      BarGraph,
      MixedGraph,
      RegionGraph,
      BeaconMap,
      VDaterange,
      apexchart,
      Spinner,
      VueMonthlyPicker
    },
    data() {
      return {
        deviceLogsMonthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        selectedMonth: moment(),
        emitTest: null,
        appLoadFinish: false,
        loading_show: false,
        show: false,
        range: {},
        types: [
          {
            log_type: this.beacon_logs,
            log_value: "beacon_logs"
          },
          {
            log_type: this.event_logs,
            log_value: "event_logs"
          },
          {
            log_type: this.region_logs,
            log_value: "region_logs"
          },
          {
            log_type: this.device_logs,
            log_value: "device_logs"
          },
          {
            log_type: this.os_logs,
            log_value: "os_logs"
          },
          {
            log_type: this.beacon_map,
            log_value: "beacon_map"
          },
          {
            log_type: this.beacon_battery,
            log_value: "beacon_battery"
          },
          {
            log_type: this.unique_device_logs,
            log_value: "unique_device_logs"
          }
        ],
        selected_type: "",
        selected_cluster: "",
        apps: [],
        app_id: undefined,
        allApps: null,
        series: [],
        series_ios_version: [],
        series_ios_model: [],
        series_android_version: [],
        series_android_model: [],
        series_os: [],
        currentMonth: new Date().toISOString().slice(0, 7).replace('-', '/'),
        clusters: [],
      }
    },


    mounted() {
      this.getApps();

      if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
      }
    },


    created() {
      this.types[0].log_type = this.$t('summary.beacon_logs');
      this.types[1].log_type = this.$t('summary.event_logs');
      this.types[2].log_type = this.$t('summary.region_logs');
      this.types[3].log_type = this.$t('summary.device_logs');
      this.types[4].log_type = this.$t('summary.os_logs');
      this.types[5].log_type = this.$t('summary.beacon_map');
      this.types[6].log_type = this.$t('summary.beacon_battery');
      this.types[7].log_type = this.$t('summary.unique_device_logs');
    },
    computed: {
      beacon_logs() {
        return this.$t('summary.beacon_logs');
      },
      event_logs() {
        return this.$t('summary.event_logs');
      },
      region_logs() {
        return this.$t('summary.region_logs');
      },
      device_logs() {
        return this.$t('summary.device_logs');
      },
      os_logs() {
        return this.$t('summary.os_logs');
      },
      beacon_map() {
        return this.$t('summary.beacon_map');
      },
      beacon_battery() {
        return this.$t('summary.beacon_battery');
      },
      unique_device_logs() {
        return this.$t('summary.unique_device_logs');
      },
    },
    watch: {
      beacon_logs() {
        this.types[0].log_type = this.$t('summary.beacon_logs');
      },
      event_logs() {
        this.types[1].log_type = this.$t('summary.event_logs');
      },
      region_logs() {
        this.types[2].log_type = this.$t('summary.region_logs');
      },
      device_logs() {
        this.types[3].log_type = this.$t('summary.device_logs');
      },
      os_logs() {
        this.types[4].log_type = this.$t('summary.os_logs');
      },
      beacon_map() {
        this.types[5].log_type = this.$t('summary.beacon_map');
      },
      beacon_battery() {
        this.types[6].log_type = this.$t('summary.beacon_battery');
      },
      unique_device_logs() {
        this.types[7].log_type = this.$t('summary.unique_device_logs');
      },


      app_id() {
        if (typeof this.app_id !== 'undefined') {
          let cluster_data = this.apps.find(x => x.app_id === this.app_id)['clusters'];
          let cluster_item = new Array();
          for (let i = 0; i < cluster_data.length; i++) {
            cluster_item[i] = new Object();
            cluster_item[i]["cluster_name"] = cluster_data[i].name;
            cluster_item[i]["cluster_id"] = cluster_data[i].id;
            cluster_item[i]['image'] = cluster_data[i].image;
            cluster_item[i]['beacons'] = cluster_data[i].beacons;
          }
          this.clusters = cluster_item;
        }
      }
    },
    methods: {
      getApps() {
        axios.get("/summary/apps").then(response => {
          let data = response.data;
          let apps = new Array();
          for (let i = 0; i < data.length; i++) {
            apps[i] = {};
            apps[i]["app_name"] = data[i].name;
            apps[i]["app_id"] = data[i].loco_id;
            apps[i]['clusters'] = data[i].clusters;
          }
          this.apps = apps;
          this.appLoadFinish = true;
          if (this.apps.length > 0) {
            this.app_id = this.apps[0].app_id
          }
        })
      },

      getLogs(range) {
        this.loading_show = true;
        axios.get("/summary/logs", {
            params: {
              app_id: this.app_id,
              start_date: range.start ? range.start : null,
              end_date: range.end ? range.end : null,
              log_type: this.selected_type,
              cluster_id: this.selected_cluster
            }
          }
        ).then(response => {

            if (!(typeof this.selected_type === 'undefined') && this.app_id !== '') {
              if (this.selected_type === 'region_logs' || this.selected_type === 'beacon_map' || this.selected_type === 'beacon_battery') {
                this.loading_show = true;
                this.series = response.data;
              }
              else if (this.selected_type === 'device_logs' || this.selected_type === 'unique_device_logs') {
                let items = response.data;
                let barData = [];
                let lineData = [];
                let seriesBarData = {};
                let seriesLineData = {};
                items.map(item => {
                  let barItem = {
                    x: item.key_as_string.substring(8, 10),
                    y: item.doc_count
                  };
                  barData.push(barItem);

                  let lineItem = {
                    x: item.key_as_string.substring(8, 10),
                    y: item.sum.value
                  };
                  lineData.push(lineItem);
                });

                var date = new Date();
                var month = date.getMonth();
                var year = date.getFullYear();

                var last_day = new Date(year, month + 1, 0).getDate();
                var current_day = lineData[lineData.length - 1];

                if (items.length !== 0) {
                  if (parseInt(current_day.x) < last_day) {
                    for (var i = parseInt(current_day.x) + 1; i <= last_day; i++) {
                      if (i < 10) {
                        let barItem = {
                          x: '0' + i,
                          y: 0
                        };
                        barData.push(barItem);

                        let lineItem = {
                          x: '0' + i,
                          y: current_day.y
                        };
                        lineData.push(lineItem);
                      } else {
                        let barItem = {
                          x: i,
                          y: 0
                        };
                        barData.push(barItem);

                        let lineItem = {
                          x: i,
                          y: current_day.y
                        };
                        lineData.push(lineItem);
                      }
                    }
                  }
                }
                seriesBarData['data'] = barData;
                seriesBarData['type'] = 'column';
                seriesBarData['name'] = 'This Day';
                this.series.push(seriesBarData);
                seriesLineData['data'] = lineData;
                seriesLineData['type'] = 'line';
                seriesLineData['name'] = 'Total';
                this.series.push(seriesLineData);

              }
              else if (this.selected_type === 'os_logs') {
                let items = response.data;
                let ios_version = items[0];
                let ios_model = items[1];
                let android_version = items[2];
                let android_model = items[3];

                let ios = {
                  name: 'iOS',
                  data: [ios_version.total]
                };

                let android = {
                  name: 'Android',
                  data: [android_version.total]
                };

                this.series_os.push(ios);
                this.series_os.push(android);

                ios_version.results.map(item => {
                  let rename = {
                    name: item.key,
                    data: [item.doc_count],
                  };
                  this.series_ios_version.push(rename);
                });

                if (ios_version['others'] > 0) {
                  let rename = {
                    name: 'others',
                    data: [ios_version['others']],
                  };
                  this.series_ios_version.push(rename);
                }

                ios_model.results.map(item => {
                  let rename = {
                    name: item.key,
                    data: [item.doc_count],
                  };
                  this.series_ios_model.push(rename);
                });

                if (ios_model['others'] > 0) {
                  let rename = {
                    name: 'others',
                    data: [ios_model['others']],
                  };
                  this.series_ios_model.push(rename);
                }

                android_version.results.map(item => {
                  let rename = {
                    name: item.key,
                    data: [item.doc_count],
                  };
                  this.series_android_version.push(rename);
                });

                if (android_version['others'] > 0) {
                  let rename = {
                    name: 'others',
                    data: [android_version['others']],
                  };
                  this.series_android_version.push(rename);
                }

                android_model.results.map(item => {
                  let rename = {
                    name: item.key,
                    data: [item.doc_count],
                  };
                  this.series_android_model.push(rename);
                });

                if (android_model['others'] > 0) {
                  let rename = {
                    name: 'others',
                    data: [android_model['others']],
                  };
                  this.series_android_model.push(rename);
                }

              }
              else {
                let items = response.data;
                let barData = [];
                let seriesBarData = {};
                this.series=[];

                items.map(item => {
                  let barItem = {
                    x: item.name,
                    y: item.count
                  };
                  barData.push(barItem);
                });
                seriesBarData['data'] = barData;
                seriesBarData['name'] = "Total number of <b>" + this.selected_type + "</b> logs";
                this.series.push(seriesBarData);
              }
            }
            this.loading_show = false;
          }
        )
      },
      resetForm(event) {
        this.app_id = undefined;
        this.selected_cluster = "";
        this.getSingleApp("")
      },

      getSingleApp(app_id) {
        let range = JSON.parse(JSON.stringify(this.range));
        this.series = [];
        this.series_ios_version = [];
        this.series_ios_model = [];
        this.series_android_version = [];
        this.series_android_model = [];
        this.series_os = [];


        if ((this.selected_type === 'device_logs' || this.selected_type === 'unique_device_logs') && app_id !== '' && app_id!== undefined && this.selectedMonth !== null) {

          const month = this.selectedMonth.format('MM')

          const year = this.selectedMonth.format('YYYY');

          range.start = this.getMonthDateRange(year,month).year + "-" + this.getMonthDateRange(year,month).month+ "-" + this.getMonthDateRange(year,month).start;
          range.end =this.getMonthDateRange(year,month).year + "-" + this.getMonthDateRange(year,month).month+ "-" + this.getMonthDateRange(year,month).end;

          this.getLogs(range);
        }


        if ((this.selected_type === 'beacon_logs' || this.selected_type === 'event_logs') && (app_id !== undefined && range.start !== undefined && range.end !== undefined)) {
          this.getLogs(range);
        }

        if (this.selected_type === 'beacon_map' && this.selected_cluster !== "" && app_id !== '' && range.start !== undefined && range.end !== undefined) {
          this.getLogs(range);
        }

        if (this.selected_type === 'beacon_battery' && this.selected_cluster !== "" && app_id !== '') {
          this.getLogs(range);
        }


        if (this.selected_type === 'region_logs' && app_id !== undefined && range.start !== undefined && range.end !== undefined) {
          this.getLogs(range);
        }

        if (this.selected_type === 'os_logs' && app_id !== '' && range.start !== undefined && range.end !== undefined) {
          this.getLogs(range);
        }
      },
      getMonthDateRange(year, month)
      {
        // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
        // array is 'year', 'month', 'day', etc
        var startDate = moment([year, month - 1]);

        // Clone the value before .endOf()
        var endDate = moment(startDate).endOf('month');

        // just for demonstration:




        // make sure to call toDate() for plain JavaScript date type
        return { start: startDate.format("DD"), end: endDate.format("DD"), year:year, month:month};
      }
    }
  }
</script>

<style>
  .v-date-range .v-menu--inline {
    width: 100% !important;
  }

  .vue-monthly-picker .picker .monthItem .item.active:hover {
    cursor: pointer;
    background-color: #8FC320 !important;
    color: white;
    font-weight: bold;
  }


  .next:after {
    border-left: 10px solid #00897B !important;
    margin-left: 5px
  }


  .prev:after {
    border-right: 10px solid #00897B !important;
    margin-left: 5px
  }


  .disabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
  }

  .custom-select .v-select__slot {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-select .v-select__prepend-inner {
    top: calc(50% - 12px) !important;
    right: 12px !important;
  }
</style>
