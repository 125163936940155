import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

Vue.use(Vuex);

const plugin = store => {
  store.subscribe((mutation, state) => {
    if (mutation.type === "login") {
      router.push("/home");
    }
    if (mutation.type === "logout") {
      const token = localStorage.getItem("token");
      if (token) {
        localStorage.clear();
        localStorage.setItem("attemptedToken", token);
      }
      router.push("/login");
    }
  });
};

const store = new Vuex.Store({
  state: {
    authenticated: !!localStorage.getItem("token"),
    user: {},
    notice: {},
    search: {}
  },
  getters: {
    search: state => state.search
  },
  mutations: {
    login(state) {
      state.authenticated = true;
    },
    logout(state) {
      state.authenticated = false;
    },
    user(state, payload) {
      state.user = payload;
    },
    notify(state, payload) {
      state.notice = payload;
    },
    search(state, payload) {
      state.search = payload;
    }
  },
  actions: {
    onLogin({commit}) {
      commit("login");
    },
    onLogout({commit}) {
      commit("logout");
    },
    setUser({commit}, payload) {
      commit("user", payload);
    },
    onNotify({commit}, payload) {
      commit("notify", {
        active: true,
        timeout: 6000,
        color: payload.type,
        top: true,
        right: true,
        multiLine: payload.multiLine,
        text: payload.text
      });
    },
    setSearch({commit}, payload) {
      commit("search", payload);
    }
  },
  plugins: [plugin]
});

export default store;
