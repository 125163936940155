<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="850">
    <v-btn icon slot="activator" @click="onSetDef">
      <v-icon>settings</v-icon>
    </v-btn>
    <v-card>
      <v-card-title>
        <v-icon color="secondary">place</v-icon>
        <span>&ensp;</span>
        <span class="secondary--text">
          <strong>{{ items.name }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs6 class="grey--text pl-2">
            <strong>IN ACTION</strong>
          </v-flex>
          <v-flex xs6 class="grey--text pl-2">
            <strong>OUT ACTION</strong>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6>
            <div class="btn-list">
              <v-btn-toggle v-model="items.in">
                <div class="grid">
                  <v-btn
                    v-for="(item, i) in actions"
                    :key="i"
                    :value="item.id"
                    color="primary"
                    class="white--text"
                  >
                    <span>{{ item.name }}</span>
                  </v-btn>
                </div>
              </v-btn-toggle>
            </div>
          </v-flex>
          <v-flex xs6>
            <div class="btn-list">
              <v-btn-toggle v-model="items.out">
                <div class="grid">
                  <v-btn
                    v-for="(item, i) in actions"
                    :key="i"
                    :value="item.id"
                    color="primary"
                    class="white--text"
                  >
                    <span>{{ item.name }}</span>
                  </v-btn>
                </div>
              </v-btn-toggle>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="secondary" @click.stop="onClose">{{ $t('common.close') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat color="success" @click.stop="onSave">{{ $t('common.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["items", "actions"],
  data() {
    return {
      dialog: false,
      default: {
        in: null,
        out: null
      }
    };
  },
  methods: {
    onSetDef() {
      this.default.in = this.items.in;
      this.default.out = this.items.out;
    },
    onClose() {
      this.items.in = this.default.in;
      this.items.out = this.default.out;
      this.dialog = false;
    },
    onSave() {
      this.dialog = false;
    }
  }
};
</script>

<style>
</style>
