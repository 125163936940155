<template>
  
</template>

<script>
export default {
  data() {
    return {
      image: "",
      error: false,
      message: "The file may not be greater than 1,000,000 bytes."
    };
  },
  watch: {
    image() {
      let str = this.image;
      if (str.length > 0 && str.indexOf(",") >= 0) {
        str = str.slice(str.indexOf(",") + 1);
      }
      this.items.image = str;
    }
  },
  methods: {
    onPickFile() {
      if (this.mode) return;
      this.$refs.file.click();
    },
    onDropFile(e) {
      if (this.mode) return;
      let files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChangeFile(e) {
      let files = e.target.files;
      this.createFile(files[0]);
    },
    onRemoveFile() {
      this.image = "";
      this.$refs.file.value = "";
    },
    createFile(file) {
      const render = new FileReader();
      const vm = this;

      this.error = false;
      // Max:1Mb(1000000byte)
      if (file.size > 1000000) {
        this.error = true;
        return;
      }

      render.onload = e => {
        vm.image = e.target.result;
      };
      render.readAsDataURL(file);
    }
  }
};
</script>

<style>
</style>
