<template>
  
</template>

<script>
import Clipboard from "clipboard";
import { mapActions } from "vuex";

export default {
  mounted() {
    const clipboard = new Clipboard(".clipboard");

    clipboard.on("success", e => {
      this.onNotify({
        type: "success",
        text: "Copied!"
      });
    });

    clipboard.on("error", e => {
      console.error(e.action);
      console.error(e.trigger);
    });
  },
  methods: {
    ...mapActions(["onNotify"])
  }
};
</script>

<style>
</style>
