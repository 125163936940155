<template>
  
</template>

<script>
export default {
  filters: {
    enabled(e) {
      return e ? "success" : "error";
    },
    battery(n) {
      switch (true) {
        case n >= 70:
          return "success";
        case n >= 30:
          return "warning";
        default:
          return "error";
      }
    },
    tag(s) {
      switch (s) {
        case "Facility":
        case "Floor":
          return "primary";
        case "Area":
        case "Section":
          return "warning";
      }
    }
  }
};
</script>

<style>
</style>
