<template>
  <apexchart width="700px" :height="110" type=bar :options="chartOptions" :series="series"
             v-if="series.length && series[0].data.length>0"/>
</template>

<script>
  import apexchart from 'vue-apexcharts';

  export default {
    props: ["series", "chartOptions", "height"],
    components: {
      apexchart
    },
  }
</script>

<style scoped>

</style>



