<template>
  <div>


    <v-card v-if="item" class="my-2">

      <v-card-title primary-title>
        <v-layout row justify-space-between dense>
          <v-flex>{{ item.name }}</v-flex>
          <v-subheader>
            {{ this.prefecture }}
          </v-subheader>
          <v-subheader>{{ this.city }}</v-subheader>
        </v-layout>

      </v-card-title>
      <v-divider></v-divider>

  <LocationDetail :itemModel="item" :mode="true" type="search" :errors="[]"/>
  </v-card>
  </div>
</template>

<script>

  import LocationDetail from "../../components/locations/LocationDetail";
  import Api from "../../api/lmp";
  import {getTypeObject} from "../../utils/facilities";
  import {CITIES, CITY_SELECT_VALUE, PREFECTURE_SELECT_VALUE} from "../../utils/masterdata";

  export default {
    components: { LocationDetail },
    data() {
      return {
        item: null,
        prefecture: '',
        city: '',
      };
    },
    mounted() {
      const {location_id} = this.$route.params;
      this.onFetch(location_id);
    },
    methods: {
      onFetch(location_id) {
        Api.get(`/location/${location_id}?with[]=company&with[]=photo&with[]=beacon`).then(response => {
          const facilities = getTypeObject(response.data);
          this.cities = CITIES(response.data.prefecture);
          this.item = {...response.data, facilities };
          this.prefecture = PREFECTURE_SELECT_VALUE(this.item.prefecture).text;
          this.city = CITY_SELECT_VALUE(this.item.prefecture, this.item.city).text;

        });
      }
    },
  };
</script>

