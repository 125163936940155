<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">apps</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.app') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    autofocus
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.plan') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-btn-toggle mandatory v-model="items.plan">
                    <v-btn
                      v-for="(item, i) in plan"
                      :key="i"
                      :value="item.value"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.text }}</span>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <p class="caption grey--text pt-2 mb-0">{{ $t('discriptions.plan') }}</p>
                </v-flex>
              </v-layout>
              <v-layout row pb-3>
                <v-flex xs6 offset-xs2>
                  <plan-descriptions :active="items.plan"></plan-descriptions>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>BUNDLE ID ( iOS )</strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    prepend-icon="phone_iphone"
                    placeholder="jp.example.app.ios"
                    v-model="items.bundle_id.ios"
                    :rules="[
                      v => !v || /^[a-zA-Z0-9-_.]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>BUNDLE ID ( Android )</strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    prepend-icon="phone_android"
                    placeholder="jp.example.app.android"
                    v-model="items.bundle_id.android"
                    :rules="[
                      v => !v || /^[a-zA-Z0-9-_.]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>BUNDLE ID</strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.bundle_id.bundle_id"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.timer') }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-menu
                    ref="menu01"
                    :close-on-content-click="false"
                    v-model="menu01"
                    :nudge-right="40"
                    :return-value.sync="items.timer_date"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="items.timer_date"
                      prepend-icon="event"
                      :rules="[
                        v => !!v || $t('rules.required'),
                      ]"
                      readonly
                      class="pt-0"
                    ></v-text-field>
                    <v-date-picker v-model="items.timer_date">
                      <v-spacer></v-spacer>
                      <v-btn flat color="primary" @click="menu01 = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="$refs.menu01.save(items.timer_date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs3>
                  <v-menu
                    ref="menu02"
                    :close-on-content-click="false"
                    v-model="menu02"
                    :nudge-right="40"
                    :return-value.sync="items.timer_time"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="items.timer_time"
                      prepend-icon="access_time"
                      :rules="[
                        v => !!v || $t('rules.required'),
                      ]"
                      readonly
                      class="pt-0"
                    ></v-text-field>
                    <v-time-picker v-model="items.timer_time"
                                   @change="$refs.menu02.save(items.timer_time)"></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.image') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <div
                    @click.stop="onPickFile"
                    @dragover.prevent
                    @drop.prevent="onDropFile"
                    :class="[
                      'image-box',
                      error ? 'size' : ''
                    ]"
                  >
                    <span class="caption grey--text" v-if="!image">{{ $t('discriptions.image') }}</span>
                    <img :src="image" alt="" class="image small">
                    <br/>
                    <v-btn outline small color="error" @click.stop="onRemoveFile" v-if="image">{{ $t('common.remove')
                      }}
                    </v-btn>
                    <input type="file" ref="file" accept="image/*" @change="onChangeFile">
                  </div>
                  <span class="caption error--text" v-if="error">{{ message }}</span>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid"
              :loading="loading"
            ></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapState, mapActions } from "vuex";
import ImageMethod from "../../utils/methods/ImageMethod";
import PlanDescriptions from "../../components/apps/PlanDescriptions";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      plan: [
        { icon: "directions_walk", value: "Startup", text: "startup" },
        { icon: "directions_run", value: "Standard", text: "standard" },
        { icon: "directions_bike", value: "Premium", text: "premium" }
      ],
      menu01: false,
      menu02: false,
      items: {
        id: uuid(),
        client_name: "",
        client_id: "",
        name: "",
        plan: "Startup",
        secret: {
          sdk: "",
          api: ""
        },
        pin: "",
        bundle_id: {
          ios: "",
          android: "",
          bundle_id: "",
        },
        timer_date: null,
        timer_time: null,
        settings: {
          sending: true,
          aggregate: false,
          interval: {
            beacon_logs: 60,
            event_logs: 60,
            region_logs: 60
          },
          buffer: {
            beacon_logs: 10000,
            event_logs: 10000,
            region_logs: 10000
          }
        },
        structure: {
          clusters: [],
          regions: []
        },
        image: "",
        enabled: true
      }
    };
  },
  components: {
    PlanDescriptions,
    SaveConfirmation
  },
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    this.getClientName();
    this.setTimeDefault();
  },
  methods: {
    ...mapActions(["onNotify"]),
    getClientName() {
      axios.get(`/clients/${this.user.client_id}`).then(response => {
        this.items.client_name = response.data.payload.name;
      });
    },
    onSave() {
      this.loading = true;
      let path = this.$route.path.replace(/\/new/g, "");
      this.items.client_id = this.user.client_id;
      axios.post(path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: "Creating Successfully!"
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.loading = false;
            let messages = response.data.payload;
            let text = "";
            Object.keys(messages).forEach(key => {
              text += `${messages[key]}<br>`;
            });
            this.onNotify({
              type: "error",
              text: text,
              multiLine: true
            });
          }, 1000);
        }
      });
    },
    setTimeDefault() {
      let d = new Date();
      let year = d.getFullYear();
      let month = ("0" + (d.getMonth() + 1)).slice(-2);
      let day = ("0" + d.getDate()).slice(-2);
      let hour = ("0" + d.getHours()).slice(-2);
      let minute = ("0" + d.getMinutes()).slice(-2);
      this.items.timer_date = year + "-" + month + "-" + day;
      this.items.timer_time = hour + ":" + minute;
    }
  },
  mixins: [ImageMethod]
};
</script>

<style>
</style>
