<template>
  
</template>

<script>
import numeral from "numeral";

export default {
  filters: {
    comma(n) {
      return numeral(n).format("0,0");
    }
  }
};
</script>

<style>
</style>
