<template>
    <div>
        <v-layout row wrap>
            <v-flex xs12>
                <v-toolbar color="white" class="elevation-1">
                    <v-toolbar-title>
                        <span class="caption">{{ $t("common.total") }}</span>
                        {{ total }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <location-search :onFetch="onFetch"></location-search>
                </v-toolbar>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs12>
                <v-card
                        v-for="location in items" :key="location.id"
                        class="list-card"
                        :to="`${$route.path}/${location.id}`"
                >
                    <card-location :item="location" :application="location.application" type="search"></card-location>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "../../components/cards/CardLocation";
    import Api, {ApiHelper} from "../../api/lmp";
    import LocationSearch from "../../components/search/LocationsSearchOperations";

    export default {
        components: {
            LocationSearch,
            CardLocation
        },
        mounted() {
            this.onFetch({});
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        data() {
            return {
                total: 0,
                rowsPerPage: 30,
                pagination: 1,
                items: [],
                allCheck: false
            };
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            }
        },
        methods: {
            onFetch(search) {
                const url = ApiHelper.queryBuilder('/location?with[]=photo&with[]=company', search);
                return Api.get(url)
                    .then(response => {
                        this.items = response.data;
                        this.total = this.items.reduce((sum, x) => sum + (x.company != null ? 1 : 0), 0);
                    });
            }
        }
    };
</script>

<style scoped></style>
