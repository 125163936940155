<template>
  <v-card flat>
    <v-card-text>
      <v-layout row>
        <v-flex xs2 class="text-xs-right">
          <img src="../../assets/security.png" alt="" width="100">
        </v-flex>
        <v-flex xs6>
          <v-layout row class="security-card">
            <v-flex xs6>
              <dl>
                <dt>{{ $t('discriptions.2fa.title') }}</dt>
                <dd>
                  <p class="mb-0">{{ $t('discriptions.2fa.text') }}</p>
                  <v-chip outline small label :color="google2fa | enabled">{{ google2fa ? $t('shared.status.enabled') : $t('shared.status.disabled') }}</v-chip>
                </dd>
              </dl>
            </v-flex>
            <v-flex xs6>
              <!-- Enable Tow-Factor Authentication -->
              <v-btn
                outline
                color="success"
                @click.stop="enableDialog = true"
                v-if="!google2fa"
              >{{ $t('common.enable_2fa') }}</v-btn>
              <enable-confirmation
                v-on:onSwitchGoogle2fa="onSwitchGoogle2fa"
                v-on:onCloseDialog="onCloseDialog"
                :google2fa="google2fa"
                :dialog="enableDialog"
              ></enable-confirmation>
              <!-- Disable Tow-Factor Authentication -->
              <v-btn
                outline
                color="error"
                @click.stop="disableDialog = true"
                v-if="google2fa"
              >{{ $t('common.disable_2fa') }}</v-btn>
              <disable-confirmation
                v-on:onSwitchGoogle2fa="onSwitchGoogle2fa"
                v-on:onCloseDialog="onCloseDialog"
                :dialog="disableDialog"
              ></disable-confirmation>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ColorFilter from "../../utils/filters/ColorFilter";
import EnableConfirmation from "./dialogs/EnableConfirmation";
import DisableConfirmation from "./dialogs/DisableConfirmation";

export default {
  data() {
    return {
      google2fa: false,
      enableDialog: false,
      disableDialog: false
    };
  },
  components: {
    EnableConfirmation,
    DisableConfirmation
  },
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    this.onFetch();
  },
  methods: {
    onFetch() {


      console.log(this.user.role);

      const url=(this.user.role==="Administrator")?"/users/" + this.user.id:"/clients/" + this.user.client_id;

      axios.get(url).then(response => {
        if (response.data.code === 200) {

          console.log(response);
          const result = response.data.payload.google2fa_secret;
          if (result.length > 0) {
            this.google2fa = true;
          } else {
            this.google2fa = false;
          }
        } else {
        }
      });
    },
    onSwitchGoogle2fa(val) {
      this.google2fa = val;
    },
    onCloseDialog(status) {
      switch (status) {
        case "enable":
          this.enableDialog = false;
          break;
        case "disable":
          this.disableDialog = false;
          break;
      }
    }
  },
  mixins: [ColorFilter]
};
</script>

<style>
.security-card {
  height: 100%;
}
.security-card > div {
  margin: auto;
}
.security-card dt {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
