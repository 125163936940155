<template>
  <div>
    <v-tooltip bottom>
      <v-btn icon slot="activator" @click.stop="onOpen">
        <v-icon>filter_list</v-icon>
      </v-btn>
      <span>{{ $t('common.search_operations') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">search</v-icon>
          <span>&ensp;</span>
          <h4>{{ $t('common.search_operations') }}</h4>
        </v-card-title>
        <v-card-text>
          <v-layout row v-if="user.role === 'Administrator'">
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.owner') }}</strong>
            </v-flex>
            <v-flex xs5>
              <v-select
                :items="owners"
                item-text="name"
                item-value="id"
                v-model="search.client_id"
                autocomplete
                class="pt-0"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.is_mounted') }}</strong>
            </v-flex>
            <v-flex xs10 class="d-flex">
                <v-checkbox :label="$t('items.mounted')" v-model="mounted" :value="1"></v-checkbox>
                <v-checkbox :label="$t('items.movable')" v-model="mounted" :value="0"></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.name') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-model="search.name"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>UUID</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-model="search.uuid"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>MAJOR</strong>
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="search.major"
                type="number"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>MINOR</strong>
            </v-flex>
            <v-flex xs5>
              <v-text-field
                v-model="search.minor"
                type="number"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>TX POWER</strong>
            </v-flex>
            <v-flex xs10>
              <v-layout row>
                <v-flex xs1 class="secondary--text pt-2">
                  <strong>-255</strong>
                </v-flex>
                <v-flex xs10>
                  <v-slider
                    v-model="search.tx_power"
                    min="-255"
                    max="0"
                    thumb-label
                    class="pt-1"
                  ></v-slider>
                </v-flex>
                <v-flex xs1 class="secondary--text pt-2">
                  <strong>{{ search.tx_power }}</strong>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>BATTERY</strong>
            </v-flex>
            <v-flex xs10>
              <v-layout row>
                <v-flex xs1 class="secondary--text pt-2">
                  <strong>0</strong>
                </v-flex>
                <v-flex xs10>
                  <v-slider
                    v-model="search.battery"
                    thumb-label
                    class="pt-1"
                  ></v-slider>
                </v-flex>
                <v-flex xs1 class="secondary--text pt-2">
                  <strong>{{ search.battery }}</strong>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>LOCAL NAME</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-model="search.local_name"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>MODULE ID</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-model="search.module_id"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.status') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-btn-toggle v-model="search.enabled">
                <v-btn
                  v-for="(item, i) in enabled"
                  :key="i"
                  :value="item.value"
                  :color="item.value ? 'success' : 'error'"
                  class="white--text"
                >
                  <span>{{ $t(`shared.status.${item.text}`) }}</span>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClear">{{ $t('common.clear') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="onSearch">{{ $t('common.search') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["owners"],
  data() {
    return {
      dialog: false,
      enabled: [
        { value: true, text: "enabled" },
        { value: false, text: "disabled" }
      ],
      search: {},
      mounted: [],
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    onOpen() {
      this.dialog = true;
      this.search = this.$store.getters.search;
      if (isNaN(this.search.tx_power)) {
        this.search.tx_power = 0;
      }
      if (isNaN(this.search.battery)) {
        this.search.battery = 100;
      }
    },
    onSearch() {
      this.search.mounted = this.mounted;
      this.$store.dispatch("setSearch", this.search);
      this.onFetch();
    },
    onClear() {
      this.$store.dispatch("setSearch", {
        client_id: "",
        name: "",
        uuid: "",
        major: null,
        minor: null,
        tx_power: 0,
        battery: 100,
        local_name: "",
        module_id: ""
      });
      this.mounted = [];
      this.onFetch();
    },
    onFetch() {
      this.dialog = false;
      this.$emit("onFetch", this.search);
    }
  }
};
</script>

<style>
</style>
