export default role => {
  let menu;

  switch (role) {
    case "Administrator":
      menu = [
        { header: "Menu" },
        { action: "home", title: "Home", path: "/home" },
        { action: "public", title: "Clients", path: "/clients" },
        { action: "bluetooth", title: "Beacons", path: "/beacons" },
        { action: "smartphone", title: "Devices", path: "/devices" },
        {
          action: "location_on",
          title: "Locations",
          path: "/locations"
        },
        { action: "next_week", title: "Application requests", path: "/apply" },
        { action: "chat", title: "Inquiries", path: "/inquiries" },
        {
          action: "receipt",
          title: "Receipts",
          path: "/receipts"
        },
        {
          action: "announcement",
          title: "Announcements",
          path: "/announcements"
        },
        { action: "settings", title: "Settings", path: "/settings" }
      ];
      break;
    case "Manager":
      menu = [
        { header: "Menu" },
        { action: "home", title: "Home", path: "/home" },
        { action: "apps", title: "Apps", path: "/apps" },
        { action: "bluetooth", title: "Beacons", path: "/beacons" },
        { action: "smartphone", title: "Devices", path: "/devices" },
        { action: "layers", title: "Clusters", path: "/clusters" },
        { action: "place", title: "Regions", path: "/regions" },
        { action: "whatshot", title: "Actions", path: "/actions" },
        { action: "bar_chart", title: "Summary", path: "/summary" },
        { action: "person", title: "Users", path: "/users" },
        { action: "settings", title: "Settings", path: "/settings" }
      ];
      break;
    case "General":
      menu = [
        { header: "Menu" },
        { action: "home", title: "Home", path: "/home" },
        { action: "apps", title: "Apps", path: "/apps" },
        { action: "bluetooth", title: "Beacons", path: "/beacons" },
        { action: "smartphone", title: "Devices", path: "/devices" },
        { action: "layers", title: "Clusters", path: "/clusters" },
        { action: "place", title: "Regions", path: "/regions" },
        { action: "whatshot", title: "Actions", path: "/actions" },
        { action: "settings", title: "Settings", path: "/settings" }
      ];
      break;
  }
  return menu;
};
