// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import api from "./api";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "./stylus/main.styl";
import annotation from "chartjs-plugin-annotation";
import colorschemes from "chartjs-plugin-colorschemes";
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import VueMoment from "vue-moment";

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja",
  messages: {
    en: require("./i18n/lang/en.json"),
    ja: require("./i18n/lang/ja.json")
  }
});

Vue.use(Vuetify, {
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    primary: "#00897b",
    secondary: "#546e7a",
    accent: "#00897b",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  }
});

Vue.use(VueMoment);

Vue.config.productionTip = false;

/* eslint-disable no-new */
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('ja', {
    style: 'currency',
    currency: 'JPY',
    minimumFractionDigits: 0
  });
  return formatter.format(value);
})

store.subscribe((mutation, state) => {
  if (mutation.type === 'user' && mutation.payload.lang !== undefined && mutation.payload.lang) {
    i18n.locale = mutation.payload.lang
  }
})

new Vue({
  el: "#app",
  router,
  i18n,
  store,
  annotation,
  colorschemes,
  components: { App },
  template: "<App/>",
  render: h => h(App),
  created() {
    api.init();
    api.refetch();
  }
});
