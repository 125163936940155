<template>
  <v-card style="margin: 0 auto;">
    <br><br>
    <center>
      <apexchart width="90%" height=400 style="margin: 0 auto;" :options="Options" :series="this.my_series"
                 v-if="series.length && series[0].data.length>0"/>
    </center>
    <br><br><br>
    <v-alert
      :value="true"
      color="success"
      icon="information"
      outline
      v-if="series.length && series[0].data.length==0"
      style="width: 80%;"
    >
      {{$t('summary.not_found')}}
    </v-alert>
    <br><br><br>
  </v-card>
</template>

<script>
  import apexchart from 'vue-apexcharts';
  import moment from "moment"

  export default {
    props: ["series","selectedMonth"],
    components: {
      apexchart
    },

    data() {
      return {
        my_series:[],
        Options: {
          xaxis: {
            tickAmount: 0,
            labels: {
              show: true,
              hideOverlappingLabels: false,
              showDuplicates: true,
              trim: false,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                colors: []
              }
            },
            min: 1,
            max: null,
            axisTicks: {
              show:false
            },
          },
          chart: {
            toolbar: {
              show: false
            }
          }
        },



      }
    },



    beforeMount() {




     this.getWeekendInMonth()
    },
    methods:{
      getWeekendInMonth(){

        var month =this.selectedMonth.format('MM');
        var year =this.selectedMonth.format('YYYY');
        var colors=[]

        const last_day = Number(this.getLastDay(year,month).end);


        this.my_series=[{
          "data":this.series[0]["data"].slice(0,last_day),
          "type":"column",
          "name":"This day"

        },

          {
            "data":this.series[1]["data"].slice(0,last_day),
            "type":"line",
            "name":"Total"

          },
        ]



        this.Options.xaxis.tickAmount=last_day;

        for (var i = 1; i <= last_day; i++) {




          const day1=i;


          const full_date=year+"-"+month+"-"+day1;
      //   console.log(new Date(full_date));



        //  console.log(moment(full_date).day())

       //console.log(moment(full_date).subtract(1, 'weeks').startOf('isoWeek').format('dddd'));


          if (moment(full_date).day() === 6) {

            colors.push('#008FFB');
          } else if (moment(full_date).day() === 0) {
            colors.push('#FF4560');
          } else {
            colors.push('#000');
          }

        }
        
        this.Options.xaxis.labels.style.colors=[...colors];
        this.Options.xaxis.max=Number(last_day);

      },

      getLastDay(year, month)
      {

        var startDate = moment([year, month - 1]);

        // Clone the value before .endOf()
        var endDate = moment(startDate).endOf('month');


        return { end: endDate.format("DD")};

      }
    }


  }
</script>

<style scoped>

</style>
