<template>
  <v-layout row justify-center>
    <v-dialog v-model="visible" scrollable persistent no-click-animation lazy max-width="750px">

      <v-card>
        <v-card-title v-if="selected.type === 'app'" primary-title class="headline">{{ selected.title ? selected.title :
          `${selected.client_name}
          ${$t('common.created_app_1')} ${selected.app_name} ${$t('common.created_app_2')}` }}
        </v-card-title>
        <v-card-title v-else-if="selected.type === 'log'" primary-title class="headline">{{ `${selected.app_name}
          ${$t('common.log_title')}` }}
        </v-card-title>
        <v-card-title v-else primary-title class="headline">{{ selected.title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text height="300px">

          <div v-if="user.role === 'Administrator'">
            <v-subheader>{{ $t('common.destination') }}</v-subheader>
            <v-item-group multiple class="pl-2">
              <v-item
                v-if="selected.client_name"
              >
                <v-chip color="primary" dark>
                  {{ selected.client_name }}
                </v-chip>
              </v-item>
              <v-item v-else>
                <v-chip color="primary" dark>
                  {{ $t('role.Beacrew_Administrator')}}
                </v-chip>
              </v-item>
            </v-item-group>
          </div>

          <div align-center>
            <v-subheader>{{ $t('common.body') }}</v-subheader>
            <div v-if="selected.type === 'app'" class="px-3"
                 v-html="`${selected.client_name} ${$t('common.created_app_1')} ${selected.app_name} ${$t('common.created_app_2')}`"></div>
            <div v-else-if="selected.type === 'log'" class="px-3"
                 v-html="`${selected.app_name} ${$t('common.log_body')}`"></div>
            <div v-else class="px-3"
                 v-html="selected.body"></div>
          </div>

          <div v-if="selected.type" class="mt-4">
            <v-layout v-if="selected.type === 'app'" row align-center class="px-2">
              <v-subheader>{{ $t('common.creation_date')}}:</v-subheader>
              <div>{{selected.created}}</div>
            </v-layout>
            <v-layout v-else-if="selected.type === 'log'" row align-center class="px-2">
              <v-subheader>{{ $t('common.target_date')}}:</v-subheader>
              <div>{{selected.target_date}}</div>
            </v-layout>
            <v-layout row align-center class="px-2">
              <v-subheader>{{ $t('common.contract_plan')}}:</v-subheader>
              <div>{{selected.plan}}</div>
            </v-layout>
              <v-layout v-if="selected.type=== 'log'" row align-center class="px-2">
                <v-subheader>{{ $t('common.maximum_logs_per_month')}}:</v-subheader>
                <div>{{selected.maximum_logs_per_month}}</div>
              </v-layout>
              <v-layout v-if="selected.type=== 'log'" row align-center class="px-2">
                <v-subheader>{{ $t('common.number_of_logs')}}:</v-subheader>
                <div>{{selected.number_of_logs}}</div>
              </v-layout>
          </div>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="confirmationType">
          <p class="body-1 ml-4">{{ $t('comfirm.save.send')}}</p>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click="toggle">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="green darken-1" flat @click="send">{{ $t('common.ok') }}</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="toggle">{{ $t('common.ok') }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["selected", "visible", "confirmationType"],
  computed: {
    ...mapState(["user"])
  },
  methods: {
    toggle() {
      this.confirmationType ? this.$emit("cancel") : this.$emit("toggle");
    },
    send() {
      this.$emit("send");
    }
  }
};
</script>

<style scoped>
</style>
