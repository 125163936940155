<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">whatshot</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.action') }}</h3>
            <v-spacer></v-spacer>
            <mode-selection v-on:onSwitchMode="onSwitchMode"></mode-selection>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                      v =>  v && v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>URI</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.uri"
                    :rules="[
                      v => !v || /^[a-zA-Z0-9:/?#\[\]@!$&'()*+,;=.\-_]+$/.test(v) || $t('rules.unusable'),
                      v => !v || v.length <= 250 || $t('rules.max250c')
                    ]"
                    counter="250"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.action_interval') }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.interval"
                    :rules="[
                      v => !!v || v === 0 || $t('rules.required'),
                      v => v >= 0 || $t('rules.min0n'),
                      v => v <= 99999999 || $t('rules.max99999999n')
                    ]"
                    min="0"
                    max="99999999"
                    :suffix="$t('suffix.second')"
                    :hint="$t('discriptions.action_interval')"
                    persistent-hint
                    type="number"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.parameters') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-layout row v-for="(item, i) in params" :key="i">
                    <v-flex xs4>
                      <v-text-field
                        v-model="item.key"
                        :rules="[
                          v => !!v || $t('rules.required'),
                          // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                          v => v && v.length <= 45 || $t('rules.max45c')
                        ]"
                        counter="45"
                        :disabled="mode"
                        class="pt-0"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs8>
                      <v-text-field
                        v-model="item.value"
                        :rules="[
                          v => !!v || $t('rules.required'),
                          // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                          v => v && v.length <= 45 || $t('rules.max45c')
                        ]"
                        counter="45"
                        :disabled="mode"
                        class="pt-0"
                      ></v-text-field>
                    </v-flex>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click.stop="onRemoveBox(i)"
                      v-if="!mode"
                    >{{ $t('common.delete') }}</v-btn>
                  </v-layout>
                  <v-layout class="add-box">
                    <div class="inner">
                      <v-btn outline small color="primary" @click.stop="onAddBox" :disabled="params.length >= 99 || mode">{{ $t('common.add') }}</v-btn>
                    </div>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" :disabled="mode"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.created') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">
                  {{ items.created | dateFormat }}
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.updated') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">
                  {{ items.updated | dateFormat }}
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.assignment') }}</strong>
                </v-flex>

                <v-flex xs8 class="grey--text">
                  <v-card v-for="(item, i) in items.assignment" :key="i" class="list-card">
                    <div class="list-card-main" style="cursor: default; padding-right: 14px">
                      <div class="list-card-head" style="width: 100px">
                        <p class="list-card-text">
                          APP
                        </p>
                        <br>
                        <p class="list-card-text">
                          TARGET
                        </p>
                      </div>
                      <div class="list-card-body-without-border">
                        <p class="list-card-text">
                          <strong>{{ item.app_name }}</strong>
                        </p>
                        <p class="list-card-text">
                          {{ item.app_loco_id}}
                        </p>
                        <p class="list-card-text" style="margin-top: 5px;">
                          <strong>{{ "region_type" in item ? item.region_name : item.beacon_name}}</strong>
                        </p>
                        <p class="list-card-text">
                          {{ "region_type" in item ? item.region_loco_id : item.beacon_loco_id}}
                        </p>
                      </div>
                      <div>
                        <v-btn
                            v-if="'region_type' in item && item.region_type === 1"
                            disabled
                            style="width: 125px; background-color: #d9e9d4 !important; color: #070808 !important; border:0.5px solid #070808 !important"
                        >
                          region in
                        </v-btn>
                        <v-btn
                            v-else-if="'region_type' in item && item.region_type === 2"
                            disabled
                            style="width: 125px; background-color: #f4cbcc !important; color: #070808 !important; border:0.5px solid #070808 !important"
                        >
                          region out
                        </v-btn>
                        <v-btn
                            v-else
                            disabled
                            style="width: 125px; background-color: #d0e0e4 !important; color: #070808 !important; border:0.5px solid #070808 !important"
                        >
                          ranging
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <delete-confirmation
              v-on:onDelete="onDelete"
              :loading="loading"
              v-if="!mode"
            ></delete-confirmation>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid"
              :loading="loading"
              v-if="!mode"
            ></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import TimeFilter from "../../utils/filters/TimeFilter";
import ModeSelection from "../../components/ModeSelection";
import DeleteConfirmation from "../../components/dialogs/DeleteConfirmation";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";

export default {
  data() {
    return {
      mode: true,
      valid: false,
      loading: false,
      params: [],
      items: {}
    };
  },
  components: {
    ModeSelection,
    DeleteConfirmation,
    SaveConfirmation
  },
  mounted() {
    this.onFetch();
  },
  methods: {
    ...mapActions(["onNotify"]),
    onFetch() {
      axios.get(this.$route.path).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.payload;
          // Arraies
          this.params = this.items.params;
        } else {
        }
      });
    },
    onSwitchMode(val) {
      this.mode = val;
    },
    onRemoveBox(i) {
      this.params.splice(i, 1);
    },
    onAddBox() {
      this.params.push({
        key: "",
        value: ""
      });
    },
    onDelete() {
      this.loading = true;
      axios.delete(this.$route.path).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "error",
              text: "Deleting Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    },
    onSave() {
      this.loading = true;
      axios.put(this.$route.path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: this.$t('common.saved')
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  },
  mixins: [TimeFilter]
};
</script>

<style>
</style>
