<template></template>

<script>
export default {
  data() {
    return {
      position: {
        left: null,
        top: null
      }
    };
  },
  methods: {
    onInitPoint() {
        const x = this.item.x * 800 / this.naturalImage.width
        const y = this.item.y * this.max.y / this.naturalImage.height
        this.onDraw(x , y, this.max.y);
    },
    onClickMap(e) {
      let element = e.target;
      // DOMRect (Element position)
      let rect = element.getBoundingClientRect();
      // Mouse position
      let mouseX = e.pageX;
      let mouseY = e.pageY;

      console.log("mouseX:", mouseX);
      console.log("mouseY:", mouseY);

      // Element position
      let pointX = rect.left + window.pageXOffset;
      let pointY = rect.top + window.pageYOffset;

      console.log("pointX: ", pointX);
      console.log("pointY: ", pointY);

      // Pointer position
      let offsetX = Math.max(Math.floor(mouseX - pointX), 0);
      let offsetY = Math.max(Math.floor(mouseY - pointY), 0);

      console.log("offsetX: ", offsetX);
      console.log("offsetY: ", offsetY);

      // Pointer position (consider pointer size)
      let positionX = offsetX;
      let positionY = offsetY;

      console.log("positionX: ", positionX);
      console.log("positionY: ", positionY);

      // link inputs to position on click
      this.item.x = Math.floor(positionX * this.naturalImage.width / 800);
      this.item.y = Math.floor(positionY * this.naturalImage.height / this.max.y);

      this.onDraw(positionX, positionY, this.max.y);
    },
    onDraw(left, top, max_y = 1000) {
      // max_y is only used with the inputs. clicking outside the picture doesn't trigger an event
      const range_x = left > 800 ? 800 : left < 0 ? 0 : left;
      const range_y = top > max_y ? max_y : top < 0 ? 0 : top;
      const marker_radius = 12;
      // -12px is only for the display, item.x and item.y are not affected
      this.position.left = range_x - marker_radius;
      this.position.top = range_y - marker_radius;
    }
  }
};
</script>

<style>
</style>
