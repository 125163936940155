<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">public</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.client') }}</h3>
            <v-spacer></v-spacer>
            <mode-selection v-on:onSwitchMode="onSwitchMode"></mode-selection>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-z0-9-]+$/.test(v) || $t('rules.unusable'),
                      v => v && v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.image') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <div
                    @click.stop="onPickFile"
                    @dragover.prevent
                    @drop.prevent="onDropFile"
                    :class="[
                      'image-box',
                      mode ? 'disabled' : '',
                      error ? 'size' : ''
                    ]"
                  >
                    <span class="caption grey--text" v-if="!image">{{ $t('discriptions.image') }}</span>
                    <img :src="image" alt="" class="image small">
                    <br />
                    <v-btn outline small color="error" @click.stop="onRemoveFile" v-if="image && !mode">{{ $t('common.remove') }}</v-btn>
                    <input type="file" ref="file" accept="image/*" @change="onChangeFile">
                  </div>
                  <span class="caption error--text" v-if="error">{{ message }}</span>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" :disabled="mode"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <v-icon color="secondary">person</v-icon>
                  <strong>{{ $t('subtitle.manager') }}</strong>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="manager.id" disabled class="pt-0"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.name') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="manager.name"
                    :disabled="mode"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.email') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="manager.email"
                    :disabled="mode"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || $t('rules.format')
                    ]"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.password') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    :rules="[

                      v => /^$|^[a-zA-Z0-9/\*-.,\!#\$%&\(\)~\|_\+]+$/.test(v) || $t('rules.unusable'),
                      v => (v.length >= 8 || v.length == 0) || $t('rules.min8c')
                    ]"
                    :append-icon="visibility ? 'visibility' : 'visibility_off'"
                    :append-icon-cb="() => (visibility = !visibility)"
                    :type="visibility ? 'password' : 'text'"
                    :hint="$t('discriptions.password')"
                    persistent-hint
                    class="pt-0"
                    :disabled="mode"
                    v-model="manager.password"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.loco_share') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.loco_share" :disabled="mode"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.created') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">
                  {{ items.created | dateFormat }}
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.updated') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">
                  {{ items.updated | dateFormat }}
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <delete-confirmation
              v-on:onDelete="onDelete"
              :loading="loading"
              v-if="!mode"
            ></delete-confirmation>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid"
              :loading="loading"
              v-if="!mode"
            ></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <div class="text-xs-center grey--text">
          <v-icon color="secondary">apps</v-icon>
          <strong>APP SETTINGS</strong>
        </div>
        <v-card v-for="(item, i) in apps" :key="i" class="list-card">
          <div class="list-card-main">
            <div class="list-card-head">
              <p class="list-card-id">
                <strong>{{ item.id }}</strong>
              </p>
              <v-chip outline small label :color="item.enabled | enabled">{{ item.enabled ? $t('shared.status.enabled') : $t('shared.status.disabled') }}</v-chip>
            </div>
            <div class="list-card-thumb">
              <img :src="item.image" alt="" v-if="item.image">
              <img src="../../assets/noimage.png" alt="" v-else>
            </div>
            <div class="list-card-body">
              <div class="list-card-body-list">
                <dl class="list-card-dl">
                  <dt>{{ $t('items.name') }}</dt>
                  <dd>
                    <strong>{{ item.name }}</strong>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="list-card-body">
              <div class="list-card-body-list">
                <dl class="list-card-dl">
                  <dt>SENDING</dt>
                  <dd>
                    <v-checkbox
                      v-model="item.settings.sending"
                      hide-details
                    ></v-checkbox>
                  </dd>
                </dl>
                <dl class="list-card-dl">
                  <dt>AGGREGATE</dt>
                  <dd>
                    <v-checkbox
                      v-model="item.settings.aggregate"
                      hide-details
                    ></v-checkbox>
                  </dd>
                </dl>
                <dl class="list-card-dl">
                  <dt>PIN</dt>
                  <dd>
                    <v-text-field
                      v-model="item.pin"
                      mask="######"
                      hide-details
                      class="pt-0"
                    ></v-text-field>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="list-card-footer">
              <v-btn outline color="success" @click.stop="onOpenDialog(i)">{{ $t('common.save') }}</v-btn>
            </div>
          </div>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title>
              <v-icon color="secondary">check_circle</v-icon>
              <span>&ensp;</span>
              <span class="success--text">
                <strong>Save Confirmation</strong>
              </span>
            </v-card-title>
            <v-card-text>
              <v-layout row>
                <v-flex xs12>
                  <p class="grey--text">Are you sure you want to save this document?</p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn flat color="success" :loading="loading" @click.stop="onSaveApp">{{ $t('common.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import ColorFilter from "../../utils/filters/ColorFilter";
import TimeFilter from "../../utils/filters/TimeFilter";
import ImageMethod from "../../utils/methods/ImageMethod";
import ModeSelection from "../../components/ModeSelection";
import DeleteConfirmation from "../../components/dialogs/DeleteConfirmation";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";
import Api, {ApiHelper} from "../../api/lmp";

export default {
  data() {
    return {
      mode: true,
      valid: false,
      loading: false,
      dialog: false,
      items: {},
      apps: [],
      index: null,
      manager: {},
      visibility: true
    };
  },
  components: {
    ModeSelection,
    DeleteConfirmation,
    SaveConfirmation
  },
  mounted() {
    this.onFetch();
    this.onFetchApps();
    this.onFetchManager();
  },
  methods: {
    ...mapActions(["onNotify"]),
    onFetch() {
      axios.get(this.$route.path).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.payload;
          this.image = this.items.image;
        } else {
        }
      });
    },
    onFetchApps() {
      axios
        .get("/apps", {
          params: {
            client_id: this.$route.params.id,
            per_page: 1000,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.apps = response.data.payload.hits;
          } else {
          }
        });
    },
    onFetchManager() {
      axios
        .get("/manager", {
          params: {
            client_id: this.$route.params.id
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.manager = response.data.payload;
            this.manager.password = null;
          } else {
          }
        });
    },
    onSwitchMode(val) {
      this.mode = val;
    },
    onDelete() {
      let user = {email: encodeURIComponent(this.items.user.email)};
      let share_status = {loco_share: 0};
      let put_body = {...user, ...share_status};
      const url = ApiHelper.queryBuilder('/auth/user/delete_locoshare_account', put_body);
      Api.put(url).then(response => {
        console.log(response)
      });

      this.loading = true;
      axios.delete(this.$route.path).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "error",
              text: "Deleting Successfully!"
            });
          }, 1000);
        } else {
        }
      });
    },
    onSave() {
      // update loco share access, sync with lmp
      let user = {email: encodeURIComponent(this.items.user.email)};
      let share_status = {loco_share: this.items.loco_share};
      let put_body = {...user, ...share_status};
      const url = ApiHelper.queryBuilder('/auth/user/update_locoshare_access', put_body);
      Api.put(url).then(response => {
                console.log(response)
      });

      this.loading = true;
      let body = { ...this.items };
      body["user"] = { ...this.manager };
      axios.put(this.$route.path, body).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: this.$t('common.saved')
            });
          }, 1000);
        } else if (response.data.code === 409) {
            setTimeout(() => {
                this.loading = false;
                this.onNotify({
                    type: "error",
                    text: response.data.message,
                    multiLine: false
                });
            }, 1000);
        } else {
          setTimeout(() => {
            this.loading = false;
            let messages = response.data.payload;
            let text = "";
            Object.keys(messages).forEach(key => {
              text += `${messages[key]}<br>`;
            });
            this.onNotify({
              type: "error",
              text: text,
              multiLine: true
            });
          }, 1000);
        }
      });
    },
    onOpenDialog(i) {
      this.index = i;
      this.dialog = true;
    },
    onSaveApp() {
      this.loading = true;
      let items = this.apps[this.index];
      axios.put("/apps/" + items.id, items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.dialog = false;
            this.onNotify({
              type: "success",
              text: this.$t('common.saved')
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  },
  mixins: [ColorFilter, TimeFilter, ImageMethod]
};
</script>

<style>
</style>
