<template>
  <v-layout row justify-center>
    <v-dialog v-model="visible" persistent scrollable no-click-animation
              max-width="1250">
      <v-card>
        <v-layout row>
          <v-flex xs12>
            <v-toolbar color="white" class="elevation-1">
              <v-toolbar-title>
                <span class="caption">{{ $t('common.total') }}</span>
                {{ items.length }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn flat color="secondary" @click="dialog_create = true" @click.stop="showRead">{{
                $t('common.unread_only') }}
              </v-btn>
              <v-btn flat color="success" @click="dialog_create = true" @click.stop="showAll">{{
                $t('common.show_all') }}
              </v-btn>
            </v-toolbar>
          </v-flex>
        </v-layout>
        <v-card-text>
          <v-list two-line>
            <template v-for="(item, index) in items">
              <v-list-tile
                :key="'past'+item.id"
                :prepend-icon="item.action"
                avatar
                ripple
                @click="toggleShow(item.id)"
              >
                <v-list-tile-action>
                  <svg v-if="item.read" height="10" width="10">
                    <circle cx="5" cy="5" r="5" fill="#00897b"></circle>
                  </svg>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title v-if="item.type === 'app'" class="limit">
                    {{`${item.client_name} ${$t('common.created_app_1')} ${item.app_name}
                    ${$t('common.created_app_2')}` }}
                  </v-list-tile-title>
                  <v-list-tile-title v-else-if="item.type === 'log'" class="limit">
                    {{`${item.app_name} ${$t('common.log_title')}` }}
                  </v-list-tile-title>
                  <v-list-tile-title v-else class="limit">
                    {{item.title }}
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action class="prevent-wrapping">
                  <v-list-tile-action-text>{{ item.created }}</v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider
                inset
                v-if="index + 1 < items.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="success" @click="$emit('toggleBack')">{{
            $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: ["announcements", "visible"],
  data() {
    return {
      items: [...this.announcements]
      // or items: [...this.announcements].filter(item => item.read) if unread needs to be main
    };
  },
  watch: {
    announcements: function() {
      this.showAll();
      // or this.showRead if unread needs to be main
    }
  },
  methods: {
    toggleShow(id) {
      this.$emit("toggle", id);
    },
    showAll() {
      this.items = this.announcements;
    },
    showRead() {
      this.items = this.announcements.filter(item => item.read);
    }
  }
};
</script>

<style scoped>
.limit {
  max-width: 30ch;
}
</style>
