<template>
  <div class="mx-0">
    <v-btn outline color="error" @click.stop="dialog = true" :disabled="disabled">{{ $t('common.delete') }}</v-btn>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">delete</v-icon>
          <span>&ensp;</span>
          <span class="error--text">
            <strong>{{ $t('comfirm.delete.title') }}</strong>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs12>
              <p class="grey--text">{{ $t('comfirm.appdelete.text') }}</p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="error" :loading="loading" @click.stop="onDelete">{{ $t('common.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["disabled", "loading"],
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    onDelete() {
      this.$emit("onDelete");
    }
  }
};
</script>

<style>
</style>
