<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card>
      <v-card-title>
        <v-icon color="secondary">security</v-icon>
        <span>&ensp;</span>
        <span class="grey--text">
          <strong>{{ $t('comfirm.disable_2fa.title') }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <p class="grey--text">{{ $t('comfirm.disable_2fa.text') }}</p>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="secondary" @click.stop="onClose">{{ $t('common.close') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat color="error" :loading="loading" @click.stop="onDisable">{{ $t('common.disable') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  props: ["dialog"],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions(["onNotify"]),
    onDisable() {
      this.loading = true;
      axios.get("/2fa/disable").then(response => {
        if (response.data.code === 200) {
          this.loading = false;
          this.onClose();
          this.onSwitch();
          this.onNotify({
            type: "error",
            text: "Disabling Successfully!"
          });
        } else {
          this.loading = false;
        }
      });
    },
    onSwitch() {
      this.$emit("onSwitchGoogle2fa", false);
    },
    onClose() {
      this.$emit("onCloseDialog", "disable");
    }
  }
};
</script>

<style>
</style>
