<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <mode-selection v-on:onSwitchMode="onSwitchMode"></mode-selection>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-layout row>
          <v-flex xs2 class="text-xs-right grey--text pt-3">
            <strong>ID</strong>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="items.id"
              disabled
              class="pt-0"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs2 class="text-xs-right grey--text pt-3">
            <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-model="items.name"
              :rules="[
                v => !!v || $t('rules.required'),
                v => /^[a-z0-9-]+$/.test(v) || $t('rules.unusable'),
                v =>  v && v.length <= 45 || $t('rules.max45c')
              ]"
              counter="45"
              :disabled="mode"
              class="pt-0"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs2 class="text-xs-right grey--text pt-3">
            <strong>{{ $t('items.image') }}</strong>
          </v-flex>
          <v-flex xs6>
            <div
              @click.stop="onPickFile"
              @dragover.prevent
              @drop.prevent="onDropFile"
              :class="[
                'image-box',
                mode ? 'disabled' : '',
                error ? 'size' : ''
              ]"
            >
              <span class="caption grey--text" v-if="!image">{{ $t('discriptions.image') }}</span>
              <img :src="image" alt="" class="image small">
              <br />
              <v-btn outline small color="error" @click.stop="onRemoveFile" v-if="image && !mode">{{ $t('common.remove') }}</v-btn>
              <input type="file" ref="file" accept="image/*" @change="onChangeFile">
            </div>
            <span class="caption error--text" v-if="error">{{ message }}</span>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs2 class="text-xs-right grey--text">
            <strong>{{ $t('items.created') }}</strong>
          </v-flex>
          <v-flex xs6 class="grey--text">
            {{ items.created | dateFormat }}
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs2 class="text-xs-right grey--text">
            <strong>{{ $t('items.updated') }}</strong>
          </v-flex>
          <v-flex xs6 class="grey--text">
            {{ items.updated | dateFormat }}
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outline color="success" @click.stop="dialog = true" :disabled="!valid">{{ $t('common.save') }}</v-btn>
      <save-confirmation
        v-on:onClose="onClose"
        v-on:onSave="onSave"
        :dialog="dialog"
        :loading="loading"
        v-if="!mode"
      ></save-confirmation>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import TimeFilter from "../../utils/filters/TimeFilter";
import ImageMethod from "../../utils/methods/ImageMethod";
import ModeSelection from "../../components/ModeSelection";
import SaveConfirmation from "./dialogs/SaveConfirmation";

export default {
  data() {
    return {
      mode: true,
      valid: false,
      loading: false,
      dialog: false,
      items: {}
    };
  },
  components: {
    ModeSelection,
    SaveConfirmation
  },
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    this.onFetch();
  },
  methods: {
    ...mapActions(["onNotify"]),
    onFetch() {
      axios.get("/clients/" + this.user.client_id).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.payload;
          this.image = this.items.image;
        } else {
        }
      });
    },
    onSwitchMode(val) {
      this.mode = val;
    },
    onClose() {
      this.dialog = false;
    },
    onSave() {
      this.loading = true;
      axios
        .put("/clients/" + this.user.client_id, this.items)
        .then(response => {
          if (response.data.code === 200) {
            setTimeout(() => {
              this.loading = false;
              this.dialog = false;
              this.onNotify({
                type: "success",
                text: this.$t('common.saved')
              });
            }, 1000);
          } else {
            this.loading = false;
          }
        });
    }
  },
  mixins: [TimeFilter, ImageMethod]
};
</script>

<style>
</style>
