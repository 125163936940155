<template>
  <div class="mx-0">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">content_copy</v-icon>
          <span></span>
          <span class="secondary--text">
            <strong>警告</strong>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs12>
              <p class="grey--text">このビーコンをクラスタへ登録すると使用できるアプリケーションが限定されます。 宜しいですか？</p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="cancelAction">{{ $t('common.cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="secondary" @click.stop="okAction">{{ $t('common.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog"],
  methods: {
      cancelAction() {
          this.$emit("cancelAction");
      },
      okAction() {
          this.$emit("okAction");
      }
  }
};
</script>

<style>
</style>
