<template>
  <v-card style="margin: 0 auto;">


    <div style="height:400px;" :class="{'scrollable':true}" v-if="region_log_series.length && region_log_series[0].data.length>0 && !loading_show && show_me">
      <center>
        <apexchart width="100%" type="bar" :height="height"  style="margin: 0 auto;" :options="chartOptions" :series="region_log_series" />
      </center>

    </div>



    <div v-if="region_log_series.length && region_log_series[0].data.length==0 && !loading_show">
    <br>
    <br>
    <v-alert
      :value="true"
      color="success"
      icon="information"
      outline
      style="width: 80%; margin-top: 20px; margin-bottom: 20px;"
    >
      {{$t('summary.not_found')}}
    </v-alert>
    <br><br>
    </div>
  </v-card>
</template>


<script>

  import apexchart from 'vue-apexcharts'
  import Spinner from "vue-simple-spinner";

  export default {
    props: ["series","loading_show"],
    components: {
      apexchart,
      Spinner
    },

    data() {
      return {
        height: "400px",
        show_me:false,
        region_log_series: [{
          name: "IN",
          data: []
        },
          {
            name: "OUT",
            data: []
          }
        ],
        chartOptions: {
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          xaxis: {
            type: "categories",
            categories: [],
            tickAmount: 0,
            tickPlacement: "on"
          },
          chart: {
            toolbar: {
              show: false
            }
          },
        },
      }
    },
    watch:{
      series:function(newVal,oldVal){
        if("regions_in" in newVal && "regions_out" in newVal){
          // add 70 as margin, otherwise if there is only one region graph can't show properly
          this.height= 70 + newVal.regions_in.length*80+"px";

          let regions_in=newVal.regions_in.map(x=>x.count);
          let regions_out=newVal.regions_out.map(x=>x.count);

          const max_value_region_in=Math.max(...regions_in);
          const max_value_region_out=Math.max(...regions_out);


          this.chartOptions.xaxis.tickAmount=(Math.max(max_value_region_in,max_value_region_out)<9)?Math.max(max_value_region_in,max_value_region_out):9;

          let categories= newVal.regions_out.map(x=>x.name);

          // char options
          const charOptions = {...this.chartOptions};
          charOptions["xaxis"]["categories"]= [...categories];
          this.chartOptions = {...charOptions};

          // series
          const reg_series = [...this.region_log_series].map(a => ({...a}));
          reg_series[0].data =regions_in;
          reg_series[1].data = regions_out;
          this.region_log_series = reg_series;
          this.show_me=true;


        }
      }
    }
  }


</script>

<style>
  .scrollable{
    overflow-y: scroll;

  }
</style>


