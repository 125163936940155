<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">layers</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.cluster') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="items.id" disabled class="pt-0"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.name') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    autofocus
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-2">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.parent') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-select
                    :items="parants"
                    item-text="name"
                    item-value="id"
                    v-model="items.parent_id"
                    class="pt-0"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-2">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.tag') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.tag"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.image') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <div
                    @click.stop="onPickFile"
                    @dragover.prevent
                    @drop.prevent="onDropFile"
                    :class="[
                      'image-box',
                      error ? 'size' : ''
                    ]"
                  >
                    <span class="caption grey--text" v-if="!image">{{ $t('discriptions.image') }}</span>
                    <img :src="image" alt class="image">
                    <br>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click.stop="onRemoveFile"
                      v-if="image"
                    >{{ $t('common.remove') }}</v-btn>
                    <input type="file" ref="file" accept="image/*" @change="onChangeFile">
                  </div>
                  <span class="caption error--text" v-if="error">{{ message }}</span>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.cm_per_pixel') }}</strong>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="items.cm_per_pixel"
                    :rules="[
                      v => v >= 0 || $t('rules.min0n'),
                      v => !v || /^[\d+(\.\d+)?]+$/.test(v) || $t('rules.unusable')
                    ]"
                    suffix="cm"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.att_coef') }}</strong>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="items.att_coef"
                    :rules="[
                      v => v >= 0 || $t('rules.min0n'),
                      v => !v || /^[\d+(\.\d+)?]+$/.test(v) || $t('rules.unusable')
                    ]"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox style="margin-top: 3px;" v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                </v-flex>
                <v-flex xs4 class="draggable-wrap small">
                  <draggable
                    :list="beacons.map((b, i) => ({...b, prevIndex: i}))"
                    class="draggable-area small"
                    :options="{ group: 'beacons', animation: 300 }"
                    @change="checkCanRemove"
                  >
                    <v-list v-for="(beacon, i) in beacons" :key="i" class="draggable-item small">
                      <v-list-tile>
                        <v-list-tile-action style="min-width: 30px;">
                          <v-icon>{{ beacon.hasOwnProperty('type') && beacon.type === "device" ? "smartphone" : "bluetooth"}}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title><div>
                            <strong style="white-space: nowrap">{{ beacon.name }}</strong></div>
                          </v-list-tile-title>
                          <v-list-tile-sub-title  v-if="beacon.shared_apps && user.client_id !== beacon.client_id">
                            <div>
                              <span
                                style="white-space: nowrap"
                              >App: {{ `${beacon.shared_apps.filter(app => apps.includes(app.id)).slice(0, 2).map(app => app.name).join(', ')}${beacon.shared_apps.filter(app => apps.includes(app.id)).length > 2 ? '...' : ''}`}}</span>
                            </div>
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action v-if="beacon.shared && user.client_id !== beacon.client_id">
                          <div>
                            <span style="white-space: nowrap; color: blue;">Shared</span>
                          </div>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </draggable>
                </v-flex>
                <v-icon large>keyboard_arrow_right</v-icon>
                <v-flex xs4 class="draggable-wrap small">
                  <draggable
                    :list="items.beacons"
                    class="draggable-area destination small"
                    :options="{ group: 'beacons', animation: 300 }"
                    @change="checkCanDrop"
                  >
                    <v-list
                      v-for="item in items.beacons.map((b, i) => ({...b, prevIndex: i}))"
                      :key="item.id"
                      class="draggable-item small"
                    >
                      <v-list-tile>
                        <v-list-tile-action style="min-width: 30px;">
                          <v-icon>{{ item.hasOwnProperty('type') && item.type === "device" ? "smartphone" : "bluetooth"}}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            <v-layout
                              align-center
                              justify-space-between
                              row
                              style="width: 100%"
                            >

                              <strong style="white-space: nowrap; margin: 8px">{{ item.name }}</strong>
                              <svg v-if="item.x > 0 && item.y > 0 || item.set" height="10" width="10">
                                <circle cx="5" cy="5" r="5" fill="#00897b"></circle>
                              </svg>
                            </v-layout>
                          </v-list-tile-title>
                          <v-list-tile-sub-title
                            v-if="item.shared_apps && user.client_id !== item.client_id"
                          >
                            <div>
                              <span
                                style="white-space: nowrap"
                              >App: {{ `${item.shared_apps.filter(app => apps.includes(app.id)).slice(0, 2).map(app => app.name).join(', ')}${item.shared_apps.filter(app => apps.includes(app.id)).length > 2 ? '...' : ''}`}}</span>
                            </div>
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action v-if="item.shared && user.client_id !== item.client_id">
                          <div>
                            <span
                              style="white-space: nowrap; color: blue; text-overflow: ellipsis;"
                            >Shared</span>
                          </div>
                        </v-list-tile-action>
                        <beacon-setting
                          :selected="item"
                          :key="item.id"
                          :index="item.prevIndex"
                          :beaconsData="items.beacons"
                          :beaconsDisplay="onFilterBeacons(item.id)"
                          :image="image"
                          v-if="item.type === 'beacon'"
                          @onClose="onForceUpdate"
                        ></beacon-setting>
                        <device-setting
                          :selected="item"
                          :key="item.id"
                          :index="item.prevIndex"
                          :beaconsData="items.beacons"
                          :beaconsDisplay="onFilterBeacons(item.id)"
                          :image="image"
                          v-if="item.type === 'device'"
                          @onClose="onForceUpdate"
                        ></device-setting>
                      </v-list-tile>
                    </v-list>
                  </draggable>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <add-beacon-to-cluster-confirmation :dialog="checkAddBeaconIndex !== null" @okAction="moveBeacon" @cancelAction="cancelMoveBeacon"></add-beacon-to-cluster-confirmation>
            <conflicting-beacon-to-cluster-confirmation :dialog="checkConflictingBeaconIndex !== null" @okAction="checkConflictingBeaconIndex = null"></conflicting-beacon-to-cluster-confirmation>
            <save-confirmation v-on:onSave="onSave" :valid="valid" :loading="loading"></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapState, mapActions } from "vuex";
import ColorFilter from "../../utils/filters/ColorFilter";
import ImageMethod from "../../utils/methods/ImageMethod";
import ClusterMethod from "../../utils/methods/ClusterMethod";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";
import AddBeaconToClusterConfirmation from "../../components/dialogs/AddBeaconToClusterConfirmation";
import ConflictingBeaconToClusterConfirmation from "../../components/dialogs/ConflictingBeaconToClusterConfirmation";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      items: {
        id: uuid(),
        client_id: "",
        name: "",
        tag: "",
        image: "",
        cm_per_pixel: null,
        att_coef: "2.0",
        parent_id: "",
        enabled: true,
        beacons: []
      },
        checkAddBeaconIndex: null,
        checkConflictingBeaconIndex: null,
        confirmedAppLimits: [],
        apps: []
    };
  },
  components: {
      ConflictingBeaconToClusterConfirmation,
      AddBeaconToClusterConfirmation,
    SaveConfirmation
  },
  computed: {
    ...mapState(["user"]),
    handleDataItem() {
      let data = {...this.items};
      let arrBeacons = data.beacons.filter((item) => item.type === "beacon");
      let arrDevices = data.beacons.filter((item) => item.type === "device");
      data.beacons = arrBeacons.map(({ type, ...beacon }) => beacon);
      data.devices = arrDevices.map(({ type, ...device }) => device);
      return data;
    },
  },
  mounted() {
    this.onFetchParents();
    this.onFetchBeacons();
      this.onFetchApps();
  },
  methods: {
      onFetchApps() {
          axios
              .get("/apps", {
                  params: {
                      client_id: this.$route.params.id,
                      per_page: 1000,
                      page: 1
                  }
              })
              .then(response => {
                  if (response.data.code === 200) {
                      const apps = response.data.payload.hits;
                      this.apps = apps.map(app => app.id);
                  } else {
                  }
              });
      },
      cancelMoveBeacon() {
          this.items.beacons.splice(this.checkAddBeaconIndex.newIndex, 1);
          this.checkAddBeaconIndex = null;
      },
      moveBeacon() {
          this.beacons.splice(this.checkAddBeaconIndex.element.prevIndex, 1);
          this.checkAddBeaconIndex = null;
      },
      checkCanDrop(evt) {
          if('added' in evt) {
              // add shared beacons with alert
              if(evt.added.element.shared && evt.added.element.client_id !== this.user.client_id) {
                  // if beacon with single app being registered, beacon with same single app can be registered, or if beacon with multiple apps includes the single app that being registered
                  const existSingleApp = this.confirmedAppLimits.length === 1 && evt.added.element.shared_app_id.filter(x => this.confirmedAppLimits.includes(x)).length > 0;

                  // if beacon with multiple apps being registered, only beacon with same multiple apps can be registered
                  const filter_clients_shared_apps = evt.added.element.shared_app_id.filter(x => this.apps.includes(x)).length > 0 ? evt.added.element.shared_app_id.filter(x => this.apps.includes(x)) : [];
                  const filter_clients_shared_apps_string = filter_clients_shared_apps.sort().join(',');
                  const existMultipleApps = this.confirmedAppLimits.length  > 1 && filter_clients_shared_apps_string === this.confirmedAppLimits.sort().join(',');
                  if(this.confirmedAppLimits.length == 0){
                      this.checkAddBeaconIndex = evt.added;
                      this.confirmedAppLimits = evt.added.element.shared_app_id.filter(x => this.apps.includes(x)).length > 0 ? evt.added.element.shared_app_id.filter(x => this.apps.includes(x)) : [];
                  }else{
                    if(!existSingleApp && !existMultipleApps) {
                        // conflicted app
                        this.checkConflictingBeaconIndex = evt.added;
                        this.items.beacons.splice(evt.added.newIndex, 1);
                    } else {
                        this.beacons.splice(evt.added.element.prevIndex, 1)
                    }
                  }
              }
              // add owners's own beacon
              else {
                  this.beacons.splice(evt.added.element.prevIndex, 1)
              }
          }
      },
      checkCanRemove(evt) {
          if('added' in evt) {
              if(evt.added.element.shared) {
                  this.checkRemoveBeaconIndex = evt.added;
                  this.confirmedAppLimits = [...this.items.beacons].splice(evt.added.element.prevIndex, 1).reduce((t,b) => [...t, ...b.shared_app_id || []], []);
              }
              this.beacons.splice(evt.added.newIndex, 0, evt.added.element)
          }
      },
      onForceUpdate() {
          // need to force update since the draggable item is not updated when x,y change.
          this.$forceUpdate();
      },
    ...mapActions(["onNotify"]),
    onSave() {
      this.loading = true;
      let path = this.$route.path.replace(/\/new/g, "");
      axios.post(path, this.handleDataItem).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: "Creating Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  },
  mixins: [ColorFilter, ImageMethod, ClusterMethod]
};
</script>

<style>
</style>
