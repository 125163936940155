<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar color="white" class="elevation-1">
          <v-toolbar-title>
            <span class="caption">{{ $t("common.receipt_fees") }}</span>{{ items.reduce((t, x) => x.location.reduce((t1, l) => t1+l.usage_fee*l.royalty*(l.application ? l.application.length : 0), t),0) | toCurrency}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <receipts-search :onSearch="onFetch"></receipts-search>
          <v-btn outline color="success" @click="onCSVDownload">{{
            $t("manual.download_csv")
            }}
          </v-btn>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-card
          v-for="company in items" :key="company.id"
          class="list-card"
          :to="`${$route.path}/${company.id}`"
        >
          <card-receipt :item="company"></card-receipt>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import ReceiptsSearch from "../../components/search/ReceiptsSearchOperations";
    import CardReceipt from "../../components/cards/CardReceipt";
    import Api, {ApiHelper} from "../../api/lmp";

    export default {

        components: {
            ReceiptsSearch,
            CardReceipt
        },
        mounted() {
            this.onFetch();
        },
        data() {
            return {
                total: 2,
                rowsPerPage: 1,
                pagination: 1,
                items: []
            };
        },
        methods: {
            onFetch(company_id = null) {
                const url = (company_id ? `/company/${company_id}` : '/company') + '?with[]=location&with[]=location.application&filter[]=location.application.active()';
                return Api.get(url)
                    .then(response => {
                        const data = Array.isArray(response.data) ? response.data : [response.data];
                        this.items = data;
                        this.total = data.length;
                    });
            },
            onCSVDownload() {
                return Api.get('/company/csv')
                    .then(response => {
                        var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
                        var url = URL.createObjectURL(blob);
                        var link = document.createElement("a");
                        link.setAttribute("href", url);
                        link.setAttribute("download", "my_data.csv");
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        }
    };
</script>

<style scoped></style>
