var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"auth-card"},[(!_vm.google2fa)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h1',[_vm._v("Login")])])],1):_vm._e(),(_vm.google2fa)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h1',[_vm._v("Google Authentication")])])],1):_vm._e(),(!_vm.google2fa)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"prepend-icon":"mail","rules":[
            v => !!v || 'This field is required'
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"prepend-icon":"lock","rules":[
            v => !!v || 'This field is required'
          ],"append-icon":_vm.view ? 'visibility' : 'visibility_off',"type":_vm.view ? 'password' : 'text'},on:{"click:append":() => (_vm.view = !_vm.view)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1):_vm._e(),(_vm.google2fa)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onLogin.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[
            v => !!v || 'This field is required',
            v => v && v.length == 6 || 'This field is must be a 6 digit number'
          ],"type":"number"},model:{value:(_vm.totp),callback:function ($$v) {_vm.totp=$$v},expression:"totp"}})],1)],1)],1):_vm._e(),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{attrs:{"block":"","outline":"","color":"primary","disabled":!_vm.valid},on:{"click":function($event){$event.stopPropagation();return _vm.onLogin.apply(null, arguments)}}},[_vm._v(_vm._s(!_vm.google2fa ? 'login' : 'verify'))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }