<template>
  <div v-if="position.left !== -12 && position.right !== -12">
    <v-tooltip left color="orange lighten-3">
      <template v-slot:activator="{ on }">
        <v-btn color="deep-orange darken-1" icon v-if="percentage >= 80 && percentage <= 100 && position.left" v-on="on" :class="{ current : active_element === name }"
               :style="`position: absolute; left: ${position.left}px; top: ${position.top}px; z-index: 1; height: 25px; width:25px`"></v-btn>
      </template>
      <span style="color: #3e2723">{{ name }}</span>
    </v-tooltip>
    <v-tooltip left color="orange lighten-3">
      <template v-slot:activator="{ on }">
        <v-btn color="amber lighten-1" icon v-if="percentage >= 60 && percentage <= 79 && position.left" v-on="on" :class="{ current : active_element === name }"
               :style="`position: absolute; left: ${position.left}px; top: ${position.top}px; z-index: 1; height: 25px; width:25px`"></v-btn>
      </template>
      <span style="color: #3e2723">{{ name }}</span>
    </v-tooltip>
    <v-tooltip left color="orange lighten-3">
      <template v-slot:activator="{ on }">
        <v-btn color="yellow lighten-2" icon v-if="percentage >= 40 && percentage <= 59 && position.left" v-on="on" :class="{ current : active_element === name }"
               :style="`position: absolute; left: ${position.left}px; top: ${position.top}px; z-index: 1; height: 25px; width:25px`"></v-btn>
      </template>
      <span style="color: #3e2723">{{ name }}</span>
    </v-tooltip>
    <v-tooltip left color="orange lighten-3">
      <template v-slot:activator="{ on }">
        <v-btn color="light-green lighten-1" icon v-if="percentage >= 20 && percentage <= 39 && position.left" v-on="on" :class="{ current : active_element === name }"
               :style="`position: absolute; left: ${position.left}px; top: ${position.top}px; z-index: 1; height: 25px; width:25px`"></v-btn>
      </template>
      <span style="color: #3e2723">{{ name }}</span>
    </v-tooltip>
    <v-tooltip left color="orange lighten-3">
      <template v-slot:activator="{ on }">
        <v-btn color="blue accent-1" icon v-if="percentage >= 1 && percentage <= 19 && position.left" v-on="on" :class="{ current : active_element === name }"
               :style="`position: absolute; left: ${position.left}px; top: ${position.top}px; z-index: 1; height: 25px; width:25px`"></v-btn>
      </template>
      <span style="color: #3e2723">{{ name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: "LogPointer",
    props: ["position", "percentage", "name", "active_element"]
  }
</script>

<style scoped>
  .current {
    position: absolute;
    animation: flash 1.2s infinite;
  }

  @keyframes flash {
    50% {
      opacity: 0.2;
    }
  }
</style>
