var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-spacer'),_c('mode-selection',{on:{"onSwitchMode":_vm.onSwitchMode}})],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
              v => !!v || _vm.$t('rules.required'),
              v => /^[a-z0-9-]+$/.test(v) || _vm.$t('rules.unusable'),
              v =>  v && v.length <= 45 || _vm.$t('rules.max45c')
            ],"counter":"45","disabled":_vm.mode},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.image')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:[
              'image-box',
              _vm.mode ? 'disabled' : '',
              _vm.error ? 'size' : ''
            ],on:{"click":function($event){$event.stopPropagation();return _vm.onPickFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDropFile.apply(null, arguments)}}},[(!_vm.image)?_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.$t('discriptions.image')))]):_vm._e(),_c('img',{staticClass:"image small",attrs:{"src":_vm.image,"alt":""}}),_c('br'),(_vm.image && !_vm.mode)?_c('v-btn',{attrs:{"outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveFile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.remove')))]):_vm._e(),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChangeFile}})],1),(_vm.error)?_c('span',{staticClass:"caption error--text"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.created')))])]),_c('v-flex',{staticClass:"grey--text",attrs:{"xs6":""}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.items.created))+" ")])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.updated')))])]),_c('v-flex',{staticClass:"grey--text",attrs:{"xs6":""}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.items.updated))+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outline":"","color":"success","disabled":!_vm.valid},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v(_vm._s(_vm.$t('common.save')))]),(!_vm.mode)?_c('save-confirmation',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading},on:{"onClose":_vm.onClose,"onSave":_vm.onSave}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }