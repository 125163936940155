import axios from "axios";
import config from "../config/lmp";

const lmpInstance = axios.create({
  baseURL: `${config}/api`
});

lmpInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;

lmpInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
}, (error) => Promise.reject(error));


export class ApiHelper {
  static queryBuilder(path, params) {
    var paramStr = "";
    const alreadyHasParameters = path.includes("?");

    Object.keys(params).forEach(p => {
      if (params[p] !== null && params[p] !== "" && params[p] !== undefined) {
        if (paramStr !== "" || alreadyHasParameters) {
          paramStr += "&";
        }
        if (params[p].constructor !== Boolean) {
          paramStr += `${p}=${params[p]}`;
        } else {
          paramStr += `${p}=${params[p] ? 1 : 0}`;
        }
      }
    });

    if (paramStr !== "") {
      if (alreadyHasParameters) {
        return path + paramStr;
      } else {
        return path + "?" + paramStr;
      }
    }
    return path;
  }
}

export default lmpInstance;
