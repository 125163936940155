<template>
  <div class="mx-0" style="height: 100%; min-height: 100px;">
    <v-card @mouseover.stop="hovering = true" @mouseout.stop="hovering = false" @click.stop="dialog = true" :color="hovering ? 'grey lighten-2' : 'grey lighten-3'" style="height: 100%;">
      <v-card-text>
        <div v-if="photo" >
          <v-img
            :src="photoModel.image_default"
            height="150px"
          ></v-img>
          <v-icon v-if="editable" large color="orange" style="position: absolute;top: 45%;left: 45%;">
            edit
          </v-icon>
        </div>
        <v-icon large color="green" style="position: absolute;top: 45%;left: 45%;" v-else-if="editable">
          add_box
        </v-icon>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" v-if="editable" width="500" class="mx-auto">
      <div>
        <v-card v-if="photo">
          <v-card-title>
            <v-icon>edit</v-icon>
            <span><strong>{{ $t('common.photo') }}</strong></span>
            <v-spacer></v-spacer>
            <v-btn flat color="error" :loading="loading" @click.stop="onDelete">{{ $t('common.delete') }}</v-btn>
          </v-card-title>
          <v-card-text>
            <v-img v-if="photo"
                   :src="photoModel.image_default"
                   max-width="500px" contain
                   @click.stop="dialog = true"
            ></v-img>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="success" :loading="loading" @click.stop="onSave">{{ $t('common.save') }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title>
            <v-icon>add_box</v-icon>
            <span><strong>{{ $t('common.photo') }}</strong></span>
          </v-card-title>
          <v-card-text>
            <v-flex xs12 class="text-xs-center text-sm-center text-md-center text-lg-center">
              <v-img max-width="500px" contain :src="photoModel.image_default" v-if="photoModel.image_default"/>
              <v-text-field :label="$t('fields.file_picker')" @click='pickFile' v-model='imageName' prepend-icon='attach_file'></v-text-field>
              <input
                type="file"
                style="display: none"
                ref="image"
                accept="image/*"
                @change="onFilePicked"
              >
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="secondary" :loading="loading" @click.stop="onAdd">{{ $t('common.add') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog" v-else-if="photo" width="500" class="mx-auto">
      <v-img
        :src="photoModel.image_default"
        max-height="500px" contain
        @click.stop="dialog = true"
      ></v-img>
    </v-dialog>
  </div>
</template>

<script>

    export default {
        props: ["photo", "addImage", "deleteImage", "editable", "index"],
        components: {},
        data() {
            return {
                dialog: false,
                hovering: false,
                imageName: '',
                imageFile: '',
                photoModel: this.photo ? this.photo : {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    image_default: 0,
                },
                loading: false
            };
        },
        watch: {
            photo: function (newVal, oldVal) {
                this.photoModel = newVal ? newVal : {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    image_default: 0,
                };
            }
        },
        methods: {
            pickFile() {
                this.$refs.image.click()
            },
            onFilePicked(e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.imageName = files[0].name;
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return;
                    }
                    const fr = new FileReader();
                    fr.readAsDataURL(files[0]);
                    fr.addEventListener('load', () => {
                        this.photoModel.image_default = fr.result;
                        this.imageFile = files[0]; // this is an image file that can be sent to server...
                    });
                } else {
                    this.imageName = '';
                    this.imageFile = '';
                    this.photoModel.image_default = '';
                }
            },
            onAdd() {
                this.addImage(this.imageFile, this.photoModel)
                    .then(() => this.dialog = false)
            },
            onDelete() {
                this.deleteImage(this.photoModel, this.index).then(() => this.dialog = false)
            }
        }
    };
</script>

<style>
</style>
