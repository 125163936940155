<template></template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import BeaconSetting from "../../pages/clusters/BeaconSetting";
import DeviceSetting from "../../pages/clusters/DeviceSetting";

export default {
  data() {
    return {
      parants: [],
      beacons: [],
      clusters: [],
      devices: [],
    };
  },
  components: {
    draggable,
    BeaconSetting,
    DeviceSetting
  },
  methods: {
    onFetchParents() {
      axios
        .get("/clusters/allExcept", {
          params: {
            except: this.items.id
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.parants = response.data.payload.hits;
            this.parants.unshift({
              id: "",
              name: ""
            });
          } else {
          }
        });
    },
    onFilterBeacons(id) {
      return this.items.beacons.filter(item => item.id !== id);
    },
    onFetchBeacons() {
      axios.get("/clusters/beacons").then(response => {
        if (response.data.code === 200) {
          this.beacons = response.data.payload.hits.filter(item => item.enabled && item.mounted);
          this.onFetchDevices();
        } else {
        }
      });
    },
    onFetchDevices() {
      axios.get("/clusters/devices").then(response => {
        if (response.data.code === 200) {
          this.devices = response.data.payload.hits.filter(item => item.status && item.mounted);
          this.handleDataBeacons();
        } else {
        }
      });
    },
    handleDataBeacons() {
      let arrBeacons = this.beacons.map((beacon) => ({...beacon, type: "beacon", displayed: true}));
      let arrDevices = this.devices.map((device) => ({...device, type: "device", displayed: true}));
      this.beacons = [...arrBeacons, ...arrDevices];
    },
    onFetchAllParents() {
      // get all the clusters
      // the v-select in ClustersSearchOpeations which uses it will store the cluster id as parent id
      // the search will then look for any cluster which this cluster as parent
      axios.get("/clusters/parents").then(response => {
        if (response.data.code === 200) {
          this.clusters = response.data.payload.hits;
        } else {
        }
      });
    }
  }
};
</script>

<style>
</style>
