<template>
  <v-dialog v-model="item" max-width="550" class="mx-auto">
    <v-card>
      <v-card-title primary-title>
        <v-layout row justify-space-between dense>
          <v-flex>{{ item.name }}</v-flex>
          <v-subheader>
            {{ this.prefecture }}
          </v-subheader>
          <v-subheader>{{ this.city }}</v-subheader>
        </v-layout>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs6>
            <span>{{ $t('card_location.type') }}: {{ facilities }}</span><br/>
            <span>{{ $t('card_location.beacon_count') }}: {{ item.beacon_count }}</span><br/>
            <span>単価： {{ item.usage_fee }}円</span><br/>
          </v-flex>
          <v-flex xs6>
            <v-text-field suffix="%" disabled label="現在のロイヤリティ率" :value="item.royalty*100"></v-text-field>
            <v-text-field suffix="%" type="number" label="翌月以降のロイヤリティ率" v-model="newRoyalty"></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="onCancel">キャンセル</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.stop="onSubmit">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

    import {getType} from "../../utils/facilities";
    import {CITY_SELECT_VALUE, PREFECTURE_SELECT_VALUE} from "../../utils/masterdata";
    import Api, {ApiHelper} from "../../api/lmp";

    export default {
        props: ["item", "onCancel", "submit"],
        mounted() {
            if (this.item) {
                this.setup(this.item);
            }
        },
        data() {
            return {
                facilities: '',
                prefecture: '',
                city: '',
                newRoyalty: 0,
            };
        },
        methods: {
            onSubmit() {
                const newItem = {...this.item, new_royalty: this.newRoyalty/100};
                this.submit(newItem).then(() => this.onCancel());
            },
            setup(item) {
                this.newRoyalty = item.new_royalty * 100;
                this.facilities = getType(item);
                this.prefecture = PREFECTURE_SELECT_VALUE(item.prefecture).text;
                this.city = CITY_SELECT_VALUE(item.prefecture, item.city).text;
            }
        }
    };
</script>

<style>
</style>
