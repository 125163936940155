<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">bluetooth</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.beacon') }}</h3>
            <span>&ensp;</span>
            <v-chip
              small
              label
              color="blue"
              class="white--text"
              v-if="items.shared && user.client_id !== items.client_id"
            >{{ $t('common.shared') }}</v-chip>
            <v-spacer></v-spacer>
            <mode-selection
              v-on:onSwitchMode="onSwitchMode"
              :disable="user.role === 'Administrator' ? false : items.shared"
            ></mode-selection>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="items.id" disabled class="pt-0"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row v-if="user.role === 'Administrator'">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.owner') }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-select
                    :items="owners"
                    item-text="name"
                    item-value="id"
                    v-model="items.client_id"
                    :disabled="mode"
                    class="pt-0"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span v-if="!items.mounted" class="red--text">*</span>{{ $t("items.app") }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-autocomplete
                    :items="apps"
                    item-text="name"
                    item-value="id"
                    v-model="items.app_id"
                    :disabled="mode || isAppDisabled || user.role !== 'Administrator'"
                    clearable
                    class="pt-0"
                    :rules="!items.mounted ? [
                      v => !!v || $t('rules.required'),
                    ] : []"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row >
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.is_mounted') }}</strong>
                </v-flex>
                <v-flex xs6 @click="onClickMounted">
                  <v-radio-group row v-model="items.mounted" :disabled="mode || appBound || clusterBound || user.role !== 'Administrator'">
                    <v-radio :label="$t('items.mounted')" :value="true"></v-radio>
                    <v-radio :label="$t('items.movable')" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.name') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                      v => v && v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>UUID
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.uuid"
                    placeholder="########-####-####-####-############"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[0-5][a-fA-F0-9]{3}-[089aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/.test(v) || $t('rules.format')
                    ]"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>MAJOR /
                    <span class="red--text">*</span>MINOR
                  </strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.major"
                    :rules="[
                      v => !!v || v === 0 || $t('rules.required'),
                      v => v >= 0 || $t('rules.min0n'),
                      v => v <= 65535 || $t('rules.max65535n')
                    ]"
                    min="0"
                    max="65535"
                    :hint="$t('discriptions.major_minor')"
                    persistent-hint
                    type="number"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.minor"
                    :rules="[
                      v => !!v || v === 0 || $t('rules.required'),
                      v => v >= 0 || $t('rules.min0n'),
                      v => v <= 65535 || $t('rules.max65535n')
                    ]"
                    min="0"
                    max="65535"
                    :hint="$t('discriptions.major_minor')"
                    persistent-hint
                    type="number"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>TX POWER(RSSI at 1m)
                  </strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.tx_power"
                    :rules="[
                      v => !!v || v === 0 || $t('rules.required'),
                      v => v >= -255 || $t('rules.min-255n'),
                      v => v <= 0 || $t('rules.max0n')
                    ]"
                    min="-255"
                    max="0"
                    suffix="dBm"
                    :hint="$t('discriptions.tx_power')"
                    persistent-hint
                    type="number"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>LOCAL NAME
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.local_name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_\s]+$/.test(v) || $t('rules.unusable'),
                      v => v && v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>MODULE ID
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.module_id"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-zA-Z0-9-:]+$/.test(v) || $t('rules.unusable'),
                      v => v && v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-4" v-if="user.role === 'Administrator'">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>MODEL
                  </strong>
                </v-flex>
                <v-flex xs3>
                  <v-btn-toggle mandatory v-model="items.model">
                    <v-btn
                      v-for="(item, i) in model"
                      :key="i"
                      :value="item.value"
                      :disabled="mode"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.text }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-4" v-if="user.role === 'Administrator'">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>MANUFACTURER
                  </strong>
                </v-flex>
                <v-flex xs3>
                  <v-btn-toggle mandatory v-model="items.manufacturer">
                    <v-btn
                      v-for="(item, i) in manufacturer"
                      :key="i"
                      :value="item.value"
                      :disabled="mode"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.text }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
              <v-layout
                row　v-if="user.role === 'Administrator' || (user.client_id === items.client_id)"
              >
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>NOTE</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.note"
                    :rules="[
                      v => !v || v.length <= 250 || $t('rules.max250c')
                    ]"
                    counter="250"
                    multi-line
                    no-resize
                    :disabled="mode"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" :disabled="mode"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.created') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">{{ items.created | dateFormat }}</v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text">
                  <strong>{{ $t('items.updated') }}</strong>
                </v-flex>
                <v-flex xs6 class="grey--text">{{ items.updated | dateFormat }}</v-flex>
              </v-layout>
              <v-layout row wrap v-if="user.role === 'Administrator' || user.client_id === items.client_id">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.shared') }}</strong>
                </v-flex>
                <v-flex xs10>
                  <v-btn-toggle multiple v-model="items.shared_id" class="fold-back" style="flex-wrap: wrap">
                    <v-btn
                      v-for="(item, i) in owners"
                      :key="i"
                      :value="item.id"
                      :disabled="mode"
                      :style="user.role !== 'Administrator' ? ((typeof items !== 'undefined' && typeof item.id !== 'undefined' && typeof items.shared_id !== 'undefined') && ( items.shared_id.indexOf(item.id) != -1) ? '' : 'display: none' ) : ''"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.name }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
                <v-flex xs2 class="text-xs-right grey--text pt-3" v-if="user.role === 'Administrator'">
                  <strong>{{ $t('items.apps') }}</strong>
                </v-flex>
                <v-flex xs10 v-if="user.role === 'Administrator'">
                  <v-btn-toggle multiple class="fold-back" style="flex-wrap: wrap">
                    <v-btn
                        v-for="item in items.shared_apps"
                        :key="item.id"
                        :value="item.id"
                        disabled
                        color="primary"
                        class="white--text"
                    >
                      <span>{{ item.name }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-else-if="items.shared_apps && user.client_id !== items.client_id">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.apps') }}</strong>
                </v-flex>
                <v-flex xs10>
                  <v-btn-toggle multiple class="fold-back" style="flex-wrap: wrap">
                    <v-btn
                      v-for="item in items.shared_apps"
                      :key="item.id"
                      :value="item.id"
                      disabled
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.name }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <delete-confirmation
              v-on:onDelete="onDelete"
              :loading="loading"
              v-if="user.role === 'Administrator' && !mode"
            ></delete-confirmation>
            <save-confirmation v-on:onSave="onSave" :valid="valid && items.manufacturer && items.model" :loading="loading" v-if="!mode"></save-confirmation>
            <warning-dialog 
              :warning="warning"
              :message="message"
              @okAction="closeWarning">
            </warning-dialog>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import TimeFilter from "../../utils/filters/TimeFilter";
import ModeSelection from "../../components/ModeSelection";
import DeleteConfirmation from "../../components/dialogs/DeleteConfirmation";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";
import WarningDialog from "../../components/dialogs/WarningDialog.vue";

export default {
  data() {
    return {
      mode: true,
      valid: false,
      loading: false,
      warning: false,
      owners: [],
      model: [
        { value: "Standard", text: "standard" },
        { value: "Protect", text: "protect" },
        { value: "Card", text: "card" },
        { value: "Usb", text: "usb" },
        { value: "heavyduty", text: "Heavy Duty" },
        { value: "Others", text: "others" }
      ],
      manufacturer: [
        { value: "Kontakt.io", text: "kontakt.io" },
        { value: "Novars", text: "novars" },
        { value: "Others", text: "others" }
      ],
      items: {
        shared_id: []
      },
      apps: [],
      beaconApps: [],
      message: ""
    };
  },
  components: {
    ModeSelection,
    DeleteConfirmation,
    SaveConfirmation,
    WarningDialog
  },
  computed: {
    ...mapState(["user"]),
    isAppDisabled () {
      return this.items.mounted == null || this.items.mounted === true;
    },
    appBound () {
      return !!this.items.app_id && this.items.mounted === false;
    },
    clusterBound () {
      return !!this.items.cluster_beacon_id && this.items.cluster_beacon_id.length != 0 && this.items.mounted === true;
    }
  },
  mounted() {
    this.onFetch();
    this.onFetchOwners();
    this.onFetchApps();
  },
  watch: {
    'items.client_id' : {
      handler (v1,v2) {
        if (v2) {
          this.items.app_id = "";
        }
        this.onFetchBeaconApps();
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions(["onNotify"]),
    onFetch() {
      axios.get(this.$route.path).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.payload;
        } else {
        }
      });
    },
    onFetchOwners() {
      axios
        .get("/clients", {
          params: {
            per_page: 100,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.owners = response.data.payload.hits;
          } else {
          }
        });
    },
    onFetchApps() {
      axios
        .get("/apps", {
          params: {
            client_id: this.$route.params.id,
            per_page: 1000,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
              const apps = response.data.payload.hits;
              this.apps = apps.map(app => app.id);
          } else {
          }
        });
    },
    onFetchBeaconApps() {
      if (this.items.client_id) {
        axios
          .get("/apps", {
            params: {
              client_id: this.items.client_id,
              per_page: 1000,
              page: 1
            }
          })
          .then(response => {
            if (response.data.code === 200) {
                const apps = response.data.payload.hits;
                this.apps = apps;
            } else {
            }
          });
      }
    },
    closeWarning() {
      this.warning = false;
    },
    onClickMounted() {
      if(!this.mode && this.appBound) {
        this.message = this.$t('rules.app_bound');
        this.warning = true;
        return;
      };
      if(!this.mode && this.clusterBound) {
        this.message = this.$t('rules.cluster_bound');
        this.warning = true;
        return;
      }
    },
    onSwitchMode(val) {
      this.mode = val;
    },
    onDelete() {
      this.loading = true;
      axios.delete(this.$route.path).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "error",
              text: "Deleting Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    },
    handleSave() {
      this.loading = true;
      axios.put(this.$route.path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: this.$t('common.saved')
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.loading = false;
            let messages = response.data.payload;
            let text = "";
            Object.keys(messages).forEach(key => {
              text += `${messages[key]}<br>`;
            });
            this.onNotify({
              type: "error",
              text: text,
              multiLine: true
            });
          }, 1000);
        }
      });
    },
    onSave() {
      if(this.items.shared_id && this.items.shared_id.length > 0) {
        if (!this.items.shared_id.includes(this.items.client_id)) {
          this.handleSave()
        } else {
          this.onNotify({
            type: "error",
            text: "オーナーと同じクライアントは登録できません"
          })
        }
      } else {
         this.handleSave()
      }
    }
  },
  mixins: [TimeFilter]
};
</script>

<style>
</style>
