<template>
    <div v-if="item">
        <v-layout row wrap py-2>
            <v-flex xs12>
                <v-card>
                    <v-card-title>
                        <v-icon color="secondary">next_week</v-icon>
                        <span>&ensp;</span>
                        <h3>{{ $t('menu.Application detail') }}</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-flex>

                            <card-location v-for="(location, index) in item.location"
                                           :key="location.id"
                                           :item="location"
                                           :index="index"
                                           :application="item"
                                           :mode="ownMode"
                                           :type="locationType"
                                           :examination="item.examination"

                            >
                            </card-location>
                        </v-flex>
                        <v-flex>
                            <label>利用用途</label>
                            <v-flex xs9>
                                <v-checkbox :disabled="ownMode" v-model="item.has_notifications" label="通知に使用したい"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_stamp_free" label="スタンプラリーに使用したい"></v-checkbox>
                                <v-checkbox :disabled="ownMode" v-model="item.has_campaign" label="キャンペーンに使用したい"></v-checkbox>
                            </v-flex>
                        </v-flex>
                        <v-flex>
                            <v-textarea
                                :label="$t('apply.other')"
                                :disabled="ownMode"
                                v-model="item.remarks"
                                :error-messages="errors['remarks']"
                            ></v-textarea>
                        </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="item.created_at.substring(0, 10)"
                          :label="$t('apply.created_at')"
                          :disabled="ownMode"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="item.appli.name"
                          :label="$t('apply.application_name')"
                          :disabled="ownMode"
                        ></v-text-field>
                      </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="item.desired_start_date"
                            :label="$t('apply.desired_start_date')"
                            :disabled="ownMode"
                            :error-messages="errors['desired_start_date']"
                          ></v-text-field>
                        </v-flex>
                        <v-flex style="width: 100px;">
                            <v-text-field
                                :label="$t('apply.usage_period')"
                                :disabled="ownMode"
                                v-model="item.usage_duration"
                                :error-messages="errors['usage_duration']"
                                suffix="ヶ月間"
                            ></v-text-field>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>

                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import CardLocation from "../cards/CardLocation";

    export default {
        props: ['itemModel', 'mode', 'locationType'],
        mounted() {
        },
        components: {
            CardLocation
        },
        watch: {
            itemModel: function (newVal, oldVal) {



                this.item = newVal;
            }
        },
        data() {
            return {
                item: this.itemModel,
                applis: [],
                ownMode: this.mode,
                dialog: false,
                finalizeDialog: false,
                agreeTerms: false,
                thanksDialog: false,
                errors: [],
                menu: false,
                confirm: false
            };
        },
        methods: {

        }
    };
</script>

<style>
</style>
