<template>
  <v-container fluid>
    <div class="text-xs-left text--secondary headline text-uppercase font-weight-medium mb-3">{{
      $t('menu.Announcements')}}
    </div>
    <v-card>
      <v-card-text>
        <v-list two-line>
          <template v-for="(item, index) in mainItems">
            <v-list-tile
              :key="item.id"
              :prepend-icon="item.action"
              avatar
              ripple
              @click="toggleShow(item.id)"
            >
              <v-list-tile-action>
                <svg v-if="item.read" height="10" width="10">
                  <circle cx="5" cy="5" r="5" fill="#00897b"></circle>
                </svg>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-if="item.type === 'app'" class="limit">
                  {{`${item.client_name} ${$t('common.created_app_1')} ${item.app_name}
                  ${$t('common.created_app_2')}` }}
                </v-list-tile-title>
                <v-list-tile-title v-else-if="item.type === 'log'" class="limit">
                  {{`${item.app_name} ${$t('common.log_title')}` }}
                </v-list-tile-title>
                <v-list-tile-title v-else class="limit">
                  {{item.title }}
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action class="prevent-wrapping">
                <v-list-tile-action-text>{{ item.created }}</v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              inset
              v-if="index + 1 < mainItems.length"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <v-btn outline primary color="primary" class="my-4 mx-0" @click="dialog_past = true">{{
      $t('common.past_announcements')}}
    </v-btn>
    <announcements-show-dialog v-if="selected" :visible="dialog_show" :selected="selected"
                               @toggle="dialog_show = false"></announcements-show-dialog>
    <announcements-past-dialog :visible="dialog_past" :announcements="items" @toggle="toggleShow"
                               @toggleBack="dialog_past = false"></announcements-past-dialog>
    <div class="mt-5" v-if=" user.role !== 'Administrator'">
      <v-layout row wrap>
        <v-flex xs3 style="min-width: 185px">
          <div class="text--secondary headline text-uppercase font-weight-medium ">{{
              $t('menu.Logs')}}
          </div>
        </v-flex>
        <v-flex xs9 style="display: flex; justify-content: end">
          <v-icon color="secondary" class="arrow">arrow_left</v-icon>
          <vue-monthly-picker
              v-model="selectedMonth" alignment="center" :clearOption=false
              selectedBackgroundColor="#00897B"
              inputClass="v-input" placeHolder="Choose a month"　style="align-self: center; font-size: 14px"
          >
          </vue-monthly-picker>
          <v-icon color="secondary" class="arrow">arrow_right</v-icon>
        </v-flex>
      </v-layout>

      <graph :selectedMonth="selectedMonth"></graph>

      <v-layout class="mt-5" row wrap>
        <v-flex xs3 style="min-width: 185px">
          <div class="text--secondary headline text-uppercase font-weight-medium ">{{
              $t('menu.Unique_Logs')}}
          </div>
        </v-flex>
        <v-flex xs9 style="display: flex; justify-content: end">
          <v-icon color="secondary" class="arrow">arrow_left</v-icon>
          <vue-monthly-picker
              v-model="selectedUniqueMonth" alignment="center" :clearOption=false
              selectedBackgroundColor="#00897B"
              inputClass="v-input" placeHolder="Choose a month" style="align-self: center; font-size: 14px"
          >
          </vue-monthly-picker>
          <v-icon color="secondary" class="arrow">arrow_right</v-icon>
        </v-flex>
      </v-layout>

      <unique-device-graph :selectedMonth="selectedUniqueMonth"></unique-device-graph>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import AnnouncementsShowDialog from "./AnnouncementsShowDialog";
import AnnouncementsPastDialog from "./AnnouncementsPastDialog";
import Graph from "../../components/graph/Graph";
import UniqueDeviceGraph from "../../components/graph/UniqueDeviceGraph";
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from "moment";

export default {
  components: {
    AnnouncementsShowDialog,
    AnnouncementsPastDialog,
    Graph,
    VueMonthlyPicker,
    UniqueDeviceGraph
  },
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      owners: [],
      mainItems: [],
      items: [],
      selected: null,
      dialog_show: false,
      dialog_create: false,
      dialog_past: false,
      selectedMonth: moment(),
      selectedUniqueMonth: moment()
    };
  },
  mounted() {
    this.onFetch();
  },
  watch: {
    user(newValue) {
      if (newValue.hasOwnProperty("_id")) {
        this.onFetch();
      }
    }
  },
  methods: {
    toggleShow(id) {
      // close the past dialog first. Otherwise the total number of unread will switch to total of message and the switch is visible.
      this.dialog_past = false;
      return axios
        .put(`/notifications/${id}`, { user_id: this.user._id })
        .then(response => {
          if (response.data.code === 200) {
            this.selected = this.items.find(item => item.id === id);
            this.selected.read = false;
            this.dialog_show = true;
            // update items to allow announcements-past-dialog to watch the changes and update
            this.items = [...this.items];
          }
        });
    },

    async onFetch() {
      await this.onFetchOwners();
      await this.onFetchAnnouncements();
      await this.onFetchRead();
    },

    mapLogNotification(item) {
      const created = new Date(item._source.created * 1000);
      const base = {
        type: item._source.type,
        app_name: item._source.app_name,
        plan: item._source.plan,
        created: new Date(item._source.created * 1000).toLocaleString(),
        target_date: `${created.getFullYear()}/${
          created.getMonth() < 10 ? "0" : ""
        }${created.getMonth() + 1}`,
        maximum_logs_per_month: item._source.maximum_logs_per_month,
        number_of_logs: item._source.number_of_logs,
        id: item._id
      };

      // The administrator needs the client name whereas user does not need it
      return this.user.role === "Administrator"
        ? {
            ...base,
            clients: item._source.clients.map(item =>
              this.owners.find(owner => owner.id === item)
            )
          }
        : { ...base };
    },

    mapAppNotification(item) {
      return {
        type: item._source.type,
        client_name: item._source.client_name,
        app_name: item._source.title,
        plan: item._source.plan,
        clients: item._source.clients.map(item =>
          this.owners.find(owner => owner.id === item)
        ),
        created: new Date(item._source.created * 1000).toLocaleString(),
        id: item._id
      };
    },

    mapNotification(item) {
      return {
        title: item._source.title,
        body: item._source.body,
        created: new Date(item._source.created * 1000).toLocaleString(),
        id: item._id
      };
    },

    formatResponse(response) {
      return response.data.payload.hits.map(item => {
        const type = item._source.type;
        switch (type) {
          case "app":
            return this.mapAppNotification(item);
          case "log":
            return this.mapLogNotification(item);
          default:
            return this.mapNotification(item);
        }
      });
    },

    onFetchAnnouncements() {
      return axios.get("/notifications?per_page=1000&page=1").then(response => {
        if (response.data.code === 200) {
          this.items = [];
          const formatResponse = this.formatResponse(response);
          const items = [...this.items, ...formatResponse];
          this.items = [...items];
          this.mainItems = [...items].slice(0, 10);
        } else {
        }
      });
    },

    onFetchRead() {
      return axios
        .post(`/notifications/read?user_id=${this.user.id}&per_page=1000&page=1`)
        .then(response => {
          const read = response.data.payload.hits.map(item => item._id);
          this.items = this.items.map(item => {
            item.read = !read.includes(item.id);
            return item;
          });
        });
    },

    onFetchOwners() {
      return axios.get("/clients?per_page=100&page=1").then(response => {
        if (response.data.code === 200) {
          this.owners = response.data.payload.hits.map(({ name, id }) => ({
            name,
            id
          }));
        } else {
        }
      });
    }
  }
};
</script>

<style scoped>
.prevent-wrapping {
  min-width: 150px;
}

.limit {
  max-width: 60ch;
}

.current_month {
  font-size: 16px;
  font-weight: 300;
  text-align: right;
}

.arrow {
  font-size: 30px;
}
</style>
