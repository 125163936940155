<template>
    <v-card :to="type === 'examination' ? `${$route.path}/${item.company_id}/${item.id}` : `${$route.path}/${item.id}`" class="list-card">
        <v-container fluid>
            <v-layout justify-space-between row>
                <v-flex xs4>
                    <div>ロケーションオーナー: {{item.name}}</div>
                </v-flex>
                <v-flex xs4>
                  <div>ロケーション数: {{item.location.reduce((t,x) => t+(x.application ? 1 : 0), 0)}}</div>
                  <div>ロケーションレンタル受領金額: {{item.location.reduce((t,x) => t+x.usage_fee*(x.application ? x.application.length : 0), 0) | toCurrency}}</div>
                </v-flex>
              <v-flex xs4>
                  <div>ロイヤリティ: {{item.location.reduce((t,x) => t+x.usage_fee*x.royalty*(x.application ? x.application.length : 0), 0) | toCurrency}}</div>
                </v-flex>

            </v-layout>
        </v-container>
    </v-card>
</template>
<script>
    import {LOCATION_APPLICATION_STATUS} from "../../utils/masterdata";

    export default {
        name: 'application-card',
        props: ["item"],
        data() {
            return {
            }
        }
    }
</script>
