<template>
  <div>
    <v-layout row wrap>
      <v-flex xs10>
        <v-toolbar color="white" class="elevation-1">
          <v-toolbar-title>
            <span class="caption">{{ $t('common.total') }}</span>
            {{ total | comma }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <search-operations v-on:onFetch="onFetch"></search-operations>
          <v-btn outline color="success" :to="`${$route.path}/new`">{{ $t('common.create') }}</v-btn>
        </v-toolbar>
      </v-flex>
      <v-flex xs2>
        <v-toolbar color="white" class="elevation-1">
          <v-tooltip bottom class="full-activator">
            <v-select
                :items="[10,30,50,100]"
                v-model="options.rowsPerPage"
                slot="activator"
                hide-details
                style="padding: 0 16px"
            ></v-select>
            <span>{{ $t('common.rows_per_page') }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="options"
            :total-items="total"
            :loading="loading"
            class="elevation-1"
            :must-sort="true"
            :custom-sort="customSort"
            :rows-per-page-items="[10,30,50,100]"
        >
          <template v-slot:items="props">
            <td @click="handleClick(props.item)">{{ props.item.name }}</td>
            <td class="text-xs" @click="handleClick(props.item)">
              <div class="list-card-thumb">
                <img :src="props.item.image" alt v-if="props.item.image" />
                <img src="../../assets/noimage.png" alt v-else />
              </div>
            </td>
            <td class="text-xs" @click="handleClick(props.item)" :title="props.item.id">{{ props.item.id.substr(0, 8) }}...</td>
            <td class="text-xs" @click="handleClick(props.item)">
              <v-icon v-if="props.item.enabled" color="green">circle</v-icon>
              <v-icon v-if="!props.item.enabled" color="red">cancel</v-icon>
            </td>

            <td class="text-xs">

              <form :action="formUrl" method="post" ref="form">
                <input v-if="props.item.user" type="hidden" name="id" :value="props.item.user.id"/>
                <input type="hidden" name="company_name" :value="props.item.name"/>
                <v-btn
                    @click.stop
                    outline
                    color="primary"
                    type="submit"
                >{{ $t('common.dashboard') }}</v-btn>
              </form>

            </td>
          </template>

        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>




<script>
import axios from "axios";
import NumericFilter from "../../utils/filters/NumericFilter";
import ColorFilter from "../../utils/filters/ColorFilter";
import TimeFilter from "../../utils/filters/TimeFilter";
import SearchOperations from "../../components/search/ActionsSearchOperations";
import config from "../../config/index";


export default {
  data() {
    return {
      owners: [],
      total: 1,
      page: 1,
      pages: 1,
      apps: [],
      items: [],
      loading: true,
      options: {
        page: 1,
        rowsPerPage: localStorage.getItem("rowsPerPage") ? parseInt(localStorage.getItem("rowsPerPage")) : 30
      },
      init: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Image', value: 'image' },
        { text: 'ID', value: 'loco_id' },
        { text: 'ステータス', value: 'enabled' },
        { text: 'Dashboard', sortable: false },
      ],
      formUrl: `${config}/admin/generateUserToken`
    };
  },
  components: {
    SearchOperations
  },
  watch: {
    options: {
      handler (v1,v2) {
        this.onFetch();
      },
      deep: true,
    }
  },
  methods: {
    customSort(items, index, desc) {
      return items
    },
    handleClick(value) {
      this.$router.push(`${this.$route.path}/${value.id}`)
    },
    onFetch() {
      let search = this.$store.getters.search;
      const { sortBy, descending, page, rowsPerPage } = this.options
      this.loading = true;
      axios
          .get(this.$route.path, {
            params: Object.assign(
                {
                  per_page: rowsPerPage,
                  page: page,
                  desc: descending,
                  sort: sortBy
                },
                search
            )
          })
          .then(response => {
            if (response.data.code === 200) {
              if (this.init) {
                let page = parseInt(localStorage.getItem("pagination"))
                if (page !== 1 && !isNaN(page)) this.options.page = page;
                else this.init = false
              }
              if (!this.init) {
                this.items = response.data.payload.hits;
                this.total = response.data.payload.total
                this.loading = false;

                localStorage.setItem("pagination", this.options.page);
                localStorage.setItem("rowsPerPage", this.options.rowsPerPage);
              }
              this.init = false;
            }
          });
    }
  },
  mixins: [NumericFilter, ColorFilter, TimeFilter]
};
</script>

<style scoped>
.list-card-thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 128px;
  overflow: hidden;
  margin: 4px 0;
}
.list-card-thumb img {
  width: auto;
  height: 80px;
}
</style>
