<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">whatshot</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.action') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    autofocus
                    :rules="[
                      v => !!v || $t('rules.required'),
                      // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>URI</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.uri"
                    :rules="[
                      v => !v || /^[a-zA-Z0-9:/?#\[\]@!$&'()*+,;=.\-_]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 250 || $t('rules.max250c')
                    ]"
                    counter="250"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.action_interval') }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="items.interval"
                    :rules="[
                      v => !!v || v === 0 || $t('rules.required'),
                      v => v >= 0 || $t('rules.min0n'),
                      v => v <= 99999999 || $t('rules.max99999999n')
                    ]"
                    min="0"
                    max="99999999"
                    :suffix="$t('suffix.second')"
                    :hint="$t('discriptions.action_interval')"
                    persistent-hint
                    type="number"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.parameters') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-layout row v-for="(item, i) in params" :key="i">
                    <v-flex xs4>
                      <v-text-field
                        v-model="item.key"
                        :rules="[
                          v => !!v || $t('rules.required'),
                          // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                          v => v.length <= 45 || $t('rules.max45c')
                        ]"
                        counter="45"
                        class="pt-0"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs8>
                      <v-text-field
                        v-model="item.value"
                        :rules="[
                          v => !!v || $t('rules.required'),
                          // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                          v => v.length <= 45 || $t('rules.max45c')
                        ]"
                        counter="45"
                        class="pt-0"
                      ></v-text-field>
                    </v-flex>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click.stop="onRemoveBox(i)"
                    >{{ $t('common.delete') }}</v-btn>
                  </v-layout>
                  <v-layout class="add-box">
                    <div class="inner">
                      <v-btn outline small color="primary" @click.stop="onAddBox" :disabled="params.length >= 99">{{ $t('common.add') }}</v-btn>
                    </div>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid"
              :loading="loading"
            ></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapActions } from "vuex";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      params: [],
      items: {
        id: uuid(),
        client_id: "",
        name: "",
        uri: "",
        interval: 0,
        params: [],
        enabled: true
      }
    };
  },
  components: {
    SaveConfirmation
  },
  watch: {
    params() {
      this.items.params = this.params;
    }
  },
  methods: {
    ...mapActions(["onNotify"]),
    onRemoveBox(i) {
      this.params.splice(i, 1);
    },
    onAddBox() {
      this.params.push({
        key: "",
        value: ""
      });
    },
    onSave() {
      this.loading = true;
      let path = this.$route.path.replace(/\/new/g, "");
      axios.post(path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: "Creating Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style>
</style>
