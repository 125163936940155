<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">smartphone</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.device') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.loco_id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3" v-if="user.role === 'Administrator'">
                  <strong>{{ $t('items.owner') }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-select
                    :items="owners"
                    item-text="name"
                    item-value="id"
                    v-model="items.owner_id"
                    class="pt-0"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t("items.app") }}</strong>
                </v-flex>
                <v-flex xs3>
                  <v-autocomplete
                    :items="apps"
                    item-text="name"
                    item-value="id"
                    v-model="items.app_id"
                    :disabled="isAppDisabled"
                    clearable
                    class="pt-0"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>デバイスID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.device_id"
                    placeholder="########-####-####-####-############"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[0-5][a-fA-F0-9]{3}-[089aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/.test(v) || $t('rules.format')
                    ]"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.is_mounted') }}</strong>
                </v-flex>
                <v-flex xs6 @click="onClickMounted">
                  <v-radio-group row v-model="items.mounted" :disabled="appBound">
                    <v-radio :label="$t('items.mounted')" :value="true"></v-radio>
                    <v-radio :label="$t('items.movable')" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.manufacturer_id') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.manufacturer_id"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-zA-Z0-9-:]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-4">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>MANUFACTURER</strong>
                </v-flex>
                <v-flex xs3>
                  <v-btn-toggle mandatory v-model="items.manufacturer">
                    <v-btn
                      v-for="(item, i) in manufacturer"
                      :key="i"
                      :value="item.value"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.text }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>NOTE</strong>
                </v-flex>
                <v-flex xs6>
                  <v-textarea
                    v-model="items.note"
                    :rules="[
                      v => v.length <= 250 || $t('rules.max250c')
                    ]"
                    counter="250"
                    no-resize
                    class="pt-0"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.shared') }}</strong>
                </v-flex>
                <v-flex xs10>
                  <v-btn-toggle multiple v-model="items.shared_id" class="fold-back" style="flex-wrap: wrap">
                    <v-btn
                            v-for="(item, i) in owners"
                            :key="i"
                            :value="item.id"
                            color="primary"
                            class="white--text"
                    >
                      <span>{{ item.name }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid && items.manufacturer"
              :loading="loading"
            ></save-confirmation>
            <warning-dialog 
              :warning="warning"
              :message="message"
              @okAction="closeWarning">
            </warning-dialog>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapState, mapActions } from "vuex";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation.vue";
import WarningDialog from "../../components/dialogs/WarningDialog.vue";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      warning: false,
      owners: [],
      apps: [],
      manufacturer: [
        { value: "OBNIZ", text: "obniz" },
        { value: "IOS", text: "ios" },
        { value: "ANDROID", text: "android" }
      ],
      items: {
        loco_id: uuid(),
        owner_id: "",
        name: "",
        device_id: uuid(),
        mounted: true,
        manufacturer_id: "",
        manufacturer: "",
        note: "",
        enabled: true,
        shared: false,
        shared_id: [],
        app_id: ""
      },
      message: ""
    };
  },
  components: {
    SaveConfirmation,
    WarningDialog
  },
  computed: {
    ...mapState(["user"]),
    appBound () {
      return !!this.items.app_id && this.items.mounted === false; 
    },
    isAppDisabled () {
      return this.items.mounted == null || this.items.mounted === true;
    },
  },
  mounted() {
    this.onFetchOwners();
  },
  watch: {
    'items.owner_id' : {
      handler (v1,v2) {
        this.items.app_id = "";
        this.onFetchApps();
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions(["onNotify"]),
    closeWarning () {
      this.warning = false;
    },
    onClickMounted() {
      if(this.appBound) {
        this.message = this.$t('rules.app_bound');
        this.warning = true;
      };
    },
    onFetchOwners() {
      axios
        .get("/clients", {
          params: {
            per_page: 100,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.owners = response.data.payload.hits;
          } else {
          }
        });
    },
    onFetchApps() {
      axios
        .get("/apps", {
          params: {
            client_id: this.items.owner_id,
            per_page: 1000,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
              const apps = response.data.payload.hits;
              this.apps = apps;
          } else {
          }
        });
    },
    onSave() {
      const vue = this;
      if(!this.items.shared_id.includes(this.items.owner_id)) {
        this.loading = true;
        let path = this.$route.path.replace(/\/new/g, "");
        const { shared, ...payload } = this.items
        axios.post(path, payload).then(response => {
          if (response.data.code === 200) {
            setTimeout(() => {
              this.loading = false;
              this.$router.go(-1);
              this.onNotify({
                type: "success",
                text: "Created Successfully!"
              });
            }, 1000);
          } else {
            setTimeout(() => {
              this.loading = false;
              let messages = response.data.payload;
              let text = "";
              Object.keys(messages).forEach(key => {
                text += `${messages[key]}<br>`;
              });
              this.onNotify({
                type: "error",
                text: text,
                multiLine: true
              });
            }, 1000);
          }
        }).catch(error => {
          setTimeout(() => {
            this.loading = false;
            let messages = error.response.data.payload.errors;
            let text = "";
            Object.keys(messages).forEach(key => {
              text += `${vue.$t('rules_unique.' + key)}<br>`;
            });
            this.onNotify({
              type: "error",
              text: text,
              multiLine: true
            });
          }, 1000);
        });
      } else {
        this.onNotify({
          type: "error",
          text: "オーナーと同じクライアントは登録できません"
        })
      }
    }
  }
};
</script>

<style>
</style>
