<template>
  <v-card class="auth-card">
    <v-layout row v-if="!google2fa">
      <v-flex xs12>
        <h1>Login</h1>
      </v-flex>
    </v-layout>
    <v-layout row v-if="google2fa">
      <v-flex xs12>
        <h1>Google Authentication</h1>
      </v-flex>
    </v-layout>
    <v-layout row v-if="!google2fa">
      <v-flex xs12>
        <v-form v-model="valid">
          <v-text-field
            prepend-icon="mail"
            v-model="email"
            :rules="[
              v => !!v || 'This field is required'
            ]"
          ></v-text-field>
          <v-text-field
            prepend-icon="lock"
            v-model="password"
            :rules="[
              v => !!v || 'This field is required'
            ]"
            :append-icon="view ? 'visibility' : 'visibility_off'"
            @click:append="() => (view = !view)"
            :type="view ? 'password' : 'text'"
          ></v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout row v-if="google2fa">
      <v-flex xs12>
        <v-form v-model="valid" @submit.prevent="onLogin">
          <v-text-field
            v-model="totp"
            :rules="[
              v => !!v || 'This field is required',
              v => v && v.length == 6 || 'This field is must be a 6 digit number'
            ]"
            type="number"
          ></v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-btn block outline color="primary" @click.stop="onLogin" :disabled="!valid">{{ !google2fa ? 'login' : 'verify' }}</v-btn>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                google2fa: false,
                valid: false,
                view: true,
                email: "",
                password: "",
                totp: ""
            };
        },
        mounted() {
            const token = this.getCookie('token');
            if (token !== undefined && localStorage.getItem('attemptedToken') !== token) {
                localStorage.setItem('token', token);
                this.$store.dispatch("onLogin");
            }
        },
        methods: {
            onLogin() {
              this.google2fa = false;
                axios
                    .post("/login", {
                        email: this.email,
                        password: this.password,
                        totp: this.totp
                    })
                    .then(response => {
                        if (response.data.code === 200) {
                            if (response.data.payload.google2fa) {
                                this.google2fa = true;
                            } else {
                                this.$store.dispatch("setUser", response.data.payload.user);
                                this.$store.dispatch("onNotify", {
                                    type: "success",
                                    text: "Login Successfully!"
                                });
                                this.$store.dispatch("onLogin");
                            }
                        } else {
                            this.$store.dispatch("onNotify", {
                                type: "error",
                                text: response.data.message
                            });
                            this.totp = "";
                        }
                    });
            },
            getCookie(name) {
                var value = "; " + document.cookie;
                var parts = value.split("; " + name + "=");
                if (parts.length == 2) return parts.pop().split(";").shift();
            }
        }
    };
</script>

<style>
  .auth-card {
    padding: 25px;
    max-width: 360px;
    width: 100%;
  }

  .auth-card h1 {
    color: #00897b;
    font-style: oblique;
  }
</style>
