<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <v-icon color="secondary">public</v-icon>
                  <strong>{{ $t('subtitle.client') }}</strong>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="items.id" disabled class="pt-0"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.name') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-z0-9-]+$/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.loco_share') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.loco_share"></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <v-icon color="secondary">person</v-icon>
                  <strong>{{ $t('subtitle.manager') }}</strong>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="items.user.id" disabled class="pt-0"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.name') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.user.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.email') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.user.email"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || $t('rules.format')
                    ]"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>
                    <span class="red--text">*</span>
                    {{ $t('items.password') }}
                  </strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.user.password"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[a-zA-Z0-9/\*-.,\!#\$%&\(\)~\|_\+]+$/.test(v) || $t('rules.unusable'),
                      v => v.length >= 8 || $t('rules.min8c')
                    ]"
                    :append-icon="visibility ? 'visibility' : 'visibility_off'"
                    :append-icon-cb="() => (visibility = !visibility)"
                    :type="visibility ? 'password' : 'text'"
                    :hint="$t('discriptions.password')"
                    persistent-hint
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.user.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <save-confirmation v-on:onSave="onSave" :valid="valid" :loading="loading"></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapActions } from "vuex";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";

export default {
  data() {
    return {
      valid: false,
      visibility: true,
      loading: false,
      items: {
        id: uuid(),
        name: "",
        google2fa_secret: "",
        image: "",
        enabled: true,
        loco_share: false,
        user: {
          id: uuid(),
          client_id: "",
          name: "",
          email: "",
          password: "",
          role: "Manager",
          image: "",
          enabled: true
        }
      }
    };
  },
  components: {
    SaveConfirmation
  },
  methods: {
    ...mapActions(["onNotify"]),
    onSave() {
      this.loading = true;
      let path = this.$route.path.replace(/\/new/g, "");
      this.items.user.client_id = this.items.id;
      axios.post(path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: "Creating Successfully!"
            });
          }, 1000);
        } else if (response.data.code === 409) {
          setTimeout(() => {
            this.loading = false;
            this.onNotify({
              type: "error",
              text: response.data.message,
              multiLine: false
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.loading = false;
            let messages = response.data.payload;
            let text = "";
            Object.keys(messages).forEach(key => {
              text += `${messages[key]}<br>`;
            });
            this.onNotify({
              type: "error",
              text: text,
              multiLine: true
            });
          }, 1000);
        }
      });
    }
  }
};
</script>

<style>
</style>
