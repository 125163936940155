<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card>
      <v-card-title>
        <v-icon color="secondary">security</v-icon>
        <span>&ensp;</span>
        <span class="grey--text">
          <strong>{{ $t('comfirm.enable_2fa.title') }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout row v-if="!google2fa">
          <v-flex xs12>
            <p class="grey--text">{{ $t('comfirm.enable_2fa.text') }}</p>
          </v-flex>
        </v-layout>
        <v-layout row v-if="google2fa">
          <v-flex xs6>
            <div class="security-box" ref="qrcodeContainer">
              <VueQRCodeComponent v-if="items.google2fa_url" :text="items.google2fa_url" :size="parseInt('190')" />
              <a @click="downloadQr(items.secret)">{{ $t('common.download') }}</a>
            </div>
          </v-flex>
          <v-flex xs6>
            <p class="grey--text">
              <br />
              {{ $t('comfirm.enable_2fa.sub_text01') }}
              <br /><br /><br />
              {{ $t('comfirm.enable_2fa.sub_text02') }} <code>{{ items.secret }}</code>
            </p>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="secondary" @click.stop="onClose">{{ $t('common.close') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat color="success" :loading="loading" @click.stop="onEnable" :disabled="google2fa">{{ $t('common.enable') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  props: ["google2fa", "dialog"],
  components: {
    VueQRCodeComponent
  },
  data() {
    return {
      loading: false,
      items: {}
    };
  },
  methods: {
    ...mapActions(["onNotify"]),
    onEnable() {
      this.loading = true;
      axios.get("/2fa/enable").then(response => {
        if (response.data.code === 200) {
          this.loading = false;
          this.items = response.data.payload;
          this.onSwitch();
          this.onNotify({
            type: "success",
            text: "Enabling Successfully!"
          });
        } else {
          this.loading = false;
        }
      });
    },
    onSwitch() {
      this.$emit("onSwitchGoogle2fa", true);
    },
    onClose() {
      this.$emit("onCloseDialog", "enable");
    },
    downloadQr (secret) {
      const canvas = this.$refs.qrcodeContainer.querySelector("canvas");
      const link = document.createElement("a");
      link.download = secret + ".png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    },
  }
};
</script>

<style>
.security-box {
  text-align: center;
}
</style>
