<template>
  <div>
    <router-view></router-view>
    <v-snackbar
      :timeout="notice.timeout"
      :color="notice.color"
      :top="notice.top"
      :bottom="notice.bottom"
      :right="notice.right"
      :left="notice.left"
      :multi-line="notice.multiLine"
      :vertical="notice.vertical"
      v-model="notice.active"
    >
      <span v-html="notice.text"></span>
      <v-btn flat dark @click.stop="notice.active = false">{{ $t('common.close') }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["notice"])
  }
};
</script>

<style>
</style>
