<template>
  
</template>

<script>
import uuid from "uuid";
import parse from "csv-parse/lib/sync";

export default {
  methods: {
    onPick() {
      this.$refs.csv.click();
    },
    onChange(e) {
      let files = e.target.files;
      this.onParse(files[0]);
    },
    onClose() {
      this.dialog = false;
      this.items = [];
      this.client_id = "";
      this.model = "";
      this.$refs.csv.value = "";
    },
    onParse(file) {
      const render = new FileReader();
      render.onload = e => {
        let res = parse(e.target.result, { columns: true });
        this.createTable(res);
      };
      render.readAsText(file);
    },
    createTable(arr) {
      Object.keys(arr).forEach(i => {
        this.items.push({
          id: uuid(),
          client_id: "",
          name: arr[i].name,
          uuid: arr[i].proximity,
          major: arr[i].major,
          minor: arr[i].minor,
          tx_power: this.txPower(arr[i].txPower),
          battery: 100,
          local_name: arr[i].name,
          module_id: arr[i].uniqueId,
          model: "",
          manufacturer: this.manufacturer,
          note: "",
          enabled: true,
          created: Math.ceil(new Date().getTime() / 1000),
          updated: Math.ceil(new Date().getTime() / 1000),
          deleted: false
        });
      });
    },
    txPower(lv) {
      switch (lv) {
        case "0":
          return "-115";
        case "1":
          return "-84";
        case "2":
          return "-81";
        case "3":
          return "-77";
        case "4":
          return "-72";
        case "5":
          return "-69";
        case "6":
          return "-65";
        case "7":
          return "-59";
      }
    }
  }
};
</script>

<style>
</style>
