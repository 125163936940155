<template>
  <div>
    <v-card-text v-if="item">

      <v-container grid-list-md text-xs-center>
        <v-layout row wrap align-center class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left; font-weight: 500;"
            >
              {{$t('location_detail.name')}}
            </div>
          </v-flex>
          <v-flex
            xs4
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-text-field :disabled="mode" v-model="item.name" :error-messages="errors['name']"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap align-center class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left; font-weight: 500;"
            >
              {{$t('location_detail.location')}}
            </div>
          </v-flex>
          <v-flex xs4 style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;">
            <v-select :disabled="mode" :items="prefectures" v-model="item.prefecture" v-on:change="getCities" :error-messages="errors['prefecture']"></v-select>
          </v-flex>
          <v-flex xs5 style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;">{{$t('fields.prefecture')}}</v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs4 style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;">
            <v-select :disabled="mode" :items="cities" v-model="item.city" :error-messages="errors['city']"></v-select>
          </v-flex>
          <v-flex xs5 style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;">{{$t('fields.region')}}</v-flex>

        </v-layout>
        <v-layout row wrap class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('fields.facility_type')}}
            </div>
          </v-flex>
          <v-flex xs9 class="pa-0">
            <v-layout row wrap class="ma-0" justify-evenly v-if="item.facilities">
              <v-flex xs6>
                <v-card elevation="1">
                  <v-list subheader>
                    <v-subheader>{{$t('facilities.commercial')}}</v-subheader>
                    <v-divider></v-divider>
                    <v-list-tile
                      v-for="entry in Object.entries(item.facilities.commercial)"
                      :key="entry[0]"
                      avatar
                      :inactive="mode"
                      @click="onCheck(entry[0])"
                    >
                      <v-list-tile-avatar>
                        <v-icon
                          v-if="Number(entry[1])===0"
                          class="mr-2"
                          color="green accent-4"
                        >radio_button_unchecked
                        </v-icon>
                        <v-icon class="mr-2" color="green accent-4" v-else
                        >check_circle
                        </v-icon
                        >
                      </v-list-tile-avatar>

                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t(`facilities.${entry[0]}`) }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
              <v-flex xs6>
                <v-card elevation="1">
                  <v-list subheader>
                    <v-subheader>{{$t('facilities.transport')}}</v-subheader>
                    <v-divider></v-divider>
                    <v-list-tile
                      v-for="entry in Object.entries(item.facilities.transport)"
                      :key="entry[0]"
                      avatar
                      :inactive="mode"
                      @click="onCheck(entry[0])"
                    >
                      <v-icon
                        v-if="Number(entry[1])===0"
                        class="mr-2"
                        color="green accent-4"
                      >radio_button_unchecked
                      </v-icon>
                      <v-icon class="mr-2" color="green accent-4" v-else
                      >check_circle
                      </v-icon>

                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t(`facilities.${entry[0]}`) }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('location_detail.description')}}
            </div>
          </v-flex>
          <v-flex
            xs9
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-textarea
              solo
              v-model="item.description"
              :disabled="mode"
              :error-messages="errors['description']"
            ></v-textarea>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('location_detail.pictures')}}
            </div>
          </v-flex>
          <v-flex xs9>
            <v-card-media>
              <v-layout row wrap v-for="(j, kk) in Math.min(Math.floor(item.photo.length / 4 + 1), 8)" :key="`pic`+kk">
                <v-flex v-if="item" v-for="(i, ii) in 4" xs3 :key="`pic`+ii+`.`+kk">
                  <modify-picture :photo="item.photo[(j - 1) * 4 + i -1]" :addImage="addImage" :deleteImage="deleteImage" :editable="!mode" :index="i-1"/>
                </v-flex>
              </v-layout>
            </v-card-media>
          </v-flex>
        </v-layout>

        <v-layout row wrap align-center class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('fields.usage_fee')}}
            </div>
          </v-flex>
          <v-flex
            xs9
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-text-field type="number" :disabled="mode" v-model="item.usage_fee" prefix="¥" :error-messages="errors['usage_fee']"></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout v-if="type==='borrow'" row wrap align-center class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('fields.usage_limit')}}
            </div>
          </v-flex>
          <v-flex
            xs9
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-text-field disabled v-model="item.application[0].application_location.usage_ends_at"></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout row wrap class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('fields.rules')}}
            </div>
          </v-flex>
          <v-flex
            xs9
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-textarea
              solo
              v-model="item.rules"
              :disabled="mode"
              :error-messages="errors['rules']"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mb-2">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left; font-weight: 500;"
            >
              {{$t('fields.status')}}
            </div>
          </v-flex>
          <v-flex
            xs4
            style="font-weight: 100; font-family: 'Open Sans', sans-serif; text-align: left;"
          >
            <v-select :disabled="mode" :items="location_statuses" v-model="item.is_active" :error-messages="errors['is_active']"></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mb-2 mt-5">
          <v-flex xs3>
            <div
              style="color: rgba(0,0,0,.54);text-align: left;
    font-weight: 500;"
            >
              {{$t('fields.beacons')}}
            </div>
          </v-flex>
          <v-flex xs6>
            <v-card>
              <v-list subheader>
                <div v-if="item.beacon.length > 0">
                  <template v-for="(beacon, index) in item.beacon">
                    <v-list-tile :key="index">
                      <v-list-tile-content>
                        <v-list-tile-title
                        >{{ beacon.name }}
                        </v-list-tile-title>

                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider
                      v-if="index + 1 < item.beacon.length"
                      :key="'A' + index"
                    ></v-divider>
                  </template>
                </div>
                <v-list-tile v-else>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{$t('beacons.none')}}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>

            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
    </v-card-actions>
  </div>
</template>
<script>
    import ModifyPicture from "./ModifyPicture"
    import {CITIES, LOCATION_STATUSES, PREFECTURES} from "../../utils/masterdata";
    import {getTypeObject} from "../../utils/facilities";
    import Api from "../../api/lmp";
    import config from "../../config/lmp";

    export default {
        name: 'LocationDetail',
        props: ['itemModel', 'mode', 'submitFunction', "errors", "type"],
        components: {ModifyPicture},
        data() {
            return {
                item: null,
                confirmDelete: false,
                confirmSave: false,
                prefectures: Object.values(PREFECTURES),
                cities: [],
                location_statuses: LOCATION_STATUSES.map(x => ({...x, text: this.$t('shared.status.' + x.text)}))
            };
        },
        watch: {
            itemModel: function (newVal, oldVal) {
                this.mountFunc(newVal)
            }
        },
        mounted() {
            this.mountFunc(this.itemModel)
        },
        methods: {
            mountFunc(newItem) {
                const facilities = getTypeObject(newItem);
                this.item = {...this.mapPhotos(newItem), facilities};
                this.getCities();
            },
            onSubmit() {
                this.submitFunction(this.item);
            },
            makeApplication() {
                Api.post('/application').then(response => {
                    Api.post(`/application/${response.data.id}/location?id=${this.item.id}`).then(() => {
                        this.$router.push(`/applications/${response.data.id}/edit`);
                    });
                })
            },
            onDelete() {
                return Api.delete(`/location/${this.item.id}`).then((response) => {
                    if (response.success) {
                        this.$router.back()
                    } else {
                        alert('ERROR')
                    }
                })
            },
            onCheck(val) {
                const item = this.item;
                item['has_' + val] = 1 - item['has_' + val];
                const facilities = getTypeObject(item);
                this.item = {...item, facilities};
            },
            addBeacons(beacons) {
                this.item.beacon = [...this.item.beacon, ...beacons];
                beacons.forEach(this.postBeacon);
            },
            postBeacon(beacon) {
                Api.post(`/location/${this.item.id}/beacon?id=${beacon.id}`);
            },
            deleteBeacons(beacons) {
                const beacon_ids = beacons.map(x => x.id);
                this.item.beacon = this.item.beacon.filter(x => !beacon_ids.includes(x.id));
                beacons.forEach(this.deleteBeacon);
            },
            deleteBeacon(beacon) {
                Api.delete(`/location/${this.item.id}/beacon/${beacon.id}`);
            },
            getCities() {
                this.cities = CITIES(this.item.prefecture);
                if (this.item.prefecture === this.itemModel.prefecture) {
                    this.item.city = this.itemModel.city;
                } else {
                    this.item.city = this.cities[0].value;
                }
            },
            mapPhotos(item) {
                return {...item, photo: item.photo.map(x => ({...x, image_default: x.image_default.substr(0,4) === 'http' ? x.image_default : config + x.image_default}))};
            },
            addImage(photo, photoModel) {
                var formData = new FormData();
                formData.append('image', photo);
                return Api.post(`/location/${this.item.id}/photo?top=${photoModel.top}&right=${photoModel.right}&bottom=${photoModel.bottom}&left=${photoModel.left}`, formData).then((response) => {
                    this.item.photo.push({...photoModel, id: response.data.id})
                })
            },
            deleteImage(photoModel, index) {
                if (photoModel.id) {
                    return Api.delete(`/location/${this.item.id}/photo/${this.item.photo[index].id}`).then(() => {
                        this.item.photo.splice(index, 1);
                    })
                } else {
                    this.item.photo.splice(index, 1);
                }
            }
        },
    }
</script>
