<template>
  <div class="mx-0">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">check_circle</v-icon>
          <span>&ensp;</span>
          <span class="success--text">
            <strong>{{ $t('comfirm.save.title') }}</strong>
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs12>
              <p class="grey--text">{{ $t('comfirm.save.text') }}</p>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClose">{{ $t('common.close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="success" :loading="loading" @click.stop="onSave">{{ $t('common.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "loading"],
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onSave() {
      this.$emit("onSave");
    }
  }
};
</script>

<style>
</style>
