<template>
  <v-card style="margin: 0 auto;">
    <br><br>
    <center>
      <div v-if="series_os.length && (series_os[0].data[0]!==0 || series_os[1].data[0]!==0)">
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
      <p style="margin-top: 60px; width: 20%; display: flex; justify-content: flex-end;">{{$t('summary.os')}}</p><stack-bar-graph :series="series_os" :chartOptions="chartOptions_os"/></div>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <p v-if="series_ios_version.length && series_ios_version[0].data.length>0" style="margin-top: 60px; width: 20%; display: flex; justify-content: flex-end;">{{$t('summary.ios_version')}}</p><stack-bar-graph :series="series_ios_version" :chartOptions="chartOptions_ios_version"/></div>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <p v-if="series_ios_model.length && series_ios_model[0].data.length>0" style="margin-top: 60px; width: 20%; display: flex; justify-content: flex-end;">{{$t('summary.ios_model')}}</p><stack-bar-graph :series="series_ios_model" :chartOptions="chartOptions_ios_model"/></div>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <p v-if="series_android_version.length && series_android_version[0].data.length>0" style="margin-top: 60px; width: 20%; display: flex; justify-content: flex-end;">{{$t('summary.android_version')}}</p><stack-bar-graph :series="series_android_version" :chartOptions="chartOptions_android_version"/></div>
      <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <p v-if="series_android_model.length && series_android_model[0].data.length>0" style="margin-top: 60px; width: 20%; display: flex; justify-content: flex-end;">{{$t('summary.android_model')}}</p><stack-bar-graph :series="series_android_model" :chartOptions="chartOptions_android_model"/></div>
      </div>
    </center>
    <br><br><br>
    <v-alert
      :value="true"
      color="success"
      icon="information"
      outline
      style="width: 80%;"
      v-if="range.start!==undefined && series_os.length && series_os[0].data[0]===0 && series_os[1].data[0]===0"
    >
      {{$t('summary.not_found')}}
    </v-alert>
    <br><br><br>
  </v-card>
</template>

<script>
  import StackBarGraph from "./StackBarGraph";

  export default {
    props: ["series_os", "series_ios_version", "series_ios_model", "series_android_version", "series_android_model", "range"],
    components: {
      StackBarGraph
    },

    data() {
      return {
        chartOptions_os: {
          colors: ['#1c54ed', '#faa907'],
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '100%',
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              }
            }
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 30,
            showForSingleSeries: true
          }
        },
        chartOptions_ios_version: {
          colors: ['#0fe7f2', '#faa907', '#5522e0', '#f7e516', '#9b19f7', '#11fa4f', '#d90d9b', '#0dba6f', '#fa0a16', '#0c6352', '#686b6a', '#B0B8B8'],
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '100%',
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              }
            },
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 30,
            showForSingleSeries: true
          }
        },
        chartOptions_ios_model: {
          colors: ['#0fe7f2', '#faa907', '#5522e0', '#f7e516', '#9b19f7', '#11fa4f', '#d90d9b', '#0dba6f', '#fa0a16', '#0c6352', '#686b6a', '#B0B8B8'],
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '100%',
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              }
            },
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 30,
            showForSingleSeries: true
          }
        },
        chartOptions_android_version: {
          colors: ['#0fe7f2', '#faa907', '#5522e0', '#f7e516', '#9b19f7', '#11fa4f', '#d90d9b', '#0dba6f', '#fa0a16', '#0c6352', '#686b6a', '#B0B8B8'],
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '100%',
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              }
            },
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 30,
            showForSingleSeries: true
          }
        },
        chartOptions_android_model: {
          colors: ['#0fe7f2', '#faa907', '#5522e0', '#f7e516', '#9b19f7', '#11fa4f', '#d90d9b', '#0dba6f', '#fa0a16', '#0c6352', '#686b6a', '#B0B8B8'],
          chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '100%',
              dataLabels: {
                position: 'center',
                hideOverflowingLabels: false,
              }
            },
          },
          xaxis: {
            categories: [""],
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 30,
            showForSingleSeries: true
          },
        }
      }
    }
  }
</script>

<style scoped>

</style>



