<template>
  <v-card style="margin: 0 auto;">
    <br><br>
    <center>

      <div :class="{'scrollable':is_scrolling}">

      <apexchart width="100%" type="bar" :height="height"  style="margin: 0 auto;" :options="chartOptions" :series="series"
                 v-if="!(series[0].data === undefined || series[0].data.length == 0) && series[0].data.length >0"/>
      </div>
    </center>

    <div v-if="(series[0].data === undefined || series[0].data.length == 0) ">
      <br>
      <br>
      <v-alert
        :value="true"
        color="success"
        icon="information"
        outline
        style="width: 80%; margin-top: 20px; margin-bottom: 20px;"
      >
        {{$t('summary.not_found')}}
      </v-alert>
      <br><br>
    </div>
    <br><br><br>
  </v-card>
</template>

<script>

  import apexchart from 'vue-apexcharts';

  export default {
    props: ["series","loading_show"],
    components: {
      apexchart
    },

    data() {
      return {
        height:"400px",
        is_scrolling:true,
        chartOptions: {
          plotOptions: {
            bar:
              {
                horizontal: true,
              },
          },
          xaxis:{
            tickAmount: 0,

          },
          chart: {
            toolbar: {
              show: false
            }
          }
        },
      }
    },
    beforeMount() {

      const values = (this.series[0]["data"].map(x=> Number(x["y"])));
      const find_max_value=Math.max.apply(null, values);
      this.chartOptions.xaxis.tickAmount=(find_max_value<9)?find_max_value:9;
      this.height = this.series[0]["data"].length * 60 + "px";
    }
  }
</script>

<style scoped>
.scrollable{
  overflow-y: scroll;

}
</style>
