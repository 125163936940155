var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("person")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('title.user')))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.email')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || _vm.$t('rules.format')
                  ]},model:{value:(_vm.items.email),callback:function ($$v) {_vm.$set(_vm.items, "email", $$v)},expression:"items.email"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.password')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-zA-Z0-9/\*-.,\!#\$%&\(\)~\|_\+]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length >= 8 || _vm.$t('rules.min8c')
                  ],"append-icon":_vm.visibility ? 'visibility' : 'visibility_off',"append-icon-cb":() => (_vm.visibility = !_vm.visibility),"type":_vm.visibility ? 'password' : 'text',"hint":_vm.$t('discriptions.password'),"persistent-hint":""},model:{value:(_vm.items.password),callback:function ($$v) {_vm.$set(_vm.items, "password", $$v)},expression:"items.password"}})],1)],1),_c('v-layout',{staticClass:"pb-2",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.role')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.items.role),callback:function ($$v) {_vm.$set(_vm.items, "role", $$v)},expression:"items.role"}},_vm._l((_vm.role),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.value,"color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t(`role.${item.text}`)))]),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.image')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:[
                    'image-box',
                    _vm.error ? 'size' : ''
                  ],on:{"click":function($event){$event.stopPropagation();return _vm.onPickFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDropFile.apply(null, arguments)}}},[(!_vm.image)?_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.$t('discriptions.image')))]):_vm._e(),_c('img',{staticClass:"image small",attrs:{"src":_vm.image,"alt":""}}),_c('br'),(_vm.image)?_c('v-btn',{attrs:{"outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveFile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.remove')))]):_vm._e(),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChangeFile}})],1),(_vm.error)?_c('span',{staticClass:"caption error--text"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }