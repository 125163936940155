import axios from "axios";
import config from "../config";
import store from "../store";

export default {
  init() {
    axios.defaults.baseURL = `${config}/admin`;
    axios.interceptors.request.use(config => {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      return config;
    });
    if(localStorage.getItem("token")) {
      axios.interceptors.response.use(
        response => response,
        error => {
          const {status} = error.response;
          if (status === 401) {
            store.dispatch("onLogout");
            store.dispatch("onNotify", {
              type: "error",
              text:
                  "The session has expired. Please login again to use the service."
            });
          }
          return Promise.reject(error);
        }
      );
    }
    axios.interceptors.response.use(response => {
      const token = response.data.payload
        ? response.data.payload.token
        : response.headers["Authorization"];
      if (token) {
        localStorage.setItem("token", token);
      }
      return response;
    });
  },
  refetch() {
    axios.get("/auth/user").then(response => {
      if (response.data.code === 200) {
        store.dispatch("setUser", response.data.payload);
      } else {
      }
    });
  }
};
