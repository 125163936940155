var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("place")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('title.region')))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"autofocus":"","rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[一-龠_]+|[ぁ-ゔ_]+|[ァ-ヴー_]+|[a-zA-Z0-9_]+|[ａ-ｚＡ-Ｚ０-９_]+[々〆〤_]+ $/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{staticClass:"pb-4",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.type')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.items.type),callback:function ($$v) {_vm.$set(_vm.items, "type", $$v)},expression:"items.type"}},_vm._l((_vm.type),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.value,"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),1),_c('p',{staticClass:"caption grey--text pt-2 mb-0"},[_vm._v(_vm._s(_vm.$t('discriptions.region_type')))])],1)],1),(_vm.items.type === 'Beacon')?[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("UUID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"########-####-####-####-############","rules":[
                      v => !!v || _vm.$t('rules.required'),
                      v => /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[0-5][a-fA-F0-9]{3}-[089aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/.test(v) || _vm.$t('rules.format')
                    ]},model:{value:(_vm.items.uuid),callback:function ($$v) {_vm.$set(_vm.items, "uuid", $$v)},expression:"items.uuid"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("MAJOR / MINOR")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                      v => v >= 0 || _vm.$t('rules.min0n'),
                      v => v <= 65535 || _vm.$t('rules.max65535n')
                    ],"min":"0","max":"65535","hint":_vm.$t('discriptions.major_minor'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.major),callback:function ($$v) {_vm.$set(_vm.items, "major", $$v)},expression:"items.major"}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                      v => v >= 0 || _vm.$t('rules.min0n'),
                      v => v <= 65535 || _vm.$t('rules.max65535n')
                    ],"min":"0","max":"65535","hint":_vm.$t('discriptions.major_minor'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.minor),callback:function ($$v) {_vm.$set(_vm.items, "minor", $$v)},expression:"items.minor"}})],1)],1)]:_vm._e(),(_vm.items.type === 'Geofence')?[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.location')))])]),_c('v-flex',{staticClass:"grey--text pt-3",attrs:{"xs10":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.latitude'))+" / "+_vm._s(_vm.$t('items.longitude'))+" / "+_vm._s(_vm.$t('items.radius')))])])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs2":"","offset-xs2":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                      v => !!v || _vm.$t('rules.required')
                    ],"type":"number"},model:{value:(_vm.items.latitude),callback:function ($$v) {_vm.$set(_vm.items, "latitude", $$v)},expression:"items.latitude"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                      v => !!v || _vm.$t('rules.required')
                    ],"type":"number"},model:{value:(_vm.items.longitude),callback:function ($$v) {_vm.$set(_vm.items, "longitude", $$v)},expression:"items.longitude"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                      v => !!v || _vm.$t('rules.required')
                    ],"type":"number"},model:{value:(_vm.items.radius),callback:function ($$v) {_vm.$set(_vm.items, "radius", $$v)},expression:"items.radius"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":"","offset-xs2":""}},[_c('google-map',{attrs:{"name":"region","latitude":_vm.items.latitude,"longitude":_vm.items.longitude,"radius":_vm.items.radius},on:{"getLatLng":_vm.getLatLng}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('div',{staticClass:"caption-box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('regioncaption.title')))]),_c('h4',[_vm._v(_vm._s(_vm.$t('regioncaption.subtitle01')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('regioncaption.subtext01'))}}),_c('h4',[_vm._v(_vm._s(_vm.$t('regioncaption.subtitle02')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('regioncaption.subtext02'))}})])])],1)]:_vm._e(),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1)],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }