<template>
  <div>
    <v-tooltip bottom>
      <v-btn icon slot="activator" @click.stop="onOpen">
        <v-icon>filter_list</v-icon>
      </v-btn>
      <span>{{ $t('common.search_operations') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">search</v-icon>
          <span>&ensp;</span>
          <h4>{{ $t('common.search_operations') }}</h4>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.name') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-model="search.name"
                class="pt-0"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row class="pb-4">
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.plan') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-btn-toggle v-model="search.plan">
                <v-btn
                  v-for="(item, i) in plan"
                  :key="i"
                  :value="item.value"
                  color="primary"
                  class="white--text"
                >
                  <span>{{ item.text }}</span>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t('items.status') }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-btn-toggle v-model="search.enabled">
                <v-btn
                  v-for="(item, i) in enabled"
                  :key="i"
                  :value="item.value"
                  :color="item.value ? 'success' : 'error'"
                  class="white--text"
                >
                  <span>{{ $t(`shared.status.${item.text}`) }}</span>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClear">{{ $t('common.clear') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="onSearch">{{ $t('common.search') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      plan: [
        { icon: "directions_walk", value: "Startup", text: "startup" },
        { icon: "directions_run", value: "Standard", text: "standard" },
        { icon: "directions_bike", value: "Premium", text: "premium" }
      ],
      enabled: [
        { value: true, text: "enabled" },
        { value: false, text: "disabled" }
      ],
      search: {}
    };
  },
  methods: {
    onOpen() {
      this.dialog = true;
      this.search = this.$store.getters.search;
    },
    onSearch() {
      this.$store.dispatch("setSearch", this.search);
      this.onFetch();
    },
    onClear() {
      this.$store.dispatch("setSearch", {
        name: "",
        plan: ""
      });
      this.onFetch();
    },
    onFetch() {
      this.dialog = false;
      this.$emit("onFetch");
    }
  }
};
</script>

<style>
</style>
