<template>
  <div>
    <v-layout row wrap>
      <v-flex>
        <v-toolbar color="white" class="elevation-1">
          <v-select
            style="width: 60px; margin-left: 20px; margin-top: 10px;"
            :items="company_with_location"
            item-text="name"
            item-value="id"
            :label="$t('apply.owner')"
            outlined
            v-model="selected_type"
            @change="getApplicationByOwner(selected_type)"
          ></v-select>
          <p style="margin: 20px; font-size: 16px;">{{$t('apply.exceeded_application')}}</p>
          <v-checkbox style="margin-left: 20px; margin-top: 20px;" :label="$t('apply.only_exceeded_application')" v-model="exceed">
          </v-checkbox>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <div v-if="exceed===true">
          <p>申請依頼一覧</p>
          <div v-for="item in exceed_item">
            <p v-if="item.application && item.application.length > 0">申請先 {{item.name}}</p>
            <application-card v-for="item in item.application" :key="item.id" :item="item" type="application"/>
          </div>
        </div>
        <div v-else>
          <p>申請依頼一覧</p>
          <div v-for="item in selected_items">
            <p v-if="item.application && item.application.length > 0">申請先 {{item.name}}</p>
            <application-card v-for="item in item.application" :key="item.id" :item="item" type="application"/>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

    import Api from "../../api/lmp";
    import ApplicationCard from "../../components/cards/ApplicationCard";
    import ApplicationRequestSearchOperations from "../../components/search/ApplicationRequestSearchOperations";

    export default {
        data() {
            return {
                items: [],
                companies: [],
                company_with_location: [],
                total: 0,
                rowsPerPage: 10,
                pagination: 1,
                selected_type: null,
                exceed: null,
                selected_items: [],
            };
        },
        components: {ApplicationCard, ApplicationRequestSearchOperations},
        computed: {
            pages() {
                return Math.ceil(this.total / this.rowsPerPage);
            },
            exceed_item() {
                let exceeded_item = [...this.selected_items].map(a => ({...a}));
                return exceeded_item.map(item => {item.application = item.application.filter(o => o.examination[0].is_expired=== true); return item});
            }
        },
        watch: {
            pagination() {
                this.onFetch();
            },
            rowsPerPage() {
                this.pagination = 1;
                this.onFetch();
            }
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onFetch(search = null) {
                let query = '';
                if(search && search.client_id) {
                    query += `&filter[]=company(${search.client_id})`;
                }
                if(search && search.enabled === 1) {
                    query += `&filter[]=exceeded()`;
                }

                Promise.all([
                    Api.get('/application?with[]=location&with[]=appli&with[]=leaser&with[]=location.photo&with[]=examination&filter[]=started(false)' + query),
                    Api.get('/company'),
                    Api.get('/company?filter[]=owner()')])
                    .then(responses => {

                        // get list of location owners
                        const all_companies = {name: "全部", id: "all"};
                        this.companies = responses[1].data;
                        this.companies.unshift(all_companies);

                        // get all application data
                        this.items = [...this.companies];
                        responses[0].data.map(item => {
                            let location_list = [];
                                item.location.map(location => {
                                    if(location_list.indexOf(location.company_id) === -1) {
                                        let index = this.items.findIndex(x => x.id === location.company_id);
                                        if(this.items[index].hasOwnProperty('application')){
                                            const found = this.items[index]['application'].some(el => el.id === location.id);
                                            if(!found) {
                                                this.items[index]['application'].push(item);
                                            }
                                        } else{
                                            this.items[index]['application'] = new Array(), this.items[index]['application'].push(item);
                                        }
                                        location_list.push(index)
                                    }
                                })
                        });

                        this.items.map(item => item.hasOwnProperty('application') ? null: item.application = []);

                        // make a copy of the all application data, and used for the selected location owner
                        this.selected_items = [...this.items];

                        // get total number of the applications
                        this.total = Object.keys(responses[2].data).length;
                        this.company_with_location = responses[2].data;
                        this.company_with_location.unshift(all_companies);
                    });
            },

            getApplicationByOwner(selected_type){
                if(selected_type === 'all'){
                    this.selected_items = this.items;
                }else{
                    let index = this.items.findIndex(x => x.id === selected_type);
                    this.selected_items = [this.items[index]];
                }
            }
        },
    };
</script>

