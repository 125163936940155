<template>
  <div class="py-2">
    <v-tabs icons-and-text centered dark color="blue-grey darken-4">
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab v-for="(item, i) in getMenu()" :key="i" :href="`#tab0${i+1}`">
        {{ $t(`setting.${item.text}`) }}
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
      <v-tab-item
        v-for="(item, i) in getMenu()"
        :key="i"
        :id="`tab0${i+1}`"
      >
        <component :is="item.component"></component>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import menu from "../props/setting";
import ClientCard from "../components/settings/ClientCard";
import UserCard from "../components/settings/UserCard";
import SecurityCard from "../components/settings/SecurityCard";
import SupportCard from "../components/settings/SupportCard";
import ImportCard from "../components/settings/ImportCard";

export default {
  components: {
    ClientCard,
    UserCard,
    SecurityCard,
    SupportCard,
    ImportCard
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    getMenu() {
      return menu(this.user.role);
    }
  }
};
</script>

<style>
</style>
