import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import OutlineTemplate from "../templates/OutlineTemplate";
import DefaultTemplate from "../templates/DefaultTemplate";
import LoginPage from "../pages/auth/LoginPage";
import HomePage from "../pages/HomePage";
import ClientsPage from "../pages/clients/ClientsPage";
import ClientsCreatePage from "../pages/clients/ClientsCreatePage";
import ClientsEditPage from "../pages/clients/ClientsEditPage";
import AppsPage from "../pages/apps/AppsPage";
import AppsCreatePage from "../pages/apps/AppsCreatePage";
import AppsEditPage from "../pages/apps/AppsEditPage";
import BeaconsPage from "../pages/beacons/BeaconsPage";
import BeaconsCreatePage from "../pages/beacons/BeaconsCreatePage";
import BeaconsEditPage from "../pages/beacons/BeaconsEditPage";
import ClustersPage from "../pages/clusters/ClustersPage";
import ClustersCreatePage from "../pages/clusters/ClustersCreatePage";
import ClustersEditPage from "../pages/clusters/ClustersEditPage";
import RegionsPage from "../pages/regions/RegionsPage";
import RegionsCreatePage from "../pages/regions/RegionsCreatePage";
import RegionsEditPage from "../pages/regions/RegionsEditPage";
import ActionsPage from "../pages/actions/ActionsPage";
import ActionsCreatePage from "../pages/actions/ActionsCreatePage";
import ActionsEditPage from "../pages/actions/ActionsEditPage";
import UsersPage from "../pages/users/UsersPage";
import UsersCreatePage from "../pages/users/UsersCreatePage";
import UsersEditPage from "../pages/users/UsersEditPage";
import SettingsPage from "../pages/SettingsPage";
import NotFoundPage from "../pages/NotFoundPage";
import SummaryPage from "../pages/summary/SummaryPage";
import ApplyPage from "../pages/apply/ApplyPage";
import ApplyViewPage from "../pages/apply/ApplyViewPage";
import InquiryViewPage from "../pages/inquiries/InquiryViewPage";
import InquiryListPage from "../pages/inquiries/InquiryListPage";
import AnnouncementsHomeConsole from "../pages/announcements/AnnouncementsConsole";
import LocationsPage from "../pages/locations/LocationSearchPage";
import LocationDetailsPage from "../pages/locations/LocationSearchDetailsPage";
import ReceiptsPage from "../pages/receipts/ReceiptsPage";
import ReceiptDetailsPage from "../pages/receipts/ReceiptDetailsPage";
import DevicesPage from "../pages/devices/DevicesPage.vue";
import DevicesCreatePage from "../pages/devices/DevicesCreatePage.vue";
import DevicesEditPage from "../pages/devices/DevicesEditPage.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/login",
      component: OutlineTemplate,
      children: [{ path: "", component: LoginPage }]
    },
    {
      path: "/home",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: HomePage,
          meta: { requiresAuth: true, page: "Home", pagekey: "menu.Home" }
        }
      ]
    },
    {
      path: "/clients",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: ClientsPage,
          meta: { requiresAuth: true, page: "Clients", pagekey: "menu.Clients" }
        },
        {
          path: "new",
          component: ClientsCreatePage,
          meta: { requiresAuth: true, page: "Clients", pagekey: "menu.Clients" }
        },
        {
          path: ":id",
          component: ClientsEditPage,
          meta: { requiresAuth: true, page: "Clients", pagekey: "menu.Clients" }
        }
      ]
    },
    {
      path: "/apps",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: AppsPage,
          meta: { requiresAuth: true, page: "Apps", pagekey: "menu.Apps" }
        },
        {
          path: "new",
          component: AppsCreatePage,
          meta: { requiresAuth: true, page: "Apps", pagekey: "menu.Apps" }
        },
        {
          path: ":id",
          component: AppsEditPage,
          meta: { requiresAuth: true, page: "Apps", pagekey: "menu.Apps" }
        }
      ]
    },
    {
      path: "/beacons",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: BeaconsPage,
          meta: { requiresAuth: true, page: "Beacons", pagekey: "menu.Beacons" }
        },
        {
          path: "new",
          component: BeaconsCreatePage,
          meta: { requiresAuth: true, page: "Beacons", pagekey: "menu.Beacons" }
        },
        {
          path: ":id",
          component: BeaconsEditPage,
          meta: { requiresAuth: true, page: "Beacons", pagekey: "menu.Beacons" }
        }
      ]
    },
    {
      path: "/devices",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: DevicesPage,
          meta: { requiresAuth: true, page: "Devices", pagekey: "menu.Devices" }
        },
        {
          path: "new",
          component: DevicesCreatePage,
          meta: { requiresAuth: true, page: "Devices", pagekey: "menu.Devices" }
        },
        {
          path: ":id",
          component: DevicesEditPage,
          meta: { requiresAuth: true, page: "Devices", pagekey: "menu.Devices"}
        }
      ]
    },
    {
      path: "/clusters",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: ClustersPage,
          meta: { requiresAuth: true, page: "Clusters", pagekey: "menu.Clusters" }
        },
        {
          path: "new",
          component: ClustersCreatePage,
          meta: { requiresAuth: true, page: "Clusters", pagekey: "menu.Clusters" }
        },
        {
          path: ":id",
          component: ClustersEditPage,
          meta: { requiresAuth: true, page: "Clusters", pagekey: "menu.Clusters" }
        }
      ]
    },
    {
      path: "/regions",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: RegionsPage,
          meta: { requiresAuth: true, page: "Regions", pagekey: "menu.Regions" }
        },
        {
          path: "new",
          component: RegionsCreatePage,
          meta: { requiresAuth: true, page: "Regions", pagekey: "menu.Regions" }
        },
        {
          path: ":id",
          component: RegionsEditPage,
          meta: { requiresAuth: true, page: "Regions", pagekey: "menu.Regions" }
        }
      ]
    },
    {
      path: "/actions",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: ActionsPage,
          meta: { requiresAuth: true, page: "Actions", pagekey: "menu.Actions" }
        },
        {
          path: "new",
          component: ActionsCreatePage,
          meta: { requiresAuth: true, page: "Actions", pagekey: "menu.Actions" }
        },
        {
          path: ":id",
          component: ActionsEditPage,
          meta: { requiresAuth: true, page: "Actions", pagekey: "menu.Actions" }
        }
      ]
    },
    {
      path: "/locations",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: LocationsPage,
          meta: { requiresAuth: true, page: "Locations", pagekey: "menu.Locations" }
        },
        {
          path: ":location_id",
          component: LocationDetailsPage,
          meta: { requiresAuth: true, page: "Locations", pagekey: "menu.Locations" }
        }
      ]
    },
    {
      path: "/receipts",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: ReceiptsPage,
          meta: { requiresAuth: true, page: "Receipts", pagekey: "menu.Receipts" }
        },
        {
          path: ":id",
          component: ReceiptDetailsPage,
          meta: { requiresAuth: true, page: "Receipts", pagekey: "menu.Receipts" }
        }
      ]
    },
    {
      path: "/users",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: UsersPage,
          meta: { requiresAuth: true, page: "Users", pagekey: "menu.Users" }
        },
        {
          path: "new",
          component: UsersCreatePage,
          meta: { requiresAuth: true, page: "Users", pagekey: "menu.Users" }
        },
        {
          path: ":id",
          component: UsersEditPage,
          meta: { requiresAuth: true, page: "Users", pagekey: "menu.Users" }
        }
      ]
    },
    {
      path: "/settings",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: SettingsPage,
          meta: { requiresAuth: true, page: "Settings", pagekey: "menu.Settings" }
        }
      ]
    },
    {
      path: "/summary",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: SummaryPage,
          meta: { requiresAuth: true, page: "Summary", pagekey: "menu.Summary" }
        }
      ]
    },
    {
      path: "/announcements",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: AnnouncementsHomeConsole,
          meta: { requiresAuth: true, page: "Announcements", pagekey: "menu.Announcements" }
        }
      ]
    },
    {
      path: "/apply",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: ApplyPage,
          meta: { requiresAuth: true, page: "Application requests", pagekey: "menu.Application requests" }
        },
        {
          path: ":id",
          component: ApplyViewPage,
          meta: { requiresAuth: true, page: "Application detail", pagekey: "menu.Application detail" }
        }
      ]
    },
    {
      path: "/inquiries",
      component: DefaultTemplate,
      children: [
        {
          path: "",
          component: InquiryListPage,
          meta: { requiresAuth: true, page: "Inquiries", pagekey: "menu.Inquiries" }
        },
        {
          path: ":inquiry_id",
          component: InquiryViewPage,
          meta: { requiresAuth: true, page: "Application detail", pagekey: "menu.Application detail" }
        }
      ]
    },
    {
      path: "*",
      component: OutlineTemplate,
      children: [{ path: "", component: NotFoundPage }]
    }
  ],
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((toRoute, fromRoute, next) => {
  const _this = this
  const updateTitle = () => {
    try {
      const title = toRoute.meta && toRoute.meta.pagekey ? router.app.$t(toRoute.meta.pagekey) : router.app.$t('menu.Home')
      const host = window.location.host.split('.')[0]
      window.document.title = `[${host}] ${title} | Loco`;
    } catch (e) {
      setTimeout(updateTitle.bind(_this), 1000)
    }
  }
  updateTitle()
  next();
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.authenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
