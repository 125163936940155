<template>
  <div>
    <application-detail :itemModel="item" :mode="true" locationType="applicationDetail"></application-detail>
  </div>
</template>

<script>
    import ApplicationDetail from "../../components/apply/ApplicationDetail";
    import Api from "../../api/lmp";

    export default {
        components: {
            ApplicationDetail
        },
        data() {
            return {
                item: null
            };
        },
        mounted() {
            this.onFetch();
        },
        methods: {
            onFetch() {
                const {id} = this.$route.params;
                Api.get(`/application/${id}?with[]=location&with[]=location.company&with[]=location.photo&with[]=appli&with[]=examination`).then(response => {
                    this.item = response.data;
                })
            },
        },
    };
</script>

<style>
</style>
