<template>
  <div>
    <v-layout row wrap>
      <v-flex xs10>
        <v-toolbar color="white" class="elevation-1">
          <v-toolbar-title>
            <span class="caption">{{ $t('common.total') }}</span>
            {{ total | comma }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <search-operations v-on:onFetch="onFetch" :owners="owners"></search-operations>
          <v-btn
            outline
            color="success"
            :to="`${$route.path}/new`"
            v-if="user.role === 'Administrator'"
          >{{ $t('common.create') }}</v-btn>
        </v-toolbar>
      </v-flex>
      <v-flex xs2>
        <v-toolbar color="white" class="elevation-1">
          <v-tooltip bottom class="full-activator">
            <v-select
              :items="[10,30,50,100]"
              v-model="options.rowsPerPage"
              slot="activator"
              hide-details
              style="padding: 0 16px"
            ></v-select>
            <span>{{ $t('common.rows_per_page') }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="options"
            :total-items="total"
            :loading="loading"
            class="elevation-1"
            :must-sort="true"
            :custom-sort="customSort"
            :rows-per-page-items="[10,30,50,100]"
        >
          <template v-slot:items="props">
            <td @click="handleClick(props.item)">{{ props.item.name }}</td>
            <td class="text-xs" @click="handleClick(props.item)" :title="props.item.uuid">{{ props.item.uuid.substr(0, 8) }}...</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.mounted == true ? $t('items.mounted') : ( props.item.mounted == false ? $t('items.movable') : "") }}</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.major }}</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.minor }}</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.local_name }}</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.module_id }}</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.battery }}%</td>
            <td class="text-xs" @click="handleClick(props.item)">{{ props.item.battery_update ? $moment(props.item.battery_update*1000).format('YYYY-MM-DD HH:mm') : '--' }}</td>
            <td class="text-xs" @click="handleClick(props.item)">
              <v-icon v-if="props.item.enabled" color="green">circle</v-icon>
              <v-icon v-if="!props.item.enabled" color="red">cancel</v-icon>
            </td>
          </template>

        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import NumericFilter from "../../utils/filters/NumericFilter";
import ColorFilter from "../../utils/filters/ColorFilter";
import TimeFilter from "../../utils/filters/TimeFilter";
import SearchOperations from "../../components/search/BeaconsSearchOperations";


export default {
  data() {
    return {
      owners: [],
      total: 1,
      page: 1,
      pages: 1,
      apps: [],
      items: [],
      loading: true,
      options: {
        page: 1,
        rowsPerPage: localStorage.getItem("rowsPerPage") ? parseInt(localStorage.getItem("rowsPerPage")) : 30
      },
      init: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'UUID', value: 'uuid' },
        { text: this.$t('items.is_mounted'), value: 'mounted' },
        { text: 'Major', value: 'major' },
        { text: 'Minor', value: 'minor' },
        { text: 'Local Name', value: 'local_name' },
        { text: 'Module ID', value: 'module_id' },
        { text: this.$t('items.battery'), value: 'battery' },
        { text: this.$t('items.battery_updated'), value: 'battery_update' },
        { text: 'ステータス', value: 'enabled' },
      ],
    };
  },
  components: {
    SearchOperations
  },
  computed: {
    ...mapState(["user"])
  },
  watch: {
    options: {
      handler (v1,v2) {
        this.onFetch();
      },
      deep: true,
    }

  },
  mounted() {
    this.onFetchOwners();
    this.onFetchApps();
  },
  methods: {
    customSort(items, index, desc) {
      return items
    },
    handleClick(value) {
      this.$router.push(`${this.$route.path}/${value.id}`)
    },
    onFetch() {
      let search = this.$store.getters.search;
      const { sortBy, descending, page, rowsPerPage } = this.options
      this.loading = true;
      axios
        .get(this.$route.path, {
          params: Object.assign(
            {
              per_page: rowsPerPage,
              page: page,
              desc: descending,
              sort: sortBy
            },
            search
          )
        })
        .then(response => {
          if (response.data.code === 200) {
            if (this.init) {
              let page = parseInt(localStorage.getItem("pagination"))
              if (page !== 1 && !isNaN(page)) this.options.page = page;
              else this.init = false
            }
            if (!this.init) {
              this.items = response.data.payload.hits;
              this.total = response.data.payload.total
              this.loading = false;

              localStorage.setItem("pagination", this.options.page);
              localStorage.setItem("rowsPerPage", this.options.rowsPerPage);
            }
            this.init = false;
          }
        });

    },
    onFetchOwners() {
      axios
        .get("/clients", {
          params: {
            per_page: 100,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.owners = response.data.payload.hits;
          } else {
          }
        });
    },
    onFetchApps() {
      axios
        .get("/apps", {
          params: {
            client_id: this.$route.params.id,
            per_page: 1000,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            const apps = response.data.payload.hits;
            this.apps = apps.map(app => app.id);
          }
        });
    },
    getClientName(id) {
      let owner = this.owners.filter(owner => {
        return owner.id === id;
      });
      return owner.length > 0 ? owner[0].name : "";
    }
  },
  mixins: [NumericFilter, ColorFilter, TimeFilter]
};
</script>
