<template>

</template>

<script>
export default {
  filters: {
      dateFormat(ts) {
          if (ts !== '') {
              let d = new Date(ts * 1000);
              let year = d.getFullYear();
              let month =
                  d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
              let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
              let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
              let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
              let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
              return (
                  year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
              );
          } else {
              return "ー";
          }
      }
  }
};
</script>

<style>
</style>
