var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("whatshot")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('title.action')))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"autofocus":"","rules":[
                    v => !!v || _vm.$t('rules.required'),
                    // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("URI")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !v || /^[a-zA-Z0-9:/?#\[\]@!$&'()*+,;=.\-_]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 250 || _vm.$t('rules.max250c')
                  ],"counter":"250"},model:{value:(_vm.items.uri),callback:function ($$v) {_vm.$set(_vm.items, "uri", $$v)},expression:"items.uri"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.action_interval')))])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || v === 0 || _vm.$t('rules.required'),
                    v => v >= 0 || _vm.$t('rules.min0n'),
                    v => v <= 99999999 || _vm.$t('rules.max99999999n')
                  ],"min":"0","max":"99999999","suffix":_vm.$t('suffix.second'),"hint":_vm.$t('discriptions.action_interval'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.interval),callback:function ($$v) {_vm.$set(_vm.items, "interval", $$v)},expression:"items.interval"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.parameters')))])]),_c('v-flex',{attrs:{"xs6":""}},[_vm._l((_vm.params),function(item,i){return _c('v-layout',{key:i,attrs:{"row":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                        v => !!v || _vm.$t('rules.required'),
                        // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                        v => v.length <= 45 || _vm.$t('rules.max45c')
                      ],"counter":"45"},model:{value:(item.key),callback:function ($$v) {_vm.$set(item, "key", $$v)},expression:"item.key"}})],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                        v => !!v || _vm.$t('rules.required'),
                        // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                        v => v.length <= 45 || _vm.$t('rules.max45c')
                      ],"counter":"45"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_c('v-btn',{attrs:{"outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveBox(i)}}},[_vm._v(_vm._s(_vm.$t('common.delete')))])],1)}),_c('v-layout',{staticClass:"add-box"},[_c('div',{staticClass:"inner"},[_c('v-btn',{attrs:{"outline":"","small":"","color":"primary","disabled":_vm.params.length >= 99},on:{"click":function($event){$event.stopPropagation();return _vm.onAddBox.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.add')))])],1)])],2)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }