<template>
  <div class="notfound">
    <h1>404</h1>
    <p>Woops, Looks like this page doesn't exist.</p>
    <v-btn outline color="primary" to="/home">back to home</v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style>
.notfound {
  padding: 25px;
}
.notfound h1 {
  font-size: 13rem;
  letter-spacing: -0.05em;
  line-height: 13rem;
}
.notfound p {
  font-size: 2rem;
  font-style: oblique;
}
</style>
