<template>
  <v-card flat>
    <v-card-text>
      <v-layout row>
        <v-flex xs2 class="text-xs-right">
          <img src="../../assets/kontaktio.png" alt="" width="100">
        </v-flex>
        <v-flex xs6>
          <v-layout row class="import-card">
            <v-flex xs6>
              <dl>
                <dt>Kontakt.io</dt>
                <dd>
                  <p class="mb-0">{{ $t('discriptions.import') }}</p>
                </dd>
              </dl>
            </v-flex>
            <v-flex xs6>
              <v-dialog v-model="dialog" scrollable persistent>
                <v-btn outline color="info" slot="activator">{{ $t('common.import') }}</v-btn>
                <v-card class="import-dialog">
                  <v-card-title>
                    <v-icon color="secondary">bluetooth</v-icon>
                    <span>&ensp;</span>
                    <span class="secondary--text">
                      <strong>Kontakt.io Beacon Import</strong>
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <v-form v-model="valid">
                      <v-layout row>
                        <v-flex xs4>
                            <v-btn outline block color="info" @click="onPick">{{ $t('common.csv') }}</v-btn>
                            <input type="file" ref="csv" accept="text/csv" @change="onChange">
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              :placeholder="$t('items.owner')"
                              :items="owners"
                              item-text="name"
                              item-value="id"
                              v-model="client_id"
                              style="padding: 8px 16px"></v-select>
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              placeholder="MODEL"
                              :items="models"
                              v-model="model"
                              :rules="[
                                v => !!v || $t('rules.required')
                              ]"
                              style="padding: 8px 16px"></v-select>
                          </v-flex>
                      </v-layout>
                    </v-form>
                    <br />
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      hide-actions
                      style="border: 2px solid #dddddd"
                    >
                      <template slot="items" slot-scope="props">
                        <td>{{ props.item.id }}</td>
                        <!-- <td>{{ props.item.client_id }}</td> -->
                        <td>{{ props.item.name }}</td>
                        <td>{{ props.item.uuid }}</td>
                        <td>{{ props.item.major }}</td>
                        <td>{{ props.item.minor }}</td>
                        <td>{{ props.item.tx_power }}</td>
                        <td>{{ props.item.local_name }}</td>
                        <td>{{ props.item.module_id }}</td>
                        <!-- <td>{{ props.item.model }}</td>
                        <td>{{ props.item.manufacturer }}</td>
                        <td>{{ props.item.note }}</td>
                        <td>{{ props.item.enabled }}</td>
                        <td>{{ props.item.created }}</td>
                        <td>{{ props.item.updated }}</td>
                        <td>{{ props.item.deleted }}</td> -->
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn flat color="secondary" @click="onClose">{{ $t('common.close') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn flat color="info" :loading="loading" :disabled="!valid || items.length === 0" @click="onImport">{{ $t('common.import') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import CsvMethod from "../../utils/methods/CsvMethod";

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      loading: false,
      owners: [],
      models: ["Standard", "Protect", "Card", "Usb", "Heavy Duty"],
      client_id: "",
      model: "",
      manufacturer: "Kontakt.io",
      headers: [
        { text: "ID", sortable: false },
        // { text: 'CLIENT ID', sortable: false },
        { text: "NAME", sortable: false },
        { text: "UUID", sortable: false },
        { text: "MAJOR", sortable: false },
        { text: "MINOR", sortable: false },
        { text: "TX POWER", sortable: false },
        // { text: 'BATTERY', sortable: false },
        { text: "LOCAL NAME", sortable: false },
        { text: "MODULE ID", sortable: false }
        // { text: 'MODEL', sortable: false },
        // { text: 'MANUFACTURER', sortable: false },
        // { text: 'NOTE', sortable: false },
        // { text: 'ステータス', sortable: false },
        // { text: 'CREATED', sortable: false },
        // { text: 'UPDATED', sortable: false },
        // { text: 'DELETED', sortable: false }
      ],
      items: []
    };
  },
  mounted() {
    this.onFetchOwners();
  },
  methods: {
    ...mapActions(["onNotify"]),
    onFetchOwners() {
      axios
        .get("/clients", {
          params: {
            per_page: 100,
            page: 1
          }
        })
        .then(response => {
          if (response.data.code === 200) {
            this.owners = response.data.payload.hits;
          } else {
          }
        });
    },
    onImport() {
      this.loading = true;
      Object.keys(this.items).forEach(i => {
        this.items[i].client_id = this.client_id;
        this.items[i].model = this.model;
      });
      axios.post("/beacons/bulk", this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.dialog = false;
            this.$router.push("/beacons");
            this.onNotify({
              type: "success",
              text: "Importing Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  },
  mixins: [CsvMethod]
};
</script>

<style>
.import-card {
  height: 100%;
}
.import-card > div {
  margin: auto;
}
.import-card dt {
  font-size: 1.1rem;
  font-weight: bold;
}
.import-dialog input[type="file"] {
  display: none;
}
</style>
