var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("apps")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('title.app')))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"autofocus":"","rules":[
                    v => !!v || _vm.$t('rules.required'),
                    // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.plan')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.items.plan),callback:function ($$v) {_vm.$set(_vm.items, "plan", $$v)},expression:"items.plan"}},_vm._l((_vm.plan),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.value,"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),1),_c('p',{staticClass:"caption grey--text pt-2 mb-0"},[_vm._v(_vm._s(_vm.$t('discriptions.plan')))])],1)],1),_c('v-layout',{attrs:{"row":"","pb-3":""}},[_c('v-flex',{attrs:{"xs6":"","offset-xs2":""}},[_c('plan-descriptions',{attrs:{"active":_vm.items.plan}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("BUNDLE ID ( iOS )")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"prepend-icon":"phone_iphone","placeholder":"jp.example.app.ios","rules":[
                    v => !v || /^[a-zA-Z0-9-_.]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.bundle_id.ios),callback:function ($$v) {_vm.$set(_vm.items.bundle_id, "ios", $$v)},expression:"items.bundle_id.ios"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("BUNDLE ID ( Android )")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"prepend-icon":"phone_android","placeholder":"jp.example.app.android","rules":[
                    v => !v || /^[a-zA-Z0-9-_.]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.bundle_id.android),callback:function ($$v) {_vm.$set(_vm.items.bundle_id, "android", $$v)},expression:"items.bundle_id.android"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("BUNDLE ID")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",model:{value:(_vm.items.bundle_id.bundle_id),callback:function ($$v) {_vm.$set(_vm.items.bundle_id, "bundle_id", $$v)},expression:"items.bundle_id.bundle_id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.timer')))])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-menu',{ref:"menu01",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.items.timer_date,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.items, "timer_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.items, "timer_date", $event)}},model:{value:(_vm.menu01),callback:function ($$v) {_vm.menu01=$$v},expression:"menu01"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"slot":"activator","prepend-icon":"event","rules":[
                      v => !!v || _vm.$t('rules.required'),
                    ],"readonly":""},slot:"activator",model:{value:(_vm.items.timer_date),callback:function ($$v) {_vm.$set(_vm.items, "timer_date", $$v)},expression:"items.timer_date"}}),_c('v-date-picker',{model:{value:(_vm.items.timer_date),callback:function ($$v) {_vm.$set(_vm.items, "timer_date", $$v)},expression:"items.timer_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){_vm.menu01 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu01.save(_vm.items.timer_date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-menu',{ref:"menu02",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.items.timer_time,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.items, "timer_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.items, "timer_time", $event)}},model:{value:(_vm.menu02),callback:function ($$v) {_vm.menu02=$$v},expression:"menu02"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"slot":"activator","prepend-icon":"access_time","rules":[
                      v => !!v || _vm.$t('rules.required'),
                    ],"readonly":""},slot:"activator",model:{value:(_vm.items.timer_time),callback:function ($$v) {_vm.$set(_vm.items, "timer_time", $$v)},expression:"items.timer_time"}}),_c('v-time-picker',{on:{"change":function($event){return _vm.$refs.menu02.save(_vm.items.timer_time)}},model:{value:(_vm.items.timer_time),callback:function ($$v) {_vm.$set(_vm.items, "timer_time", $$v)},expression:"items.timer_time"}})],1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.image')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:[
                    'image-box',
                    _vm.error ? 'size' : ''
                  ],on:{"click":function($event){$event.stopPropagation();return _vm.onPickFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDropFile.apply(null, arguments)}}},[(!_vm.image)?_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm.$t('discriptions.image')))]):_vm._e(),_c('img',{staticClass:"image small",attrs:{"src":_vm.image,"alt":""}}),_c('br'),(_vm.image)?_c('v-btn',{attrs:{"outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveFile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.remove'))+" ")]):_vm._e(),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onChangeFile}})],1),(_vm.error)?_c('span',{staticClass:"caption error--text"},[_vm._v(_vm._s(_vm.message))]):_vm._e()])],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }