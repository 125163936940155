<template>
  <v-data-iterator
    content-tag="v-layout"
    row
    wrap
    :items="items"
    hide-actions
  >
    <v-flex
      slot="item"
      slot-scope="props"
      :class="active === props.item.name ? 'active' : ''"
    >
      <v-card>
        <v-card-title>
          <h4>{{ props.item.name }}</h4>
          <v-spacer></v-spacer>
          <span class="caption">{{ props.item.min }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-tile>
            <v-list-tile-content>Beacon:</v-list-tile-content>
            <v-list-tile-content class="align-end">{{ props.item.beacon }}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Log:</v-list-tile-content>
            <v-list-tile-content class="align-end">{{ props.item.log }}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Price:</v-list-tile-content>
            <v-list-tile-content class="align-end">{{ props.item.price }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-data-iterator>
</template>

<script>
export default {
  props: ["active"],
  data() {
    return {
      items: [
        {
          name: "Startup",
          min: "Min 3 mo",
          beacon: "Up to 10",
          log: "Up to 150 K / mo",
          price: "48,000 JPY / mo"
        },
        {
          name: "Standard",
          min: "Min 3 mo",
          beacon: "Unlimited",
          log: "Up to 3.6 M / mo",
          price: "80,000 JPY / mo"
        },
        {
          name: "Premium",
          min: "Min 3 mo",
          beacon: "Unlimited",
          log: "Up to 10 M / mo",
          price: "200,000 JPY / mo"
        }
      ]
    };
  }
};
</script>

<style scoped>
.active {
  border: 2px solid #00897b;
}
</style>
