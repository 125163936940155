<template>
  <v-app class="default-template">
    <v-navigation-drawer app fixed dark class="blue-grey darken-4" v-model="drawer">
      <v-layout column align-center white--text pa-3>
        <div class="navigation-logo">
          <img src="../assets/logo.svg" alt :style="!is_console?'':'display: none;'"/>
          <img src="../assets/logo_console.svg" alt :style="is_console?'':'display: none;'"/>
        </div>
        <div>{{ user.name }}</div>
        <div>{{ user.email }}</div>
        <div>
          <strong>{{ $t(`role.${user.role}`) }}</strong>
        </div>
      </v-layout>
      <v-divider></v-divider>
      <v-list>
        <template v-for="item in getMenu()">
          <v-subheader v-if="item.header" :key="item.header">{{ $t('menu.header') }}</v-subheader>
          <v-list-tile v-else-if="!item.header && !(item.action === 'smartphone' && user.device_total === 0)" :key="item.action" @click="redirect(item.path)">
            <v-list-tile-action>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t(`menu.${item.title}`) }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar app fixed dark :color="header_color">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title>
        <strong>{{ $t(`menu.${$route.meta.page}`) }}</strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y left :close-on-content-click="false" :nudge-width="200">
        <v-btn icon slot="activator">
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile>
            <v-list-tile-avatar>
              <img :src="user.image" alt v-if="user.image" />
              <img src="../assets/user.png" alt v-else />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ user.name }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ user.email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile @click.stop="onLogout">
            <v-list-tile-content>{{ $t('logout') }}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-content>
      <v-container fluid grid-list-lg>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div>&copy; {{ $t('copyright') }}</div>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import menu from "../props/menu";
import { mapState } from "vuex";

export default {
  data() {
    var is_console = window.location.host.indexOf('console.') !== -1;
    const favicon = document.getElementById("favicon");
    favicon.href = is_console ? 'favicon_console.ico' : 'favicon.ico';
    return {
      header_color: is_console ? '#3278c9':'#00897b',
      is_console: is_console,
      drawer: true,
      items: [
        { language: "English", locale: "en" },
        { language: "Japanese", locale: "ja" }
      ]
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    goHome() {
      this.$router.push("/home");
    },
    getMenu() {
      return menu(this.user.role);
    },
    onLogout() {
      axios.post("/auth/logout").then(response => {
        if (response.data.code === 200) {
          this.$store.dispatch("onLogout");
          this.$store.dispatch("setUser", {});
          this.$store.dispatch("onNotify", {
            type: "success",
            text: "Logout Successfully!"
          });
          localStorage.removeItem("token")
        } else {
        }
      });
    },
    transform(locale) {
      switch (locale) {
        case "en":
          return "us";
        case "ja":
          return "jp";
      }
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;
    },
    redirect(path) {
      // reset pagination
      localStorage.removeItem("pagination");
      // reset rowsPerPage
      localStorage.removeItem("rowsPerPage");
      // reset search
      this.$store.dispatch("setSearch", {});
      // redirect
      this.$router.push(path);
    }
  }
};
</script>

<style>
.default-template {
  background: #e0e0e0 !important;
}
.navigation-logo {
  padding: 16px 0;
}
.navigation-logo img {
  width: 230px;
}
</style>
