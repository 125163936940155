<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <v-toolbar color="white" class="elevation-1">
          <v-toolbar-title>
            <span class="caption">{{ $t('common.total') }}</span>
            {{ items.length }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outline color="success" @click="dialog_create = true">{{ $t('common.create') }}</v-btn>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <v-list two-line>
              <template v-for="(item, index) in items">
                <v-list-tile
                  :key="item.id"
                  avatar
                  ripple
                  @click="toggleShow(item.id)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-if="item.type === 'app'" class="limit">
                      {{`${item.client_name} ${$t('common.created_app_1')} ${item.app_name}
                      ${$t('common.created_app_2')}` }}
                    </v-list-tile-title>
                    <v-list-tile-title v-else-if="item.type === 'log'" class="limit">
                      {{`${item.app_name} ${$t('common.log_title')}` }}
                    </v-list-tile-title>
                    <v-list-tile-title v-else class="limit">
                      {{item.title }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action class="prevent-wrapping">
                    <v-list-tile-action-text>{{ item.created }}</v-list-tile-action-text>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < items.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
      <announcements-show-dialog v-if="dialog_show" :visible="dialog_show" :selected="selected"
                                 @toggle="dialog_show = false"></announcements-show-dialog>
      <announcements-create-dialog v-if="owners" :visible="dialog_create" :owners="owners"
                                   @toggle="dialog_create = true"
                                   @cancel="dialog_create = false"></announcements-create-dialog>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import AnnouncementsShowDialog from "./AnnouncementsShowDialog";
import AnnouncementsCreateDialog from "./AnnouncementsCreateDialog";

export default {
  components: {
    AnnouncementsShowDialog,
    AnnouncementsCreateDialog
  },
  data() {
    return {
      items: [],
      owners: [],
      selected: null,
      dialog_show: false,
      dialog_create: false
    };
  },
  async mounted() {
    await this.onFetchOwners();
    await this.onFetch();
  },

  methods: {
    toggleShow(id) {
      this.selected = this.items.find(item => item.id === id);
      this.dialog_show = true;
    },

    mapNotification(item) {
      return {
        title: item._source.title,
        body: item._source.body,
        clients: item._source.clients.map(item =>
          this.owners.find(owner => owner.id === item)
        ),
        created: new Date(item._source.created * 1000).toLocaleString(),
        id: item._id
      };
    },

    formatResponse(response) {
      return response.data.payload.hits.map(item => this.mapNotification(item));
    },

    onFetch() {
      axios.get("/notifications/admin?per_page=1000&page=1").then(response => {
        if (response.data.code === 200) {
          const formatResponse = this.formatResponse(response);
          this.items = [...this.items, ...formatResponse];
        } else {
        }
      });
    },
    onFetchOwners() {
      return axios.get("/clients?per_page=100&page=1").then(response => {
        if (response.data.code === 200) {
          this.owners = response.data.payload.hits.map(({ name, id }) => ({
            name,
            id
          }));
        } else {
        }
      });
    }
  }
};
</script>

<style scoped>
.prevent-wrapping {
  min-width: 150px;
}

.limit {
  max-width: 60ch;
}
</style>
