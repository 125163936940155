<template>
  <v-icon
    :color="current ? 'success' : 'red'"
    :class="{current, existing: !current}"
    :style="`left: ${position.left}px; top: ${position.top}px`"
  >my_location</v-icon>
</template>

<script>
export default {
  props: ["position", "current"]
};
</script>

<style>
.current {
  position: absolute;
  animation: flash 1.2s infinite;
}
.existing {
  position: absolute;
}
@keyframes flash {
  50% {
    opacity: 0.2;
  }
}
</style>
