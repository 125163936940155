<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <v-btn
      outline
      color="info"
      slot="activator"
      @click.prevent="onOpenDialog"
    >{{ $t('common.structure') }}</v-btn>
    <v-card tile>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.stop="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('common.structure') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outline dark :loading="loading" @click.stop="onSave">{{ $t('common.save') }}</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md>
          <!-- Clusters -->
          <v-layout row>
            <v-flex xs6 class="draggable-wrap">
              <draggable
                :list="clusters"
                :class="['draggable-area', clusters.length > 0 ? '' : 'no-data']"
                :options="{ group: 'clusters', animation: 300 }"
              >
                <v-list v-for="(item, i) in clusters" :key="i" class="draggable-item">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon large>layers</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ item.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <v-icon>label</v-icon>
                        <span class="caption">{{ item.tag }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </draggable>
            </v-flex>
            <v-icon x-large>keyboard_arrow_right</v-icon>
            <v-flex xs6 class="draggable-wrap">
              <draggable
                :list="items.clusters"
                class="draggable-area destination"
                :options="{ group: 'clusters', animation: 300 }"
              >
                <v-list
                  v-for="(item, i) in items.clusters"
                  :key="i"
                  class="draggable-item"
                  :id="item.id"
                >
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon large>layers</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ item.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <v-icon>label</v-icon>
                        <span class="caption">{{ item.tag }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <cluster-setting
                      :items="item"
                      :actions="actions"
                      v-if="item.beacons.length > 0"
                    ></cluster-setting>
                  </v-list-tile>
                  <!-- Nested Clusters -->
                  <v-list-tile v-for="(item, i) in item.nested" :key="i" class="draggable-item">
                    <v-list-tile-action>
                      <v-icon large>layers</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ item.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <v-icon>label</v-icon>
                        <span class="caption">{{ item.tag }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <cluster-setting
                      :items="item"
                      :actions="actions"
                      v-if="item.beacons.length > 0"
                    ></cluster-setting>
                  </v-list-tile>
                </v-list>
              </draggable>
            </v-flex>
          </v-layout>
          <!-- Regions -->
          <v-layout row>
            <v-flex xs6 class="draggable-wrap">
              <draggable
                :list="regions"
                :class="['draggable-area', regions.length > 0 ? '' : 'no-data']"
                :options="{ group: 'regions', animation: 300 }"
                :move="onMoveCallback"
              >
                <v-list v-for="(item, i) in regions" :key="i" class="draggable-item">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon large>place</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ item.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <v-icon>label</v-icon>
                        <span class="caption">{{ item.type }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </draggable>
            </v-flex>
            <v-icon x-large>keyboard_arrow_right</v-icon>
            <v-flex xs6 class="draggable-wrap">
              <draggable
                :list="items.regions"
                class="draggable-area destination"
                :options="{ group: 'regions', animation: 300 }"
                @add="onCount"
                @remove="onCount"
              >
                <v-list v-for="(item, i) in items.regions" :key="i" class="draggable-item">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon large>place</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <strong>{{ item.name }}</strong>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>
                        <v-icon>label</v-icon>
                        <span class="caption">{{ item.type }}</span>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <region-setting :items="item" :actions="actions"></region-setting>
                  </v-list-tile>
                </v-list>
              </draggable>
              <p
                class="caption error--text pt-2"
                style="position: absolute;"
                v-if="caption"
              >* The number of selected regions reached {{ max }} pieces.</p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import { mapActions } from "vuex";
import ClusterSetting from "./ClusterSetting";
import RegionSetting from "./RegionSetting";

export default {
  props: ["id", "items", "clusters", "regions", "actions"],
  data() {
    return {
      dialog: false,
      loading: false,
      max: 20,
      caption: false
    };
  },
  components: {
    draggable,
    ClusterSetting,
    RegionSetting
  },
  methods: {
    ...mapActions(["onNotify"]),
    onOpenDialog() {
      this.$emit("onOpenDialog", this.id);
    },
    onMoveCallback(e) {
      if (e.to.childElementCount < this.max) {
        return true;
      }
      return false;
    },
    onCount(e) {
      if (e.target.childElementCount < this.max) {
        this.caption = false;
      } else {
        this.caption = true;
      }
    },
    onSave() {
      this.loading = true;
      axios
        .put("/structure/" + this.id, {
          structure: this.items
        })
        .then(response => {
          if (response.data.code === 200) {
            setTimeout(() => {
              this.loading = false;
              this.dialog = false;
              this.onNotify({
                type: "success",
                text: this.$t('common.saved')
              });
            }, 1000);
          } else {
          }
        });
    }
  }
};
</script>

<style>
</style>
