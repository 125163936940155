<template>
  <div>
    <v-tooltip bottom>
      <v-btn icon slot="activator" @click.stop="onOpen">
        <v-icon>filter_list</v-icon>
      </v-btn>
      <span>{{ $t("common.search_operations") }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="650">
      <v-card>
        <v-card-title>
          <v-icon color="secondary">search</v-icon>
          <span>&ensp;</span>
          <h4>{{ $t("common.search_operations") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-layout row>
            <v-flex xs2 class="text-xs-right grey--text pt-2 pr-3">
              <strong>{{ $t("items.location_owner") }}</strong>
            </v-flex>
            <v-flex xs10>
              <v-select
                :items="companies"
                item-text="name"
                item-value="id"
                v-model="search.client_id"
                class="pt-0"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="secondary" @click.stop="onClear">{{
            $t("common.clear")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.stop="onFetch">{{
            $t("common.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ColorFilter from "../../utils/filters/ColorFilter";
import ClusterMethod from "../../utils/methods/ClusterMethod.vue";
import Api from "../../api/lmp";

export default {
    props: ['onSearch'],
  data() {
    return {
      dialog: false,
      search: {
          client_id: ''
      },
      companies: []
    };
  },
  mounted() {
      Api.get('/company').then(response => {
          this.companies = response.data;
      })
  },
  methods: {
    onOpen() {
      this.dialog = true;
    },
    onClear() {
      this.search = {
          client_id: ""
      };
    },
    onFetch() {
      this.dialog = false;
      this.onSearch(this.search.client_id);
    }
  },
  mixins: [ColorFilter, ClusterMethod]
};
</script>

<style></style>
