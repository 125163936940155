<template>
  <v-card>
    <v-list three-line min-width="100px">
      <template v-for="(item, index) in logs">
        <v-list-tile
          :key="`${item.name}${index}`"
          avatar
          ripple
        >
          <v-list-tile-content class="font-weight-light">
            <v-list-tile-title class="teal--text text--darken-2">{{ item.name }}
            </v-list-tile-title>
            <v-list-tile-sub-title class="text--primary">合計：
              {{ item.total }}
            </v-list-tile-sub-title>
            <v-list-tile-sub-title>前日：
              {{ item.yesterday_count }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider
          v-if="index + 1 < logs.length"
          :key="index"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: ["logs"]
};
</script>

<style scoped>
.v-list__tile__content {
  overflow: visible;
}
</style>
