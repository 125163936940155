<template>
  <div>
    <v-layout row wrap py-2>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <v-icon color="secondary">place</v-icon>
            <span>&ensp;</span>
            <h3>{{ $t('title.region') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>ID</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="items.id"
                    disabled
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.name') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    autofocus
                    v-model="items.name"
                    :rules="[
                      v => !!v || $t('rules.required'),
                      v => /^[一-龠_]+|[ぁ-ゔ_]+|[ァ-ヴー_]+|[a-zA-Z0-9_]+|[ａ-ｚＡ-Ｚ０-９_]+[々〆〤_]+ $/.test(v) || $t('rules.unusable'),
                      v => v.length <= 45 || $t('rules.max45c')
                    ]"
                    counter="45"
                    class="pt-0"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="pb-4">
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong><span class="red--text">*</span>{{ $t('items.type') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-btn-toggle mandatory v-model="items.type">
                    <v-btn
                      v-for="(item, i) in type"
                      :key="i"
                      :value="item.value"
                      color="primary"
                      class="white--text"
                    >
                      <span>{{ item.text }}</span>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <p class="caption grey--text pt-2 mb-0">{{ $t('discriptions.region_type') }}</p>
                </v-flex>
              </v-layout>
              <template v-if="items.type === 'Beacon'">
                <v-layout row>
                  <v-flex xs2 class="text-xs-right grey--text pt-3">
                    <strong><span class="red--text">*</span>UUID</strong>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="items.uuid"
                      placeholder="########-####-####-####-############"
                      :rules="[
                        v => !!v || $t('rules.required'),
                        v => /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[0-5][a-fA-F0-9]{3}-[089aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/.test(v) || $t('rules.format')
                      ]"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2 class="text-xs-right grey--text pt-3">
                    <strong>MAJOR / MINOR</strong>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model="items.major"
                      :rules="[
                        v => v >= 0 || $t('rules.min0n'),
                        v => v <= 65535 || $t('rules.max65535n')
                      ]"
                      min="0"
                      max="65535"
                      :hint="$t('discriptions.major_minor')"
                      persistent-hint
                      type="number"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model="items.minor"
                      :rules="[
                        v => v >= 0 || $t('rules.min0n'),
                        v => v <= 65535 || $t('rules.max65535n')
                      ]"
                      min="0"
                      max="65535"
                      :hint="$t('discriptions.major_minor')"
                      persistent-hint
                      type="number"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <template v-if="items.type === 'Geofence'">
                <v-layout row>
                  <v-flex xs2 class="text-xs-right grey--text pt-3">
                    <strong><span class="red--text">*</span>{{ $t('items.location') }}</strong>
                  </v-flex>
                  <v-flex xs10 class="grey--text pt-3">
                    <strong>{{ $t('items.latitude') }} / {{ $t('items.longitude') }} / {{ $t('items.radius') }}</strong>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs2 offset-xs2>
                    <v-text-field
                      v-model="items.latitude"
                      :rules="[
                        v => !!v || $t('rules.required')
                      ]"
                      type="number"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="items.longitude"
                      :rules="[
                        v => !!v || $t('rules.required')
                      ]"
                      type="number"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      v-model="items.radius"
                      :rules="[
                        v => !!v || $t('rules.required')
                      ]"
                      type="number"
                      class="pt-0"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs6 offset-xs2>
                    <google-map
                      name="region"
                      v-on:getLatLng="getLatLng"
                      :latitude="items.latitude"
                      :longitude="items.longitude"
                      :radius="items.radius"
                    ></google-map>
                  </v-flex>
                  <v-flex xs2>
                    <div class="caption-box">
                      <h3>{{ $t('regioncaption.title') }}</h3>
                      <h4>{{ $t('regioncaption.subtitle01') }}</h4>
                      <p v-html="$t('regioncaption.subtext01')"></p>
                      <h4>{{ $t('regioncaption.subtitle02') }}</h4>
                      <p v-html="$t('regioncaption.subtext02')"></p>
                    </div>
                  </v-flex>
                </v-layout>
              </template>
              <v-layout row>
                <v-flex xs2 class="text-xs-right grey--text pt-3">
                  <strong>{{ $t('items.status') }}</strong>
                </v-flex>
                <v-flex xs6>
                  <v-checkbox v-model="items.enabled" disabled></v-checkbox>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn outline color="secondary" @click.stop="$router.go(-1)">{{ $t('common.back') }}</v-btn>
            <v-spacer></v-spacer>
            <save-confirmation
              v-on:onSave="onSave"
              :valid="valid"
              :loading="loading"
            ></save-confirmation>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import uuid from "uuid";
import { mapActions } from "vuex";
import GoogleMap from "../../components/GoogleMap";
import SaveConfirmation from "../../components/dialogs/SaveConfirmation";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      type: [
        { icon: "bluetooth", value: "Beacon", text: "beacon" },
        { icon: "place", value: "Geofence", text: "geofence" }
      ],
      items: {
        id: uuid(),
        client_id: "",
        name: "",
        type: "Beacon",
        uuid: "",
        major: null,
        minor: null,
        latitude: 35.644288,
        longitude: 139.699096,
        radius: 500,
        enabled: true
      }
    };
  },
  components: {
    GoogleMap,
    SaveConfirmation
  },
  methods: {
    ...mapActions(["onNotify"]),
    getLatLng(latLng) {
      this.items.latitude = latLng.lat();
      this.items.longitude = latLng.lng();
    },
    onSave() {
      this.loading = true;
      let path = this.$route.path.replace(/\/new/g, "");
      axios.post(path, this.items).then(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            this.loading = false;
            this.$router.go(-1);
            this.onNotify({
              type: "success",
              text: "Creating Successfully!"
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style>
</style>
