<template>
  <div>
    <v-layout style="padding: 50px;">
      <v-flex xs6 md6>
        <table>
          <thead>
          <tr>
            <th @click="sortBy('name')">
              {{ $t(`summary.beacon_name`) }}
              <span class="arrow" :class="sortOrders['name'] > 0 ? 'asc' : 'dsc'">
          </span>
            </th>
            <th @click="sortBy('arrived')">
              {{ $t(`summary.arrived`) }}
              <span class="arrow" :class="sortOrders['arrived'] > 0 ? 'asc' : 'dsc'">
          </span>
            </th>
            <th @click="sortBy('battery')">
              {{ $t(`summary.battery`) }}
              <span class="arrow" :class="sortOrders['battery'] > 0 ? 'asc' : 'dsc'">
          </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in battery_logs" @click="activate(entry.name)" :class="{ active : active_element === entry.name }">
            <td v-for="key in columns">
              <p style="margin-bottom: 0" v-if="key!=='battery' && entry[key] !== ''">{{entry[key]}}</p>
              <p style="margin-bottom: 0" v-else-if="key!=='battery' && entry[key] === ''">ー</p>
              <p style="margin-bottom: 0" v-else-if="entry[key] >= 30">{{entry[key]}}%</p>
              <p style="margin-bottom: 0; color: red" v-else-if="entry[key] > 0">{{entry[key]}}%</p>
              <p style="margin-bottom: 0;" v-else>ー</p>
            </td>
          </tr>
          </tbody>
        </table>
      </v-flex>
      <v-flex xs6 md6 style="position: relative;">
        <battery-pointer
          v-for="beacon in battery_logs"
          ref="picture"
          :key="beacon.name"
          :position="{left: beacon.x * map_scale -12 , top: beacon.y * map_scale -12}"
          :battery="beacon.battery"
          :name="beacon.name"
          :active_element="active_element"
        ></battery-pointer>
        <img
          v-if="image"
          ref="current_image"
          v-bind:src="image"
          class="grey lighten-2"
          style="display: block; width:100%;"
          @load="uiImageSize"
        />
        <img src="../../assets/noimage.png" alt v-else style="display: block; width:100%;">
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import BatteryPointer from "./BatteryPointer";
  export default {
    components: {BatteryPointer},
    props: ["selected_cluster", "clusters", "series"],
    data: function () {
      return {
        image: this.clusters.find(item => item.cluster_id === this.selected_cluster)['image'],
        battery_logs: this.series,
        columns: ['name', 'arrived', 'battery'],
        sortOrders: {name: 1, arrived: 1, battery: 1},
        width: 0,
        height: 0,
        natural_width: 0,
        natural_height: 0,
        loco_width: 0,
        loco_height: 0,
        active_element:0,
        map_scale: 1,
      };
    },

    methods: {
      sortBy(prop) {
        this.sortOrders[prop] = this.sortOrders[prop] * -1;
        this.battery_logs.sort((a, b) => (a[prop] === b[prop] ? 0 : a[prop] > b[prop] ? 1 : -1) * this.sortOrders[prop]);
      },

      uiImageSize() {
        const loaded = true;
        // current width and height of pictures - add 2 if use a border of 1px on each side
        this.width = this.$refs.current_image.clientWidth+ 2;
        this.height = this.$refs.current_image.clientHeight + 2;
        this.map_scale = 800 / this.$refs.current_image.naturalWidth * (this.$refs.current_image.clientWidth / 800)
      },

      activate:function(element){
        this.active_element = element;
      }
    }
  }
</script>

<style scoped>
  body {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    color: #444;
  }

  table {
    border: 2px solid #00897b;
    border-radius: 3px;
    background-color: #fff;
  }

  th {
    background-color: #00897b;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .active {
    background-color: #AED581;
  }

  th, td {
    min-width: 130px;
    padding: 10px 10px;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

  .arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
  }

  .arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }

</style>



