<template>
  <v-card>
    <template>
      <v-tabs
        class="tabs"
        color="teal"
        dark
        show-arrows
        center-active
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          v-for="(item, index) in initialData"
          :key="`${item.app_name}${index}`"
          :initialData="initialData"
          @change="$emit('changeApp', initialData, index)"
        >
          <v-layout column class="remove-layout-margin">
            <v-flex class="tab_content">{{ item.app_name }}</v-flex>
            <v-flex class="tab_content">
              <span v-if="item.logs.total * 100 / item.maximum < 90">{{ item.logs.total }}</span>
              <span v-else class="yellow--text text--accent-1">{{ item.logs.total }}</span>
              <span v-if="showTotal" class="tab_content_number"> / {{ item.maximum }}</span>
            </v-flex>
          </v-layout>
        </v-tab>
        <v-tabs-items>
          <v-card flat>
            <v-card-text class="centered-flex">
              <bar-chart v-if="loaded && initialData.length !== 0" :styles="myStyles" :chartData="datacollection"
                         :options="options"></bar-chart>
              <v-progress-circular
                  v-else-if="!loaded"
                  indeterminate
                  color="green"
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-tabs>
    </template>
  </v-card>
</template>

<script>
import BarChart from "./BarChart";

export default {
  props: ["initialData", "loaded", "options", "datacollection", "height", "showTotal"],
  components: {
    BarChart
  },
  computed: {
    myStyles() {
      return {
        // height: 360px + 32px padding + 68px tab-header = 460px => equal height of list component
        width: "100%",
        height: `360px`,
        position: "relative"
      };
    }
  }
};
</script>

<style>
  /* handle tabs height issue on production */
  .tabs .v-tabs__container {
    height: 68px;
  }

  .centered-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tabs .v-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
