<template>
  <v-layout row wrap>
    <v-flex xs3 style="min-width: 185px">
      <graph-list :logs="logs"></graph-list>
    </v-flex>
    <v-flex xs9>
      <graph-chart :initialData="initialData" @changeApp="fillData" :loaded="loaded" :options="options"
                   :datacollection="datacollection" :showTotal="false"></graph-chart>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { DarkTwo3 } from "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer";
import moment from "moment";
import GraphList from "./GraphList";
import GraphChart from "./GraphChart";

export default {
  components: {
    GraphList,
    GraphChart
  },
  props: ["selectedMonth"],
  watch: {
    selectedMonth(newVal, oldVal) {
      // Watcher for the 'data' prop
      if (newVal !== oldVal) {
        this.loaded = false;
        this.fetchGraphData()
      }
    },
  },
  data: function() {
    return {
      index: 0,
      initialData: null,
      dataText: null,
      datacollection: null, // keep datacollection null and not [] or changes wont be catched
      options: null,
      scheme: DarkTwo3,
      labels: [],
      logs : [
        { name: "unique device logs", total: 0, yesterday_count: 0 }
      ],
      loaded: false
    };
  },
  mounted() {
    this.fetchGraphData();
  },

  methods: {
    fetchGraphData() {
      const month = this.selectedMonth.format('MM')
      const year = this.selectedMonth.format('YYYY');

      let range = {}
      range.start = this.getMonthDateRange(year,month).year + "-" + this.getMonthDateRange(year,month).month+ "-" + this.getMonthDateRange(year,month).start;
      range.end =this.getMonthDateRange(year,month).year + "-" + this.getMonthDateRange(year,month).month+ "-" + this.getMonthDateRange(year,month).end;

      axios.get("/unique_device_logs", {
        params: {
          start_date: range.start,
          end_date: range.end
        }
      }).then(response => {
        this.initialData = response.data;
        console.log(response.data);
        if(response.data.length === 0) this.loaded = true;
        this.labels = response.data[0].logs.results.map(
            item => item.key_as_string
        );
        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                type: "time",
                gridLines: {
                  display: false
                },
                ticks: {
                  source: "data",
                  fontColor: "#4DB6AC",
                  major: {
                    fontColor: "#00796B",
                    fontStyle: "bold"
                  },
                  callback: function(value, index, values) {
                    // the callback will select days which are a saturday or a sunday
                    values[index].major =
                        moment(values[index].value).isoWeekday() === 7 ||
                        moment(values[index].value).isoWeekday() === 6;
                    return value;
                  }
                },
                time: {
                  unit: "day",
                  min: this.labels[0],
                  max: this.labels[this.labels.length - 1],
                  displayFormats: {
                    day: "D"
                  }
                }
              }
            ]
          },
          plugins: {
            colorschemes: {
              scheme: "brewer.DarkTwo3"
            }
          }
        };
        this.dataText = response.data.map(app => {
          return {
            app_name: app.app_name,
            total: app.logs.total,
            max: app.maximum,
            // if first day of the month return 0 instead of [app.logs.results.length - 2]. Since count($aggregate) return 1, count($aggregate) - 2 will return an offset error
            day_before: app.logs.results.length > 1 ? app.logs.results[app.logs.results.length - 2].doc_count : app.logs.results[0].doc_count
          };
        });
        // all elements have the same labels. so we can use response.data[0]
        this.fillData(response.data, this.index);
        this.loaded = true;
      });
    },
    fillData(data, index) {
      this.index = index;
      this.logs = [
        { name: "unique device logs", ...data[index].unique_device_logs },
      ];

      this.datacollection = {
        labels: this.labels,
        datasets: [
          {
            type: "line",
            data: data[index].logs.results.map(item => item.sum.value),
            fill: "transparent",
            borderWidth: 1,
            cubicInterpolationMode: "monotone"
          },
          {
            type: "bar",
            data: data[index].logs.results.map(item => item.doc_count),
            backgroundColor: "#B2DFDB"
          }
        ]
      };
    },
    getMonthDateRange(year, month)
    {
      // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
      // array is 'year', 'month', 'day', etc
      var startDate = moment([year, month - 1]);
      var endDate = moment(startDate).endOf('month');
      return { start: startDate.format("DD"), end: endDate.format("DD"), year:year, month:month};
    }
  }
};
</script>

<style>
.remove-layout-margin {
  height: 100%;
  font-size: 14px;
  text-transform: capitalize;
}

.remove-layout-margin:first-child {
  margin-top: 0 !important;
}

.remove-layout-margin:last-child {
  margin-bottom: 0 !important;
}

.v-tabs__container {
  height: 68px;
}

.tab_content {
  padding: 4px 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab_content_number {
  font-weight: 300;
}
</style>
