export default role => {
  let setting;

  switch (role) {
    case "Administrator":
      setting = [
        { icon: "person", text: "user", component: "UserCard" },
        { icon: "security", text: "security", component: "SecurityCard" },
        { icon: "bluetooth", text: "import", component: "ImportCard" }
      ];
      break;
    case "Manager":
      setting = [
        { icon: "public", text: "client", component: "ClientCard" },
        { icon: "person", text: "user", component: "UserCard" },
        { icon: "security", text: "security", component: "SecurityCard" },
        { icon: "thumb_up", text: "support", component: "SupportCard" }
      ];
      break;
    case "General":
      setting = [
        { icon: "person", text: "user", component: "UserCard" },
        { icon: "thumb_up", text: "support", component: "SupportCard" }
      ];
      break;
  }
  return setting;
};
