<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar color="white" class="elevation-1">
          <span class="name">{{ company.name }} 様</span>
        </v-toolbar>
      </v-flex>
    </v-layout>

    <v-card>
      <v-card-text>
        <v-flex xs12>
          <span>ビークルー受領金額: {{company.location.reduce((t,x) => t+x.usage_fee*x.royalty*(x.application ? x.application.length : 0), 0) | toCurrency}}</span>
        </v-flex>
        <br/>
        <v-flex xs12>
          <h2>ロケーションの受領金額</h2>
          <span>合計金額: {{company.location.reduce((t,x) => t+x.usage_fee*(x.application ? x.application.length : 0), 0) | toCurrency}}</span>
        </v-flex>
      </v-card-text>
    </v-card>

    <v-layout row>
      <v-flex xs12>
        <v-card
          v-for="(location, index) in company.location" :key="location.id"
          class="list-card"
          @click.stop="dialog = index"
        >
          <card-location :item="location" :application="location.application" type="receipt"></card-location>
        </v-card>
      </v-flex>
    </v-layout>
    <royalty-setting v-if="dialog !== null" :onCancel="cancel" :submit="onSubmit" :item="company.location[dialog]"></royalty-setting>
  </div>
</template>

<script>
    import LocationSearch from "../../components/search/LocationsSearchOperations";
    import CardLocation from "../../components/cards/CardLocation";
    import Api, {ApiHelper} from "../../api/lmp";
    import RoyaltySetting from "../../components/locations/RoyaltySetting";

    export default {
        components: {
            RoyaltySetting,
            LocationSearch,
            CardLocation
        },
        mounted() {
            this.onFetch();
        },
        data() {
            return {
                company: {location: []},
                dialog: null
            };
        },
        methods: {
            onFetch() {
                let search = this.$store.getters.search;
                const {id} = this.$route.params;
                return Api.get(`/company/${id}?with[]=location&with[]=location.application&with[]=location.photo&filter[]=location.application.active()`)
                    .then(response => {
                        this.company = response.data;
                        this.company.location = this.company.location.map(x => ({...x, company: {name: this.company.name}}));
                    });
            },
            onSubmit(item) {
                return Api.put(`/location/${item.id}`, item)
                    .then(response => {
                        this.company.location[this.dialog].new_royalty = item.new_royalty;
                    });
            },
            cancel() {
                this.dialog = null

            }
        }
    };
</script>

<style scoped>
  .name {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 16px;
  }
</style>
