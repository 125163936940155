<template>
  <div class="google-map" :id="mapName"></div>
</template>

<script>
import GoogleMapApiLoader from "google-maps-api-loader";
import GoogleMapApiKey from "../config/google";

export default {
  props: ["name", "latitude", "longitude", "radius"],
  data() {
    return {
      mapName: this.name + "-map",
      map: null,
      options: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35
      }
    };
  },
  mounted() {
    GoogleMapApiLoader({
      apiKey: GoogleMapApiKey
    }).then(() => {
      this.buildMap();
    });
  },
  watch: {
    latitude() {
      this.buildMap();
    },
    longitude() {
      this.buildMap();
    },
    radius() {
      this.buildMap();
    }
  },
  methods: {
    buildMap() {
      /* eslint-disable no-undef */
      this.map = new google.maps.Map(document.getElementById(this.mapName), {
        zoom: 14,
        center: new google.maps.LatLng(this.latitude, this.longitude)
      });
      /* eslint-disable no-new */
      new google.maps.Marker({
        position: new google.maps.LatLng(this.latitude, this.longitude),
        map: this.map
      });
      new google.maps.Circle(
        Object.assign(this.options, {
          center: new google.maps.LatLng(this.latitude, this.longitude),
          map: this.map,
          radius: parseFloat(this.radius)
        })
      );

      this.map.addListener("click", e => {
        this.$emit("getLatLng", e.latLng);
      });
    }
  }
};
</script>

<style>
.google-map {
  width: 100%;
  height: 350px;
  margin: 0 auto;
}
</style>
