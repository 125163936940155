import router from '../router'

const transportMap = {
    has_boat: 'boat',
    has_airport: 'airport',
    has_bus: 'bus',
    has_railway: 'railway',
    has_road: 'road',
};
const commercialMap = {
    has_amusement: 'amusement',
    has_dept_store: 'dept_store',
    has_parking: 'parking',
    has_food_drink: 'food_drink',
    has_hall_stadium: 'hall_stadium',
    has_grocery: 'grocery',
    has_supermarket: 'supermarket',
};

const totalMap = {...commercialMap, ...transportMap};

export function getType(item) {
    var type = '';
  Object.keys(totalMap).forEach(k => {
        if(k in item && item[k] == 1) {
          type += router.app.$t(`facilities.${totalMap[k]}`) + ' ';
        }
    });
    return type;
}


export function getTypeObject(item) {

    var type = {'commercial': {}, 'transport': {}};

    const addMap = (map, str) => Object.keys(map).forEach(k => {
        if(k in item) {
            type[str][map[k]] = item[k];
        } else {
            type[str][map[k]] = 0;
        }
    });
    addMap(commercialMap, 'commercial');
    addMap(transportMap, 'transport');

    return type;
}
