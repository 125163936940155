var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("public")]),_c('strong',[_vm._v(_vm._s(_vm.$t('subtitle.client')))])],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('items.name'))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-z0-9-]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.loco_share')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{model:{value:(_vm.items.loco_share),callback:function ($$v) {_vm.$set(_vm.items, "loco_share", $$v)},expression:"items.loco_share"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("person")]),_c('strong',[_vm._v(_vm._s(_vm.$t('subtitle.manager')))])],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.user.id),callback:function ($$v) {_vm.$set(_vm.items.user, "id", $$v)},expression:"items.user.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('items.name'))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.user.name),callback:function ($$v) {_vm.$set(_vm.items.user, "name", $$v)},expression:"items.user.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('items.email'))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || _vm.$t('rules.format')
                  ]},model:{value:(_vm.items.user.email),callback:function ($$v) {_vm.$set(_vm.items.user, "email", $$v)},expression:"items.user.email"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('items.password'))+" ")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-zA-Z0-9/\*-.,\!#\$%&\(\)~\|_\+]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length >= 8 || _vm.$t('rules.min8c')
                  ],"append-icon":_vm.visibility ? 'visibility' : 'visibility_off',"append-icon-cb":() => (_vm.visibility = !_vm.visibility),"type":_vm.visibility ? 'password' : 'text',"hint":_vm.$t('discriptions.password'),"persistent-hint":""},model:{value:(_vm.items.user.password),callback:function ($$v) {_vm.$set(_vm.items.user, "password", $$v)},expression:"items.user.password"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.user.enabled),callback:function ($$v) {_vm.$set(_vm.items.user, "enabled", $$v)},expression:"items.user.enabled"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid,"loading":_vm.loading},on:{"onSave":_vm.onSave}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }