<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="1000">
    <v-btn icon slot="activator">
      <v-icon>settings</v-icon>
    </v-btn>
    <v-card>
      <v-card-title>
        <v-icon color="secondary">layers</v-icon>
        <span>&ensp;</span>
        <span class="secondary--text">
          <strong>{{ items.name }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-flex xs12 class="draggable-wrap small">
            <div class="draggable-area destination small">
              <v-list v-for="(item, i) in items.beacons" :key="i" class="draggable-item small">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon>bluetooth</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <strong>{{ item.name }}</strong>
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <beacon-setting :items="item" :actions="actions"></beacon-setting>
                </v-list-tile>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn flat color="secondary" @click.stop="dialog = false">{{ $t('common.close') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn flat color="success" @click.stop="dialog = false">{{ $t('common.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BeaconSetting from "./BeaconSetting";

export default {
  props: ["items", "actions"],
  data() {
    return {
      dialog: false
    };
  },
  components: {
    BeaconSetting
  }
};
</script>

<style>
</style>
