<template>
  <v-layout row justify-center>
    <v-dialog v-model="visible" persistent scrollable no-click-animation lazy max-width="1250">
      <v-card>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                box
                class="mb-3"
                v-model="title"
                :rules="[v => !!v || $t('rules.required')]"
                :label="$t('common.title')"
                :placeholder="$t('common.placeholder_title')"
                required
                @keyup.13="validate"
                ref="title"
                autofocus
              ></v-text-field>

              <v-select
                box
                class="mb-3"
                v-model="selected"
                :label="$t('common.clients')"
                :items="owners"
                item-text="name"
                multiple
                small-chips
                return-object
                :rules="[v => v.length > 0 || $t('rules.required')]"
                :placeholder="$t('common.placeholder_destination')"
              >
                <template v-slot:prepend-item>
                  <v-list-tile
                    ripple
                    @click="toggle"
                  >
                    <v-list-tile-action>
                      <v-checkbox :value="selectAll"></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>{{$t('common.select_all')}}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>

              <v-textarea
                box
                v-model="body"
                ref="body"
                :rules="[v => !!v || $t('rules.required')]"
                :label="$t('common.body')"
                :placeholder="$t('common.placeholder_body')"
                required
              >
              </v-textarea>

              <v-layout row class="px-2" align-center xs6>
                <v-flex xs6>
                  <v-layout row align-center>
                    <v-icon @click="toggleLink">link</v-icon>
                    <v-text-field xs4 v-show="linkTyping" prefix="http://" ref="link" class="mx-3 mt-0"
                                  v-model="linkText"
                                  @keyup.13="insertLink"></v-text-field>
                    <v-btn v-show="linkTyping" small color="primary" flat @click="insertLink">{{ $t('common.add') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6></v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" flat @click="close">{{ $t('common.close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="validate">{{ $t('common.ok') }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <announcements-show-dialog
      :visible="dialog_show"
      :confirmationType="true"
      :selected="confirm_information"
      @toggle="dialog_show = false"
      @cancel="cancelConfirmation"
      @send="send">
    </announcements-show-dialog>

  </v-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import AnnouncementsShowDialog from "./AnnouncementsShowDialog";

export default {
  props: ["visible", "owners"],
  components: {
    AnnouncementsShowDialog
  },
  data: () => ({
    title: null,
    body: "",
    selected: [],
    dialog_show: false,
    valid: false,
    linkText: "",
    linkTyping: false,
    confirm_information: {}
  }),
  watch: {
    visible: function() {
      this.reset();
    }
  },
  computed: {
    ...mapState(["user"]),
    selectAll() {
      return this.selected.length === this.owners.length;
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.selected = [];
        } else {
          this.selected = this.owners.slice();
        }
      });
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.showConfirmation();
      }
    },
    showConfirmation() {
      // toggle back dialog create
      this.$emit("cancel");
      this.dialog_show = true;
      this.confirm_information = {
        title: this.title,
        body: this.body,
        clients: this.selected
      };
    },

    cancelConfirmation() {
      this.dialog_show = false;
      // toggle dialog create
      this.$emit("toggle");
    },
    toggleLink() {
      this.linkTyping = !this.linkTyping;
      this.$nextTick(() => this.$refs.link.focus());
    },
    insertLink() {
      // need the double slash in href to open the link and not append it to the vue-router. it also match http and https
      // https://stackoverflow.com/questions/46113986/open-an-external-url
      if (this.linkText.length) {
        this.body = `${this.body} <a href=http://${
          this.linkText
        } target="_blank">${this.linkText}</a> `;
        this.linkTyping = false;
        this.linkText = "";
        this.$refs.body.focus();
      }
    },
    close() {
      this.$emit("cancel");
      this.dialog_show = false;
      this.$refs.form.reset();
    },
    reset() {
      // need to reset the validation every time the dialog opens to update its translation
      this.$refs.form ? this.$refs.form.resetValidation() : null;
    },
    send() {
      const data = { ...this.confirm_information };
      data.clients = [...this.confirm_information.clients.map(item => item.id)];
      const payload = { ...data, sender: this.user.id };
      axios.post("/notifications", payload).then(response => {
        if (response.data.code === 200) {
          this.close();
        }
      });
    }
  }
};
</script>

<style scoped>
.push-top {
  margin-top: -16px;
}
</style>
