<template>
  <v-app dark class="blue-grey darken-4">
    <v-content>
      <v-container fluid class="center">
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>

export default {
  mounted() {
    var is_console = window.location.host.indexOf('console.') !== -1;
    const favicon = document.getElementById("favicon");
    favicon.href = is_console ? 'favicon_console.ico' : 'favicon.ico';
  }
};
</script>

<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
</style>
