var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","py-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("bluetooth")]),_c('span',[_vm._v(" ")]),_c('h3',[_vm._v(_vm._s(_vm.$t('title.beacon')))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"disabled":""},model:{value:(_vm.items.id),callback:function ($$v) {_vm.$set(_vm.items, "id", $$v)},expression:"items.id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.owner')))])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.owners,"item-text":"name","item-value":"id"},model:{value:(_vm.items.client_id),callback:function ($$v) {_vm.$set(_vm.items, "client_id", $$v)},expression:"items.client_id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[(!_vm.items.mounted)?_c('span',{staticClass:"red--text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.$t("items.app")))])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-autocomplete',{staticClass:"pt-0",attrs:{"items":_vm.apps,"item-text":"name","item-value":"id","disabled":_vm.isAppDisabled,"clearable":"","rules":!_vm.items.mounted ? [
                    v => !!v || _vm.$t('rules.required'),
                  ] : []},model:{value:(_vm.items.app_id),callback:function ($$v) {_vm.$set(_vm.items, "app_id", $$v)},expression:"items.app_id"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.is_mounted')))])]),_c('v-flex',{attrs:{"xs6":""},on:{"click":_vm.onClickMounted}},[_c('v-radio-group',{attrs:{"row":"","disabled":_vm.appBound},model:{value:(_vm.items.mounted),callback:function ($$v) {_vm.$set(_vm.items, "mounted", $$v)},expression:"items.mounted"}},[_c('v-radio',{attrs:{"label":_vm.$t('items.mounted'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('items.movable'),"value":false}})],1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('items.name')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    // v => /^[a-zA-Z0-9-_]+$/.test(v) || $t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("UUID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"########-####-####-####-############","rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[0-5][a-fA-F0-9]{3}-[089aAbB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/.test(v) || _vm.$t('rules.format')
                  ]},model:{value:(_vm.items.uuid),callback:function ($$v) {_vm.$set(_vm.items, "uuid", $$v)},expression:"items.uuid"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("MAJOR / "),_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("MINOR")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || v === 0 || _vm.$t('rules.required'),
                    v => v >= 0 || _vm.$t('rules.min0n'),
                    v => v <= 65535 || _vm.$t('rules.max65535n')
                  ],"min":"0","max":"65535","hint":_vm.$t('discriptions.major_minor'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.major),callback:function ($$v) {_vm.$set(_vm.items, "major", $$v)},expression:"items.major"}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || v === 0 || _vm.$t('rules.required'),
                    v => v >= 0 || _vm.$t('rules.min0n'),
                    v => v <= 65535 || _vm.$t('rules.max65535n')
                  ],"min":"0","max":"65535","hint":_vm.$t('discriptions.major_minor'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.minor),callback:function ($$v) {_vm.$set(_vm.items, "minor", $$v)},expression:"items.minor"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("TX POWER(RSSI at 1m)")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || v === 0 || _vm.$t('rules.required'),
                    v => v >= -255 || _vm.$t('rules.min-255n'),
                    v => v <= 0 || _vm.$t('rules.max0n')
                  ],"min":"-255","max":"0","suffix":"dBm","hint":_vm.$t('discriptions.tx_power'),"persistent-hint":"","type":"number"},model:{value:(_vm.items.tx_power),callback:function ($$v) {_vm.$set(_vm.items, "tx_power", $$v)},expression:"items.tx_power"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("LOCAL NAME")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    // v => /^[a-zA-Z0-9-_\s]+$/.test(v) || $t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.local_name),callback:function ($$v) {_vm.$set(_vm.items, "local_name", $$v)},expression:"items.local_name"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("MODULE ID")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => !!v || _vm.$t('rules.required'),
                    v => /^[a-zA-Z0-9-:]+$/.test(v) || _vm.$t('rules.unusable'),
                    v => v.length <= 45 || _vm.$t('rules.max45c')
                  ],"counter":"45"},model:{value:(_vm.items.module_id),callback:function ($$v) {_vm.$set(_vm.items, "module_id", $$v)},expression:"items.module_id"}})],1)],1),_c('v-layout',{staticClass:"pb-4",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("MODEL")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.items.model),callback:function ($$v) {_vm.$set(_vm.items, "model", $$v)},expression:"items.model"}},_vm._l((_vm.model),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.value,"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)],1),_c('v-layout',{staticClass:"pb-4",attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v("MANUFACTURER")])]),_c('v-flex',{attrs:{"xs3":""}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.items.manufacturer),callback:function ($$v) {_vm.$set(_vm.items, "manufacturer", $$v)},expression:"items.manufacturer"}},_vm._l((_vm.manufacturer),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.value,"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v("NOTE")])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"rules":[
                    v => v.length <= 250 || _vm.$t('rules.max250c')
                  ],"counter":"250","multi-line":"","no-resize":""},model:{value:(_vm.items.note),callback:function ($$v) {_vm.$set(_vm.items, "note", $$v)},expression:"items.note"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.status')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(_vm.items.enabled),callback:function ($$v) {_vm.$set(_vm.items, "enabled", $$v)},expression:"items.enabled"}})],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-right grey--text pt-3",attrs:{"xs2":""}},[_c('strong',[_vm._v(_vm._s(_vm.$t('items.shared')))])]),_c('v-flex',{attrs:{"xs10":""}},[_c('v-btn-toggle',{staticClass:"fold-back",staticStyle:{"flex-wrap":"wrap"},attrs:{"multiple":""},model:{value:(_vm.items.shared_id),callback:function ($$v) {_vm.$set(_vm.items, "shared_id", $$v)},expression:"items.shared_id"}},_vm._l((_vm.owners),function(item,i){return _c('v-btn',{key:i,staticClass:"white--text",attrs:{"value":item.id,"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outline":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.back')))]),_c('v-spacer'),_c('save-confirmation',{attrs:{"valid":_vm.valid && _vm.items.manufacturer && _vm.items.model,"loading":_vm.loading},on:{"onSave":_vm.onSave}}),_c('warning-dialog',{attrs:{"warning":_vm.warning,"message":_vm.message},on:{"okAction":_vm.closeWarning}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }