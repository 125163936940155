<template>
  <div v-if="user.role">
    <div>
      <v-layout>
        <v-flex>
          <div>
            <div class="text-xs-right" v-if=" user.role !== 'Administrator'">
              <v-icon>cloud_download</v-icon>&nbsp;
              <a :href="url" target="_blank" style="text-decoration: none">
                {{ $t('manual.download') }}
              </a>
            </div>
            <announcements-home-dashboard></announcements-home-dashboard>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import AnnouncementsHomeDashboard from "./announcements/AnnouncementsHome";

export default {
  data() {
    return {
      url: ""
    };
  },
  components: {
    AnnouncementsHomeDashboard
  },
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    this.onFetch();
  },
  methods: {
    onFetch() {
      axios.get("/download").then(response => {
        if (response.data.code === 200) {
          this.url = response.data.payload.url;
        } else {
        }
      });
    }
  }
};
</script>

<style>
</style>
